import React, { Component } from "react";
import { Link } from "react-router-dom";

class CartSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      review: true,
      guid: this.props.guid,
      subtotal: this.props.labOrderSubtotal,
      discount: this.props.labOrderDiscount,
      credit: this.props.labOrderCredit,
      boutiqueTotal: this.props.labOrderBoutiqueTotal,
      shippingTotal: this.props.shippingTotal,
      localPickup: this.props.localPickup,
      orderReady: this.props.orderReady,
      labOrder: this.props.labOrder,
      total: this.props.total,
    };
  }

  componentWillReceiveProps(nextProps) {
    if(this.props !== nextProps) {
      this.setState({
        guid: nextProps.guid,
        subtotal: nextProps.labOrderSubtotal,
        discount: nextProps.labOrderDiscount,
        credit: nextProps.labOrderCredit,
        boutiqueTotal: nextProps.labOrderBoutiqueTotal,
        shippingTotal: nextProps.shippingTotal,
        localPickup: nextProps.localPickup,
        labOrder: nextProps.labOrder,
        total: nextProps.total,
      })
    }
  }

  render() {
    const {subtotal, discount, credit, boutiqueTotal, shippingTotal,
      total, labOrder, orderReady } = this.state
      let buttonText

      if (this.props.buttonText) {
        buttonText = this.props.buttonText
      } else {
        buttonText = "REVIEW & CONFIRM"
      }

    let nextStepButton, localPickupSelector, checkedValue

    if (this.props.showLocalPickup || this.state.showLocalPickup) {

      if (this.props.localPickup) {
        checkedValue = true
      } else {
        checkedValue = false
      }

      localPickupSelector = <div className="w-form">
          <form id="email-form" name="email-form" data-name="Email Form">
            <div className="checkbox-field-5 w-checkbox">
              <input type="checkbox" checked={checkedValue} onClick={(e) => {this.props.toggleLocalPickup(e)}} onChange={(e) => {this.props.toggleLocalPickup(e)}} id="LOCAL PICKUP" name="localPickup" data-name="localPickup" className="w-checkbox-input"/>
              <label htmlFor="LOCAL PICKUP" className="checkbox-label-6 w-form-label">LOCAL PICKUP</label>
            </div>
          </form>
        </div>
    }

    if(this.state.review && orderReady) {
     nextStepButton = <Link style={{color: 'white'}} to={{
          pathname: this.props.redirectUrl,
          state: {
            orderGuid: `${this.state.guid}`
          }
        }}>
        <div className="reviewAndConfirmButtonCheckoutBox button-29 w-button">
          {buttonText}
        </div>
      </Link>
    } else if (this.state.review) {
      nextStepButton = <div className="reviewAndConfirmButtonCheckoutBox button-29 w-button disabled" style={{opacity: '0.5'}} onClick={() => { alert("To proceed to final review screen and finish checking out, please complete all the required lab order details: (1) shipping address and (2) credit card selection. If you cannot add a card on this screen, please do so on your account page at https://app.musealab.com/account You do not need to enter a discount code or credit amount to complete checkout.") }}>
        {buttonText}
      </div>
    } else {
     nextStepButton = <div className="reviewAndConfirmButtonCheckoutBox button-29 w-button" style={{color: 'white'}}>
      NEXT STEP
      </div>
    }


    return (
      <div>
        <div className="text-block-90">
          CHECKOUT
        </div>

        <div className="div-block-35">
          <div className="w-row">
            <div className="column-16 w-col w-col-6">
              <div className="text-block-91">SUBTOTAL</div>
              <div className="text-block-91">DISCOUNT</div>
              <div className="text-block-91">CREDIT</div>
              <div className="text-block-91">BOUTIQUE</div>
              <div className="text-block-93">SHIPPING</div>
              <div className="text-block-95">TOTAL</div>
            </div>
            <div className="column-17 w-col w-col-6">
              <div className="text-block-92">${subtotal}</div>
              <div className="text-block-94">${discount}</div>
              <div className="text-block-96">${credit}</div>
              <div className="text-block-96">${boutiqueTotal}</div>
              <div className="text-block-96">${shippingTotal}</div>
              <div className="text-block-96">${total}</div>
            </div>
          </div>
        </div>

        {localPickupSelector}

        {nextStepButton}

          <Link style={{color: 'black'}} to={{
            pathname: `/lab-orders/${this.state.guid}/edit/order-builder`,
            state: {
              orderGuid: `${this.state.guid}`,
              labOrder: `${this.state.labOrder}`
            }
          }}>
            <div className="button-30 w-button">

                ORDER MORE
            </div>
          </Link>

        <div className="button-31 w-button clearCartButtonCheckoutBox"
        style={{cursor: 'pointer'}}
        onClick={(e) => {e.preventDefault(); if (window.confirm("WAIT! This will remove all items from your cart and redirect you to the order page. You cannot undo this action. Are you sure you want to remove EVERYTHING from your cart and start over?"))
          this.props.clearCart() }}>
          CLEAR CART
        </div>

      </div>
    )
  }
}

export default CartSidebar;
