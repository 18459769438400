import React, { Component } from "react";
//import { Link } from "react-router-dom";

import Auth from "../modules/Auth.js"
import axios from "axios";
import SelectUSState from 'react-select-us-states';
//import signalCell from "../img/checkout-21-outlinesignalcellular4barblack48dp@2x.png";
//import outlineToggle from "../img/checkout-21-outlinetoggleoffblack48dp@2x.png";

//import "../css/newWebflow.css";

class ShippingAddressForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newAddress: this.props.newAddress,
      firstName: '',
      lastName: '',
      streetAddress: '',
      stateName: '',
      zip: '',
      city: ''
    };
    this.toggleUseExisting = this.toggleUseExisting.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  toggleUseExisting(e) {
    if (this.state.newAddress) {
      this.setState({
        newAddress: false
      });
    } else {
      this.setState({
        newAddress: true
      });
    }
  }

  handleChange(e) {
    if (!e.target) {
      this.checkForAutoCompletedAddressFields()
      this.setState({
        stateName: e
      });
    } else {
      const name = e.target.name;
      const val = e.target.value;
      this.setState({
        [name]: val
      });
    }
  }

  checkForAutoCompletedAddressFields() {
    let attributesToCheck = ['firstName', 'lastName', 'streetAddress', 'zip', 'city']

    attributesToCheck.forEach(attribute => {
      let autoCompletedValue = document.getElementsByName(attribute)[0].value
      if (this.state[attribute].length < 1 && autoCompletedValue && autoCompletedValue.length > 0) {
        this.setState({
          [attribute]: autoCompletedValue
        });
      }
    })
  }

  handleSubmit(e) {
    e.preventDefault()
    var token = Auth.getToken()
    axios
      .post('/api/v2/addresses',
        {
          street_address: this.state.streetAddress,
          street_address_two: this.state.streetAddressTwo,
          recipient_name: "" + this.state.firstName + " " + this.state.lastName,
          city: this.state.city,
          state: this.state.stateName,
          zip: this.state.zip,
          lab_order_id: this.props.labOrderGuid,
          update_address_on_order: true
        }, { headers: { token: token } })
      .then(res => {
        document.location.reload()
      })
      .catch(err =>
        this.throwError(err))
  }

  throwError(err) {
    let errorObject = err.response.data.error
    let errorMessage = "\nERROR CODE: " + errorObject.status + "\n ERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.developer_message
    console.log("ERROR MESSAGE: " + errorMessage + "\n MORE INFO: " + err.response.data.error.more_info.join(" ,").split(",").join("\n"))
    //alert(errorMessage)

    this.setState({
      loading: false,
      errorState: true,
      errorMessage: errorMessage
    })
  }

  savedAddresses = () => {
    if (this.props.localPickup) {
      return <option value="local">LOCAL PICKUP</option>
    } else if (this.props.savedAddresses && this.props.savedAddresses.length > 0) {
      return this.formatSavedAddresses(this.props.savedAddresses)
    } else {
      let addrOptions = []
      addrOptions.push(<a key="none-saved" value="" style={{ textAlign: 'center' }} className="dropdown-link-12 w-dropdown-link">NO SAVED ADDRESSES</a>)
      addrOptions.push(<a key="new" style={{ textAlign: 'center' }} onClick={(e) => { this.setupNewAddressForm(e) }} value="new" className="dropdown-link-12 w-dropdown-link">ADD NEW ADDRESS</a>)
      return addrOptions
    }
  }

  setupNewAddressForm(e) {
    e.preventDefault()
    this.toggleDropdownOpen(e)
    this.props.toggleShippingAddressForm(e)
  }

  toggleDropdownOpen(e) {
    e.preventDefault()
    if (e.target.classList.contains("w--open")) {
      e.target.classList.remove("w--open")
      e.target.parentElement.style.zIndex = '100'
      e.target.style.zIndex = '100'
      document.querySelector("nav.dropdown-list-3").classList.remove('w--open');
    } else {
      //move back cc form if it is on screen
      if (document.querySelector("nav.dropdown-list-8") !== null) {
        document.querySelector("nav.dropdown-list-8").parentElement.style.zIndex = '100'
        document.querySelector("nav.dropdown-list-8").style.zIndex = '100'
      }
      //bring forward shipping form
      document.querySelector("div.dropdown-7").style.zIndex = '999'
      document.querySelector("nav.dropdown-list-3").style.zIndex = '999'
      e.target.classList.add("w--open")
      e.target.parentElement.style.zIndex = '999'
      e.target.style.zIndex = '999'
      document.querySelector("nav.dropdown-list-3").classList.add('w--open');
    }
  }

  formatSavedAddresses(addrData) {

    const addrOptions = addrData.map(obj => {
      let recipientString = ""
      if (obj.attributes.recipient && obj.attributes.recipient.trim.length > 0) {
        recipientString += obj.attributes.recipient + ", "
      }

      recipientString += obj.attributes.street + " " + obj.attributes.street_2 + ", " + obj.attributes.state_province + " " + obj.attributes.zip

      //console.log("addr string " + recipientString)
      return <a key={obj.attributes.guid} onClick={(e) => { this.props.updateLabOrder(e, "shipping_address_id", obj.attributes.guid) }}
        value={obj.attributes.guid} href="#" className="dropdown-link-12 w-dropdown-link"> {recipientString}</a>
      //<option key={obj.attributes.guid} value={obj.attributes.guid}> {recipientString} </option>;

    })
    if (this.props.showAddressForm) {
      addrOptions.push(<a key="new" onClick={(e) => { this.setupNewAddressForm(e) }} value="new" className="dropdown-link-12 w-dropdown-link">HIDE ADDRESS FORM</a>)
    } else {
      addrOptions.push(<a key="new" onClick={(e) => { this.setupNewAddressForm(e) }} value="new" className="dropdown-link-12 w-dropdown-link">ADD NEW ADDRESS</a>)
    }
    return addrOptions
  }


  render() {
    let labOrderCity, labOrder, savedAddressDropdown, selectedAddressInfo, newAddressForm, noExistingAddresses
    labOrder = this.props.labOrder
    console.log("SHIPPING ADDRESS FORM ", labOrder.shipping_address)
    if (labOrder && this.props.savedAddresses && this.props.savedAddresses.length > 0) {
      noExistingAddresses = false
      savedAddressDropdown = <div className="div-block-41">
        <div data-delay="0" onClick={(e) => { this.toggleDropdownOpen(e) }} className="dropdown-7 w-dropdown">
          <div className="w-dropdown-toggle">
            <div className="icon-2 w-icon-dropdown-toggle"></div>
            <div className="text-block-113">SHIP TO</div>
          </div>

          <nav className="dropdown-list-3 w-dropdown-list">
            {this.savedAddresses()}
          </nav>

        </div>
      </div>

    } else {
      noExistingAddresses = true
      savedAddressDropdown = <div className="div-block-41">
        <div data-delay="0" onClick={(e) => { this.toggleDropdownOpen(e) }} className="dropdown-7 w-dropdown">

          <div className="w-dropdown-toggle">
            <div className="icon-2 w-icon-dropdown-toggle"></div>
            <div className="text-block-113">SHIP TO</div>
          </div>

          <nav className="dropdown-list-3 w-dropdown-list">
            {this.savedAddresses()}
          </nav>

        </div>
      </div>
    }

    let newAddressButton, selectedAddress
    if (this.state.firstName.length > 0 && this.state.lastName.length > 0 && this.state.streetAddress.length > 0 && this.state.stateName.length > 0 && this.state.zip.length >= 5 && this.state.city.length > 0) {

      newAddressButton = <input type="submit"
        value="SAVE"
        onClick={(e) => { this.handleSubmit(e) }}
        className="submit-button-5 w-button" />

    } else {
      newAddressButton = <input type="submit"
        disabled
        style={{ opacity: '0.5' }}
        value="SAVE"
        className="submit-button-5 w-button" />
    }

    if (this.props.selectedAddress) {

      if (this.props.selectedAddress.city && this.props.selectedAddress.state_province) {
        labOrderCity = "" + this.props.selectedAddress.city + ", " + this.props.selectedAddress.state_province + " " + this.props.selectedAddress.zip
      } else {
        labOrderCity = ""
      }

      let labOrderRecipient
      if (this.props.selectedAddress.recipient && this.props.selectedAddress.recipient.length > 0) {
        labOrderRecipient = this.props.selectedAddress.recipient
      } else if (labOrder.client_name && labOrder.client_name.length > 0) {
        labOrderRecipient = labOrder.client_name
      } else {
        labOrderRecipient = labOrder.photographer_name
      }
      selectedAddress = <div className="orderSummaryShippingAddress">

        {labOrderRecipient}
        <br />
        {labOrder.shipping_address.street} {labOrder.shipping_address.street_2}
        <br />
        {labOrderCity}
      </div>
    } else {
      selectedAddress = "NONE SELECTED"
    }
    //const {key, guid, orderId, orderName, orderStatus,
    //coverPhoto, orderExternalId, orderNotes, orderIsApi,
    //orderApiVendor, orderHasFraming, orderHasMounting,
    //orderHasMatting } = this.props
    //
    // onClick={(e) => {this.toggleUseExisting(e)}}

    if (this.props.page === "checkout" && this.props.labOrder && this.props.labOrder.shipping_address && this.props.labOrder.shipping_address.guid && this.props.labOrder.shipping_address.guid.length > 0) {
      selectedAddressInfo = <div className="div-block-42" style={{ marginTop: '20px', marginBottom: '20px' }}>
        <div className="text-block-114" style={{}}>
          SELECTED ADDRESS: {selectedAddress}
        </div>
      </div>
    } else {
      selectedAddressInfo = <div></div>

    }

    if (noExistingAddresses || this.props.showAddressForm) {
      newAddressForm = <div className="w-form">
        <form id="email-form-4" name="email-form-4" data-name="Email Form 4" className="form-11">
          <input
            type="text"
            className="text-field-27 w-input"
            name="firstName"
            onChange={this.handleChange}
            placeholder="First Name"
            id="firstName" />
          <input
            type="text"
            className="text-field-27 w-input"
            name="lastName"
            onChange={this.handleChange}
            placeholder="Last Name" />
          <input
            value=""
            type="text"
            className="text-field-27 w-input"
            name="businessName"
            onChange={this.handleChange}
            placeholder="Business Name or Attn To"
            id="businessName" />
          <input
            type="phone"
            className="text-field-28 w-input"
            name="phone"
            onChange={this.handleChange}
            placeholder="Phone Number" />
          <input
            type="text"
            className="text-field-29 w-input"
            name="streetAddress"
            onChange={this.handleChange}
            placeholder="Street Address"
            id="streetAddress" />
          <input
            type="text"
            className="text-field-29 w-input"
            name="streetAddressTwo"
            onChange={this.handleChange}
            placeholder="Unit or Apt"
            id="streetAddressTwo" />
          <input
            type="text"
            className="text-field-29 w-input"
            name="city"
            onChange={this.handleChange}
            placeholder="City"
            id="city" />
          <SelectUSState
            className="text-field-30 w-input"
            name="state"
            onChange={this.handleChange}
            defaultValue={labOrder.shipping_address.state_province || ''}
            value={labOrder.shipping_address.state_province || ''} />
          <input type="text"
            className="text-field-31 w-input"
            name="zip"
            onChange={this.handleChange}
            placeholder="Zip Code" />
          {newAddressButton}
        </form>
      </div>
    } else {
      newAddressForm = <div></div>
    }


    if (!this.props.localPickup) {
      return (
        <div>
          {savedAddressDropdown}
          {selectedAddressInfo}
          {newAddressForm}
        </div>
      )
    } else {
      return (
        <div className="div-block-41">
          <div data-delay="0" className="dropdown-7 w-dropdown">
            <div className="w-dropdown-toggle">
              <div className="text-block-113">LOCAL PICKUP</div>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default ShippingAddressForm;
