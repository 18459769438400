import React, { Component } from "react";
import Auth from "./modules/Auth.js"
import axios from "axios"
import MuseaApi from "./modules/MuseaApi.js"
import LabOrderCard from "./components/LabOrderCard.js";
import Honeybadger from 'honeybadger-js'
import 'react-toastify/dist/ReactToastify.css';

import NavBar from "./components/NavBar.js";

class CartPreview extends Component {
    constructor(props) {
        super(props);
        const hb_config = {
            api_key: process.env.REACT_APP_HB_KEY,
            environment: process.env.REACT_APP_CURRENT_ENV,
            revision: 'current'
        }
        this.honeybadger = Honeybadger.configure(hb_config)
        this.state = {
            loading: true,
            startLabOrder: {},
            endLabOrder: {},
            honeybadger: this.honeybadger,
        };

    }


    componentDidMount() {
        let museaApi = new MuseaApi()
        museaApi.checkAndResetCache()
        this.getLabOrder()
    }


    getLabOrder = async (e, data) => {
        //e.preventDefault();
        var token = Auth.getToken()
        var startOrderGuid = this.props.location.pathname["split"]("/")[2];
        var endOrderGuid = this.props.location.pathname["split"]("/")[4];
        try {
            let firstRes = await axios
                .get(`api/v2/lab_orders/${startOrderGuid}`, { headers: { 'token': token } })
            let secondRes = await axios
                .get(`api/v2/lab_orders/${endOrderGuid}`, { headers: { 'token': token } })
            let museaApi = new MuseaApi()
            var firstParsedOrder = museaApi.mergeAttributesForLabOrder(firstRes.data);
            var endParsedOrder = museaApi.mergeAttributesForLabOrder(secondRes.data);
            console.log(firstParsedOrder)
            this.setState({
                redirectToReferrer: false,
                auth: Auth.isAuthenticated(),
                from: { pathname: "/" },
                firstLabOrder: firstParsedOrder,
                endLabOrder: endParsedOrder,
                loading: false,
                errorState: false
            });
            return true;

        } catch (err) {
            alert(err)
            this.throwError(err)
        }

    };
    mergeOrders = () => {
        var token = Auth.getToken()
        var startOrderGuid = this.props.location.pathname["split"]("/")[2];
        var endOrderGuid = this.props.location.pathname["split"]("/")[4];
        axios.post(`/api/v2/lab-orders/merge`,
            {
                orders: [
                    startOrderGuid, endOrderGuid
                ]
            },
            { headers: { 'token': token } }
        ).then(
            (res) => {
                console.log(res);
                this.context.router.push("/lab-orders");
            }
        ).catch(
            (e) => this.throwError(e),
        )
    }

    throwError(err) {
        let errorMessage, errorObject
        if (err.response && err.response.status === 401) {
            this.setState({
                userLoggedOut: true
            })
            return false
        } else {
            if (err.response != null) {
                errorObject = err.response.data.error
                if (errorObject != null) {
                    errorMessage = "\nERROR CODE: " + errorObject.status + "\n ERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.developer_message
                    if (err.response.data.error.more_info != null) {
                        this.state.honeybadger.notify(err)
                        alert("ERROR MESSAGE: " + errorMessage)
                        console.log("ERROR MESSAGE: " + errorMessage + "\nMORE INFO: " + err.response.data.error.more_info.join(" ,").split(",").join("\n"))
                    } else {
                        this.state.honeybadger.notify(err)
                        alert("ERROR MESSAGE: " + errorMessage)
                        console.log(errorMessage + " More info not available.")
                    }
                } else {
                    errorMessage = "\nERROR CODE: " + err.response
                }
            } else {
                this.state.honeybadger.notify(err)
                alert("ERROR MESSAGE: " + err.stack)
                errorMessage = err.stack
            }

            this.setState({
                loading: false,
                errorMessage: errorMessage
            })
        }
    }
    render() {
        const { loading, firstLabOrder, endLabOrder } = this.state
        if (loading)
            return (
                <div>
                    <NavBar />
                    <div className="w-row">
                        <div className="column-2000 w-col w-col-9">
                            <div id="w-node-dd7d45327c78-d0052ddd">
                                <div style={{ textAlign: 'center', fontWeight: '800', marginLeft: '10%', marginTop: '10%' }} className="text-block-103">loading...</div>
                                <div className="div-block-39"></div>

                            </div>
                        </div>
                    </div>
                </div>
            )
        return (
            <div>
                <NavBar />
                <div className="w-row">
                    <div className="w-col">
                        <div style={{ display: "flex" }}>
                            <div style={{ width: "50%" }}>
                                <LabOrderCard key={firstLabOrder.guid}
                                    guid={firstLabOrder.guid}
                                    orderId={firstLabOrder.id}
                                    orderName={firstLabOrder.order_name}
                                    orderPhotographerName={firstLabOrder.photographer_name}
                                    orderPhotographerBusinessName={firstLabOrder.photographer_business}
                                    orderStatus={firstLabOrder.status}
                                    orderCreatedAt={firstLabOrder.created_at}
                                    orderUpdatedAt={firstLabOrder.updated_at}
                                    orderPaidAt={firstLabOrder.paid_at}
                                    // includedAttributes={includedAttributes}
                                    // parsedDate={parsedDate}
                                    orderShippedAt={firstLabOrder.shipped_at}
                                    orderTrackingUrl={firstLabOrder.tracking_url}
                                    orderTrackingNumber={firstLabOrder.tracking_number}
                                    orderBoutiqueShippingNote={firstLabOrder.boutique_shipping_note}
                                    orderBoutiqueShippingPrice={firstLabOrder.boutique_shipping_price}
                                    orderShippingMethod={firstLabOrder.shipping_method}
                                    coverPhoto={firstLabOrder.cover_photo_url}
                                    orderExternalId={firstLabOrder.external_order_id}
                                    orderNotes={firstLabOrder.notes}
                                    orderHasNotes={firstLabOrder.has_notes}
                                    orderAdminNotes={firstLabOrder.internal_notes}
                                    orderHasAdminNotes={firstLabOrder.has_admin_notes}
                                    orderIsApi={firstLabOrder.api_order}
                                    orderApiVendor={firstLabOrder.api_vendor}
                                    orderHasFraming={firstLabOrder.has_frame}
                                    orderHasMounting={firstLabOrder.has_mount}
                                    orderHasMatting={firstLabOrder.has_mat}
                                    downloadAllLink={firstLabOrder.download_all_link}
                                />
                            </div>
                            <div style={{ width: "50%" }}>
                                <LabOrderCard style={{ width: "48%" }} key={endLabOrder.guid}
                                    guid={endLabOrder.guid}
                                    orderId={endLabOrder.id}
                                    orderName={endLabOrder.order_name}
                                    orderPhotographerName={endLabOrder.photographer_name}
                                    orderPhotographerBusinessName={endLabOrder.photographer_business}
                                    orderStatus={endLabOrder.status}
                                    orderCreatedAt={endLabOrder.created_at}
                                    orderUpdatedAt={endLabOrder.updated_at}
                                    orderPaidAt={endLabOrder.paid_at}
                                    // includedAttributes={includedAttributes}
                                    // parsedDate={parsedDate}
                                    orderShippedAt={endLabOrder.shipped_at}
                                    orderTrackingUrl={endLabOrder.tracking_url}
                                    orderTrackingNumber={endLabOrder.tracking_number}
                                    orderBoutiqueShippingNote={endLabOrder.boutique_shipping_note}
                                    orderBoutiqueShippingPrice={endLabOrder.boutique_shipping_price}
                                    orderShippingMethod={endLabOrder.shipping_method}
                                    coverPhoto={endLabOrder.cover_photo_url}
                                    orderExternalId={endLabOrder.external_order_id}
                                    orderNotes={endLabOrder.notes}
                                    orderHasNotes={endLabOrder.has_notes}
                                    orderAdminNotes={endLabOrder.internal_notes}
                                    orderHasAdminNotes={endLabOrder.has_admin_notes}
                                    orderIsApi={endLabOrder.api_order}
                                    orderApiVendor={endLabOrder.api_vendor}
                                    orderHasFraming={endLabOrder.has_frame}
                                    orderHasMounting={endLabOrder.has_mount}
                                    orderHasMatting={endLabOrder.has_mat}
                                    downloadAllLink={endLabOrder.download_all_link}
                                />
                            </div>
                        </div>
                        <hr></hr>
                        <div style={{ textAlign: "center" }}>
                            <h4>Looks good?</h4>
                            <button className="submit-button-4 w-button" onClick={() => this.mergeOrders()}>Merge</button>
                        </div>
                    </div>
                </div>
            </div>
        )


    }
}

export default CartPreview;
