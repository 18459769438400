import Auth from "./Auth.js"
import React from "react";
import axios from "axios"

class MuseaApi {

  async checkAndResetCache() {
    let cacheExpired = Auth.checkIfCacheCheckExpired()
    // console.log("cache expired? " + cacheExpired)
    if (cacheExpired) {
      let currentCachedVersion = Auth.getCachedClientVersion()
      let upToDateCachedVersion = await this.getCurrentCientVersion()
      // console.log("upToDateCachedVersion: " + upToDateCachedVersion)
      // console.log("currentCachedVersion: " + currentCachedVersion)
      if (currentCachedVersion === upToDateCachedVersion) {
        //if version is up to date, set cache check to be good
        //for another 15 minutes
        // console.log("resetting last cache check to: " + Date.now())
        Auth.setLastCacheCheck()
      } else {
        //if version is out of date hard reset cache
        // console.log("resetting cached version to: " + upToDateCachedVersion)
        Auth.setCachedClientVersion(upToDateCachedVersion)
      }

      this.resetCache()
    }
  }

  async getCurrentCientVersion() {
    let currentVersion = await axios.get("https://bvxifnr8t0.execute-api.us-east-1.amazonaws.com/default/checkClientVersionApi", { headers: {} })
      .then(res => {
        return res.data
      })
    return currentVersion
  }

  resetCache() {
    // console.log("resetting the cache")
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
  }

  mergeAttributesForLabOrder(response) {
    //let labOrderData = response['data'];
    let includedObjects = response['included'];
    let includedRelationships = response['data']['relationships'];
    let baseLabOrder = response['data']['attributes'];
    let labOrder = {};
    Object.assign(labOrder, baseLabOrder);

    labOrder['shipping_address'] = labOrder.shipping_address
    labOrder['images'] = this.mergeImages(labOrder, includedRelationships.images)

    if (includedRelationships.line_items && includedRelationships.line_items.data.length > 0) {
      const lineItems = includedRelationships.line_items.data.map(obj => {
        //console.log("returning item: " + obj)
        return this.returnItem(includedObjects, obj);
      })
      let formattedLineItems = this.formatLineItemsForCart(lineItems, labOrder)
      labOrder["lineItems"] = formattedLineItems;
    } else {
      labOrder["lineItems"] = [];
    }

    //console.log("lab order is: " + labOrder)
    return labOrder
  }

  mergeImages(labOrder, images) {
    let foundImages
    if (images && images.data.length > 0) {
      foundImages = images.data.map(obj => {
        return obj.attributes
      })
    } else {
      foundImages = [];
    }
    return foundImages
  }


  returnItem(includedObjects, obj) {
    var foundItem = includedObjects.filter(item => (
      obj.id === item.id && obj.type === item.type));
    if (foundItem.length > 0) {
      return foundItem.shift().attributes;
    } else {
      return {}
    }
  }


  formatLineItemsForCart(lineItems, labOrder) {
    //console.log("formatting line items for cart: " + lineItems)
    let formattedLineItems = lineItems.map(line_item => {
      let lineItem = {}
      let photoUrl
      let formattedPrintBorder

      console.log("line_item:")
      console.log(line_item)

      photoUrl = this.mergePhotoAndLineItem(line_item, labOrder)
      if (photoUrl != null && photoUrl.length > 1) {
        console.log("have a presigned photo " + photoUrl)
      } else {
        photoUrl = line_item.photograph_url
      }

      if (line_item.print_border != null && line_item.print_border.length > 0) {
        formattedPrintBorder = this.convertBorderToNumber(line_item.print_border)
      } else {
        formattedPrintBorder = "no border"
      }

      //let notesString
      //if (line_item.notes.length > 0) {
      //notesString = line_item.notes
      //} else {
      //notesString = "none"
      //}

      let category, detailOne, detailTwo, frameProfile, frameGlassPricing, frameGlassName, detailThree, finishPrice, finishingServiceGuid, finishingServiceDetailGuid

      if (line_item.finishing_service != null && Object.keys(line_item.finishing_service).length > 0) {
        category = line_item.finishing_service.finishing_category
        if (category) {
          category = category.toLowerCase()
        }
        if (category !== "none" && category === "hardwood frame" || category === "hardwood float canvas frame" || category === "desktop frame" || category === "contemporary frame") {
          detailOne = line_item.finishing_service.finishing_service
          frameProfile = line_item.finishing_service.formatted_frame_profile
          frameGlassPricing = line_item.finishing_service.finishing_service_detail_three_guid
          frameGlassName = line_item.finishing_service.formatted_detail_two 
          detailThree = line_item.finishing_service.formatted_detail_three
          detailTwo = line_item.finishing_service.finishing_service_detail
        } else if (category !== "none"){
          detailOne = line_item.finishing_service.finishing_service
          detailTwo = line_item.finishing_service.finishing_service_detail
          detailThree = line_item.finishing_service.formatted_detail_three
        }
        finishingServiceGuid = line_item.finishing_service.finishing_service_guid
        finishingServiceDetailGuid = line_item.finishing_service.finishing_service_detail_guid
        finishPrice = line_item.finishing_service.price
      } else {
        category = "none"
        finishingServiceGuid = ""
        finishingServiceDetailGuid = ""
        detailOne = ""
        detailTwo = ""
        detailThree = ""
        finishPrice = 0.00
      }

      lineItem.labOrderGuid = labOrder.guid
      lineItem.guid = line_item.guid
      lineItem.photoGuid = line_item.photograph_guid
      lineItem.photoUrl = photoUrl
      lineItem.imageName = line_item.photo_file_name
      lineItem.paper = line_item.print_size_attributes.paper
      lineItem.paperGuid = line_item.print_size_attributes.paper_guid
      lineItem.printSize = line_item.print_size_attributes.print_size
      lineItem.printSizeGuid = line_item.print_size_attributes.print_size_guid
      lineItem.printBorder = formattedPrintBorder
      lineItem.printBorderRaw = line_item.printBorder
      lineItem.printPrice = parseFloat(+line_item.print_size_attributes.price).toFixed(2)
      lineItem.externalId = line_item.external_id
      lineItem.notes = line_item.notes
      lineItem.internalNotes = line_item.internal_notes
      lineItem.cropAttributes = {}
      lineItem.cropAttributes.x = parseFloat(+line_item.photograph_attributes.crop_x).toFixed(2)
      lineItem.cropAttributes.y = parseFloat(+line_item.photograph_attributes.crop_y).toFixed(2)
      lineItem.cropAttributes.height = parseFloat(+line_item.photograph_attributes.crop_h).toFixed(2)
      lineItem.cropAttributes.width = parseFloat(+line_item.photograph_attributes.crop_w).toFixed(2)
      lineItem.cropAttributes.aspect = parseFloat(+line_item.photograph_attributes.aspect).toFixed(2)

      // cropped image
      lineItem.photograph_cropped_url = line_item.photograph_cropped_url
      lineItem.photograph_full_url = line_item.photograph_full_url

      lineItem.quantity = line_item.quantity
      lineItem.totalPrice = parseFloat(+line_item.subtotal).toFixed(2)
      if (category) {
        lineItem.finishingCategory = category.toUpperCase()
      } else {
        lineItem.finishingCategory = category
      }
      lineItem.finishingServiceGuid = finishingServiceGuid
      lineItem.finishingServiceDetailGuid = finishingServiceDetailGuid
      lineItem.finishingDetailOne = detailOne
      lineItem.frameProfile = frameProfile
      lineItem.frameGlassPricing = frameGlassPricing
      lineItem.frameGlassName = frameGlassName
      ////RISING WARM - ASH - 3/4"
      lineItem.finishingDetailTwo = detailTwo
      ////2" MAT BORDER
      lineItem.finishingDetailThree = detailThree
      ////UV ACRYLIC
      lineItem.finishingPrice = parseFloat(+finishPrice).toFixed(2)

      return lineItem
    })
    return formattedLineItems
  }


  convertBorderToNumber(border) {
    let formattedBorderString
    if (border === "none") {
      formattedBorderString = "no border"
    } else if (border === "one_eighth") {
      formattedBorderString = "1/8\" border"
    } else if (border === "one_fourth") {
      formattedBorderString = "1/4\" border"
    } else if (border === "one_half") {
    } else if (border === "three_fourths") {
    } else if (border === "one") {
    } else if (border === "one_and_one_half") {
    } else if (border === "two") {
    } else if (border === "three") {
    } else {
      formattedBorderString = "no border"
    }
    return formattedBorderString
  }



  mergePhotoAndLineItem(line_item, labOrder) {
    console.log("merging photo and line item: " + line_item)
    let photoUrl
    // let foundImages = labOrder.images.filter(obj => obj.photo_url === line_item.photo_url)
    let foundImages = labOrder.images.filter(image => image.photo_file_name === line_item.photo_file_name)
    if (foundImages.length > 0) {
      console.log("photo url in order review: " + foundImages[0].presigned_photo_url)
      console.log("foundImages[0].smallest_photo: " + foundImages[0].smallest_photo)
      if (foundImages[0].smallest_photo && foundImages[0].smallest_photo.length > 0) {
        photoUrl = foundImages[0].smallest_photo
      } else {
        photoUrl = foundImages[0].presigned_photo_url
      }
      console.log("photoUrl: " + photoUrl)
    } else {
      photoUrl = ""
    }
    return photoUrl
  }

  mergePhotoFileNameAndLineItem(line_item, labOrder) {
    //console.log("merging photo and line item: " + line_item)
    let photoFileName
    //let foundImages = labOrder.images.filter(obj => obj.photo_url === line_item.photo_url)
    let foundImages = labOrder.images.filter(image => image.photo_url === line_item.photograph_url)
    if (foundImages.length > 0) {
      //console.log("photo url in order review: " + foundImages[0].presigned_photo_url)
      photoFileName = foundImages[0].photo_file_name
    } else {
      photoFileName = ""
    }
    return photoFileName
  }


  async getProducts() {
    //e.preventDefault();
    var token = Auth.getToken()
    let products = await axios
      .get("/api/v2/products/papers_with_details", { headers: { 'token': token } })
      .then(res => {
        // console.log(res.data.data)
        return res.data.data
      })
    return products
  }

  paperOptions(products, paperPricingFunction) {
    if (products.length > 0) {
      return this.formatPaperOptions(products, paperPricingFunction)
    } else {
      return <a key="category" style={{ textAlign: 'center', whiteSpace: 'pre-line' }}
        onClick={(e) => { paperPricingFunction(e, "paperPricing", "", "") }}
        className="w-dropdown-link line-item-select-link"> PAPER TYPE </a>
    }
  }

  //console.log("obj.attributes " + obj.attributes)
  //Museum Etching: 1
  //Canson Rag: 2
  //Ultra Smooth: 3
  //Platine
  //Deckled with Border
  //Deckled No Border
  //Satin
  //Photo Matte
  //Lustre
  //Canvas - Mirror Wrap
  //Canvas - Gallery Wrap
  //Canvas - Black Wrap
  //Canvas - White Wrape
  formatPaperOptions(productData, paperPricingFunction) {
    const paperList = productData.filter(paper => !paper.attributes.name.toLowerCase().includes('no paper'));
    paperList.forEach(obj => {
      let name = obj.attributes.name.toLowerCase()
      //console.log("name " + name)
      if (name.includes('museum')) {
        obj.attributes.position = 1
      } else if (name.includes('canson')) {
        obj.attributes.position = 2
      } else if (name.includes('bamboo')) {
        obj.attributes.position = 3
      } else if (name.includes('ultra')) {
        obj.attributes.position = 4 
      } else if (name.includes('platine')) {
        obj.attributes.position = 5 
      } else if (name.includes('baryta')) {
        obj.attributes.position = 6
      } else if (name.includes('lustre')) {
        obj.attributes.position = 7
      } else if (name.includes('no border') && !name.includes('circle')) {
        obj.attributes.position = 8
      } else if (name.includes('with border') && !name.includes('circle')) {
        obj.attributes.position = 9
      } else if (name.includes('circle no border')) {
        obj.attributes.position = 10
      } else if (name.includes('circle with border')) {
        obj.attributes.position = 11
      } else if (name.includes('mirror')) {
        obj.attributes.position = 12
      } else if (name.includes('gallery')) {
        obj.attributes.position = 13
      } else if (name.includes('white') && !name.includes('0.5 inch bar')) {
        obj.attributes.position = 14
      } else if (name.includes('black')) {
        obj.attributes.position = 15
      } else if (name.includes('white') && name.includes('0.5 inch bar')) {
        obj.attributes.position = 16
      }
    })

    const productOptions = paperList.sort(this.comparePositions).map(obj => {
      let objGuid = `${obj.attributes.guid}`
      return <a key={obj.attributes.guid} formattedname={obj.attributes.name} style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { paperPricingFunction(e, "paperPricing", objGuid, obj.attributes.name) }}
        className="w-dropdown-link line-item-select-link"> {obj.attributes.name}</a>
    })
    return productOptions
  }


  formatPrintSizeOptions(val, products, printSizeFunction) {
    let selectedPaper = products.filter(product => (product.attributes.guid === val));

    if (selectedPaper.length > 0) {
      let sortedPrintSizes = selectedPaper[0].attributes.print_size_pricings.sort(this.comparePrices)
      let formattedOptions = []
      let formattedPrintSizes = sortedPrintSizes.map(fullObj => {
        let obj = fullObj.data.attributes
        let objGuid = obj.guid
        let formattedname = "" + obj.name + " ($" + parseFloat(+obj.price).toFixed(2) + ")"
        return <a key={obj.guid} formattedname={formattedname} style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { printSizeFunction(e, "printSizePricing", objGuid, obj.name) }}
          className="w-dropdown-link line-item-select-link"> {formattedname} </a>
      })
      formattedOptions.push(formattedPrintSizes)
      return formattedOptions
    }
  }

  comparePrices(a, b) {
    return a.data.attributes.price - b.data.attributes.price;
  }

  comparePositions(a, b) {
    return a.attributes.position - b.attributes.position;
  }

  compareOptionPrices(a, b) {
    return a.props.price - b.props.price;
  }

  compareNumbers(a, b) {
    return a - b;
  }

  // async 
  async getFinishingServices(val) {
    var token = Auth.getToken()
    let finishingServices = axios
      .get(`/api/v2/products/${val}/finishing_services`, { headers: { 'token': token } })
      .then(res => {
        console.log("res.data: ", res.data)
        return res.data
      })
    return finishingServices
  }

  mergeAttributesForFinishingOptions = (response, finishingOptionOneFunction) => {
    let finishingServicesData = response.data;
    let includedObjects = response.included;
    let finishingServices = {};
    finishingServices["four_ply_mat"] = []
    finishingServices["eight_ply_mat"] = []
    finishingServices["mount"] = []
    finishingServices["contemporary_frame"] = []
    finishingServices["hardwood_frame"] = []
    finishingServices["desktop_frame"] = []
    finishingServices["hardwood_float_canvas_frame"] = []
    //for each put in appropriate array of finishing service category
    let allFrameWoods = finishingServicesData.map((obj) => obj.attributes.frame_wood_pricing)
    //set up frame woods
    //remove null from set
    //let frameWoods = Array.from(new Set(allFrameWoods)).filter(Boolean)
    finishingServices["hardwood_frame"]["woods"] = []
    finishingServices["contemporary_frame"]["woods"] = []
    finishingServices["desktop_frame"]["woods"] = []
    finishingServices["hardwood_float_canvas_frame"]["woods"] = []
    //finishingServices["frame"]["woods"].push( <a key="category" style={{textAlign: 'center',   whiteSpace: 'pre-line' }} className="w-dropdown-link line-item-select-link"> FRAME WOOD </a>)
    //<option key="category" value="category">FRAME WOOD</option>)
    let sortedStandardWoods = this.sortStandardWoods([])
    sortedStandardWoods.forEach((obj) => {
      finishingServices["contemporary_frame"]["woods"].push(<a key={obj} formattedname={obj} style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { finishingOptionOneFunction(e, "finishingOptionOne", obj, obj) }} className="w-dropdown-link line-item-select-link"> {obj} </a>)

      finishingServices["desktop_frame"]["woods"].push(<a key={obj} formattedname={obj} style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { finishingOptionOneFunction(e, "finishingOptionOne", obj, obj) }} className="w-dropdown-link line-item-select-link"> {obj} </a>)
    })

    let sortedWoods = this.sortWoods([])
    sortedWoods.forEach((obj) => {
      var keyThreeFourths = "" + obj + "-.75"
      var nameThreeFourths = "" + obj + " 0.75 inch PROFILE"
      finishingServices["hardwood_frame"]["woods"].push(<a key={keyThreeFourths} formattedname={nameThreeFourths} style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { finishingOptionOneFunction(e, "finishingOptionOne", keyThreeFourths, nameThreeFourths) }} className="w-dropdown-link line-item-select-link"> {nameThreeFourths} </a>)

      finishingServices["desktop_frame"]["woods"].push(<a key={keyThreeFourths} formattedname={nameThreeFourths} style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { finishingOptionOneFunction(e, "finishingOptionOne", keyThreeFourths, nameThreeFourths) }} className="w-dropdown-link line-item-select-link"> {nameThreeFourths} </a>)

      var keyOneHalf = "" + obj + "-.50"
      var nameOneHalf = "" + obj + " 0.5 inch PROFILE"
      finishingServices["hardwood_float_canvas_frame"]["woods"].push(<a key={keyOneHalf} formattedname={nameOneHalf} style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { finishingOptionOneFunction(e, "finishingOptionOne", keyOneHalf, nameOneHalf) }} className="w-dropdown-link line-item-select-link"> {nameOneHalf} </a>)


      var keyOneAndAHalf = "" + obj + "-1.5"
      var nameOneAndAHalf = "" + obj + " 1.5 inch PROFILE"
      finishingServices["hardwood_frame"]["woods"].push(<a key={keyOneAndAHalf} formattedname={nameOneAndAHalf} style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { finishingOptionOneFunction(e, "finishingOptionOne", keyOneAndAHalf, nameOneAndAHalf) }} className="w-dropdown-link line-item-select-link"> {nameOneAndAHalf} </a>)

      finishingServices["desktop_frame"]["woods"].push(<a key={keyOneAndAHalf} formattedname={nameOneAndAHalf} style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { finishingOptionOneFunction(e, "finishingOptionOne", keyOneAndAHalf, nameOneAndAHalf) }} className="w-dropdown-link line-item-select-link"> {nameOneAndAHalf} </a>)
    })

    //<option key={"" + obj + "-1.5"} value={"" + obj + "-1.5"}> {obj} 1.5&quot; profile </option> )


    finishingServicesData.forEach(obj => {
      console.log("finishingServiceData obj: ", obj)
      let objAttributes = obj.attributes
      let key, name
      let category = objAttributes.finishing_category.toLowerCase()
      console.log("category ln450", category)

      let includedItems = obj.relationships.finishing_detail_pricings.data.map(item => {
        return this.returnItem(includedObjects, item);
      })

      finishingServices[objAttributes.guid] = objAttributes
      finishingServices[objAttributes.guid]["includedItems"] = includedItems

      console.log(includedItems.length + " includedItems")

      // console.log("category on 434: " + category)
      if (category === "mount") {
        key = objAttributes.guid
        name = objAttributes.name + " ($" + parseFloat(+objAttributes.price).toFixed(2) + ")"
        let formattedOption = <a key={key} formattedname={name} price={parseFloat(+objAttributes.price).toFixed(2)} style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { finishingOptionOneFunction(e, "finishingOptionOne", key, name) }} className="w-dropdown-link line-item-select-link"> {name} </a>
        //<option key={objAttributes.guid} price={parseFloat(+objAttributes.price).toFixed(2)} value={objAttributes.guid}> {objAttributes.name} (${parseFloat(+objAttributes.price).toFixed(2)}) </option>;

        finishingServices["mount"].push(formattedOption)

      } else if (category === "8-ply mat") {

        key = objAttributes.guid
        name = objAttributes.name + " ($" + parseFloat(+objAttributes.price).toFixed(2) + ")"
        let formattedOption = <a key={key} formattedname={name} price={parseFloat(+objAttributes.price).toFixed(2)} style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { finishingOptionOneFunction(e, "finishingOptionOne", key, name) }} className="w-dropdown-link line-item-select-link"> {name} </a>
        //let formattedOption = <option key={objAttributes.guid} price={parseFloat(+objAttributes.price).toFixed(2)} value={objAttributes.guid}> {objAttributes.name} (${parseFloat(+objAttributes.price).toFixed(2)}) </option>;

        finishingServices["eight_ply_mat"].push(formattedOption)
      } else if (category === "4-ply mat") {

        key = objAttributes.guid
        name = objAttributes.name + " ($" + parseFloat(+objAttributes.price).toFixed(2) + ")"
        let formattedOption = <a key={key} formattedname={name} price={parseFloat(+objAttributes.price).toFixed(2)} style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { finishingOptionOneFunction(e, "finishingOptionOne", key, name) }} className="w-dropdown-link line-item-select-link"> {name} </a>
        //let formattedOption = <option key={objAttributes.guid} price={parseFloat(+objAttributes.price).toFixed(2)} value={objAttributes.guid}> {objAttributes.name} (${parseFloat(+objAttributes.price).toFixed(2)}) </option>;

        finishingServices["four_ply_mat"].push(formattedOption)

      } else if (category === "contemporary frame") {

        let objNameTokens = objAttributes.name.split(' ')

        var mat = "" + objNameTokens[objNameTokens.indexOf("mat") - 2] + " inch mat"
        if (mat === "with inch mat") {
          mat = "no mat"
        }

        key = objAttributes.guid
        name = "" + objAttributes.name + " ($" + parseFloat(+objAttributes.price).toFixed(2) + ")"
        let formattedOption = <a key={key} formattedname={name} frameWood={objAttributes.frame_wood_pricing} frameMat={mat} frameGlass={objAttributes.frame_glass_pricing} price={parseFloat(+objAttributes.price).toFixed(2)} style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { finishingOptionOneFunction(e, "finishingOptionOne", key, name) }} className="w-dropdown-link line-item-select-link"> {name} </a>

        //let formattedOption = <option frameWood={objAttributes.frame_wood_pricing} frameProfile={profile} frameMat={mat} frameGlass={objAttributes.frame_glass_pricing} key={objAttributes.guid} value={objAttributes.guid}> {objAttributes.name} (${parseFloat(+objAttributes.price).toFixed(2)}) </option>;

        finishingServices["contemporary_frame"].push(formattedOption)

      } else if (category === "hardwood frame") {

        let objNameTokens = objAttributes.name.split(' ')

        let profileName = "" + objNameTokens[objNameTokens.indexOf("Profile") - 2]
        let profile

        if (profileName === "0.75") {
          profile = ".75"
        } else if (profileName === "0.5") {
          profile = ".50"
        } else if (profileName === "1.5") {
          profile = "1.5"
        }

        var mat = "" + objNameTokens[objNameTokens.indexOf("mat") - 2] + " inch mat"
        if (mat === "with inch mat") {
          mat = "no mat"
        }

        key = objAttributes.guid
        name = "" + objAttributes.name + " ($" + parseFloat(+objAttributes.price).toFixed(2) + ")"
        let formattedOption = <a key={key} formattedname={name} frameWood={objAttributes.frame_wood_pricing} frameProfile={profile} frameMat={mat} frameGlass={objAttributes.frame_glass_pricing} price={parseFloat(+objAttributes.price).toFixed(2)} style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { finishingOptionOneFunction(e, "finishingOptionOne", key, name) }} className="w-dropdown-link line-item-select-link"> {name} </a>

        //let formattedOption = <option frameWood={objAttributes.frame_wood_pricing} frameProfile={profile} frameMat={mat} frameGlass={objAttributes.frame_glass_pricing} key={objAttributes.guid} value={objAttributes.guid}> {objAttributes.name} (${parseFloat(+objAttributes.price).toFixed(2)}) </option>;

        finishingServices["hardwood_frame"].push(formattedOption)

      } else if (category === "desktop frame") {

        let objNameTokens = objAttributes.name.split(' ')

        if (objNameTokens.includes("Profile")) {
          let profileName = "" + objNameTokens[objNameTokens.indexOf("Profile") - 2]
          let profile

          if (profileName === "0.75") {
            profile = ".75"
          } else if (profileName === "0.5") {
            profile = ".50"
          } else if (profileName === "1.5") {
            profile = "1.5"
          }

          var mat = "" + objNameTokens[objNameTokens.indexOf("mat") - 2] + " inch mat"
          if (mat === "with inch mat") {
            mat = "no mat"
          }

          key = objAttributes.guid
          name = "" + objAttributes.name + " ($" + parseFloat(+objAttributes.price).toFixed(2) + ")"
          let formattedOption = <a key={key} formattedname={name} frameWood={objAttributes.frame_wood_pricing} frameProfile={profile} frameMat={mat} frameGlass={objAttributes.frame_glass_pricing} price={parseFloat(+objAttributes.price).toFixed(2)} style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { finishingOptionOneFunction(e, "finishingOptionOne", key, name) }} className="w-dropdown-link line-item-select-link"> {name} </a>

            //let formattedOption = <option frameWood={objAttributes.frame_wood_pricing} frameProfile={profile} frameMat={mat} frameGlass={objAttributes.frame_glass_pricing} key={objAttributes.guid} value={objAttributes.guid}> {objAttributes.name} (${parseFloat(+objAttributes.price).toFixed(2)}) </option>;

            finishingServices["desktop_frame"].push(formattedOption)

        } else {

          var mat = "" + objNameTokens[objNameTokens.indexOf("mat") - 2] + " inch mat"
          if (mat === "with inch mat") {
            mat = "no mat"
          }

          key = objAttributes.guid
          name = "" + objAttributes.name + " ($" + parseFloat(+objAttributes.price).toFixed(2) + ")"
          let formattedOption = <a key={key} formattedname={name} frameWood={objAttributes.frame_wood_pricing} frameMat={mat} frameGlass={objAttributes.frame_glass_pricing} price={parseFloat(+objAttributes.price).toFixed(2)} style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { finishingOptionOneFunction(e, "finishingOptionOne", key, name) }} className="w-dropdown-link line-item-select-link"> {name} </a>

            finishingServices["desktop_frame"].push(formattedOption)
        }


      } else if (category === "hardwood float canvas frame") {
        console.log("hardwood float category")

        let objNameTokens = objAttributes.name.split(' ')
        console.log("objNameTokens", objNameTokens)

        let profileName = "" + objNameTokens[objNameTokens.indexOf("Profile") - 2]
        let profile = ".50"
        let mat = "no mat"

        key = objAttributes.guid
        name = "" + objAttributes.name + " ($" + parseFloat(+objAttributes.price).toFixed(2) + ")"
        let formattedOption = <a key={key} formattedname={name} frameWood={objAttributes.frame_wood_pricing} frameProfile={profile} frameMat={mat} frameGlass={objAttributes.frame_glass_pricing} price={parseFloat(+objAttributes.price).toFixed(2)} style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { finishingOptionOneFunction(e, "finishingOptionOne", key, name) }} className="w-dropdown-link line-item-select-link"> {name} </a>

          //let formattedOption = <option frameWood={objAttributes.frame_wood_pricing} frameProfile={profile} frameMat={mat} frameGlass={objAttributes.frame_glass_pricing} key={objAttributes.guid} value={objAttributes.guid}> {objAttributes.name} (${parseFloat(+objAttributes.price).toFixed(2)}) </option>;

          finishingServices["hardwood_float_canvas_frame"].push(formattedOption)

      }
    })

    finishingServices["four_ply_mat"].sort(this.sortMatOptions)
    finishingServices["eight_ply_mat"].sort(this.sortMatOptions)
    finishingServices["contemporary_frame"].sort(this.sortFrameOptions)
    finishingServices["hardwood_frame"].sort(this.sortFrameOptions)
    finishingServices["hardwood_float_canvas_frame"].sort(this.sortFrameOptions)
    finishingServices["desktop_frame"].sort(this.sortFrameOptions)
    return finishingServices
  }

  sortMatOptions(matOne, matTwo) {
    let matOneNumber = new Number(matOne.props.formattedname.split(" ")[0])
    let matTwoNumber = new Number(matTwo.props.formattedname.split(" ")[0])
    if (matOneNumber < matTwoNumber) {
      return -1;
    }
    if (matOneNumber > matTwoNumber) {
      return 1;
    }
    return 0;
  }

  sortFrameOptions(frameOne, frameTwo) {
    let frameOneNumber = new Number(frameOne.props.frameMat.split(" ")[0])
    let frameTwoNumber = new Number(frameTwo.props.frameMat.split(" ")[0])
    if (frameOneNumber < frameTwoNumber) {
      return -1;
    }
    if (frameOneNumber > frameTwoNumber) {
      return 1;
    }
    return 0;
  }

  sortWoods = (woods) => {
    //maple, ash, cherry, walnut
    //Maple,Walnut,Cherry,Ash
    woods[0] = 'Ash'
    woods[1] = 'Cherry'
    woods[2] = 'Maple'
    woods[3] = 'Walnut'
    woods[4] = 'White Oak'
    return woods
  }

  sortStandardWoods = (woods) => {
    //maple, ash, cherry, walnut
    woods[0] = "ADELAIDE"
    woods[1] = "ALBANY"
    woods[2] = "ATHENS"
    woods[3] = "AURORA"
    woods[4] = "BRISTOL"
    woods[5] = "CHARLESTON"
    woods[6] = "CHARLOTTE"
    woods[7] = "CHELSEA"
    woods[8] = "COLUMBIA"
    // woods[9] = "CORINTH"
    woods[9] = "CORSICA"
    woods[10] = "CYPRUS"
    // woods[12] = "GENEVA"
    woods[11] = "HARTFORD"
    woods[12] = "HELENA"
    woods[13] = "MADISON"
    woods[14] = "NAPLES"
    woods[15] = "ODESSA"
    woods[16] = "RICHMOND"
    woods[17] = "SAVANNAH"
    woods[18] = "SIERRA"
    // woods[21] = "SYDNEY"
    woods[19] = "VENETIAN BRONZE"
    woods[20] = "VENETIAN GOLD 1.25\""
    woods[21] = "VENETIAN GOLD"
    woods[22] = "VENETIAN SILVER 1.25\""
    woods[23] = "VENETIAN SILVER"
    woods[24] = "VERONA"
    woods[25] = "VIENNA"
    return woods
  }

  setupFinishCatOneOptions = (val, finishingServiceOptions, paperName) => {
    console.log("paper: " + paperName)
    console.log("val: " + val)
    let finishCatOneOptions
    if (val === "hardwood_float_canvas_frame") {
      //filter by wood and profile
      //finishOptions = this.state.finishingServiceOptions.frame
      finishCatOneOptions = finishingServiceOptions.hardwood_float_canvas_frame.woods
      //filter by mat size
      //filter by stain
      //filter by glass
    } else if (val === "hardwood_frame") {
      //filter by wood and profile
      //finishOptions = this.state.finishingServiceOptions.frame
      finishCatOneOptions = finishingServiceOptions.hardwood_frame.woods
      //filter by mat size
      //filter by stain
      //filter by glass
    } else if (val === "desktop_frame") {
      //filter by wood and profile
      //finishOptions = this.state.finishingServiceOptions.frame
      finishCatOneOptions = finishingServiceOptions.desktop_frame.woods
      //filter by mat size
      //filter by stain
      //filter by glass
    } else if (val === "contemporary_frame") {
      //filter by wood and profile
      //finishOptions = this.state.finishingServiceOptions.frame
      finishCatOneOptions = finishingServiceOptions.contemporary_frame.woods
      //filter by mat size
      //filter by stain
      //filter by glass
    } else if (val === "mount") {
      //filter by size
      //filter by color
      //allow custom input
      let sortedMounts = finishingServiceOptions.mount.sort(this.compareOptionPrices)
      finishCatOneOptions = sortedMounts
    } else if (val === "four_ply_mat") {
      //filter by size
      //allow custom input
      let sortedMats = finishingServiceOptions.four_ply_mat.sort(this.compareOptionPrices)
      finishCatOneOptions = []
      //finishCatOneOptions.push(<a key="category" style={{textAlign: 'center', whiteSpace: 'pre-line' }} className="w-dropdown-link line-item-select-link"> MAT SIZE </a>)
      finishCatOneOptions.push(sortedMats)
    } else if (val === "eight_ply_mat") {
      //filter by size
      //allow custom input
      let sortedMats = finishingServiceOptions.eight_ply_mat.sort(this.compareOptionPrices)
      finishCatOneOptions = []
      //finishCatOneOptions.push(<a key="category" style={{textAlign: 'center', whiteSpace: 'pre-line' }} className="w-dropdown-link line-item-select-link"> MAT SIZE </a>)
      finishCatOneOptions.push(sortedMats)
    }
    return finishCatOneOptions
  }

  setupFinishCatTwoOptions = (val, category, finishingCategory, finishingServiceOptions, finishingOptionTwoFunction) => {
    let key, name
    let catTwoOptions = []
    let matPrice
    // console.log("finishingCategory is " + finishingCategory)

    if (finishingCategory === "hardwood_frame") {
      let nameTokens = val.split('-')
      let wood = nameTokens[0]
      let profile = nameTokens[1]
      let formattedMatSize

      console.log("nameTokens", nameTokens)
      console.log("wood", wood)
      console.log("profile", profile)
      console.log("finishingServiceOptions.hardwood", finishingServiceOptions.hardwood_frame)
      let matchingOptions = finishingServiceOptions.hardwood_frame.filter(item => (
        item.props.frameWood === wood && item.props.frameProfile === profile));

      let matSizes = Array.from(new Set(matchingOptions.map((obj) => obj.props.frameMat))).filter(Boolean)
      console.log("matSizes", matSizes)
      //catTwoOptions.push( <a key="category" style={{textAlign: 'center', whiteSpace: 'pre-line' }} className="w-dropdown-link line-item-select-link"> FRAME MAT</a>)
      //<option key="category" value="">FRAME MAT</option>)

      //matSizes[0] = "no mat"
      //matSizes[1] = "2\" mat"
      //matSizes[2] = "3\" mat"
      //matSizes[3] = "4\" mat"

      matSizes.forEach((obj) => {

        // console.log("item.props: ")
        // console.log(obj.props)
        // console.log("item.props.frameGlass.toLowerCase")
        // console.log(obj.props.frameGlass)

        console.log("finishingServiceOptions.hardwood_frame", finishingServiceOptions.hardwood_frame)
        let matchingPrice = finishingServiceOptions.hardwood_frame.filter(item => (
          item.props.frameWood === wood && obj === item.props.frameMat && item.props.frameProfile === profile && item.props.frameGlass.toLowerCase().includes('uv')));
        if (matchingPrice.length > 0) {
          //console.log("matching matPrice is " + matchingPrice[0].key)
          matPrice = matchingPrice[0].props.price
        } else {
          matchingPrice = finishingServiceOptions.hardwood_frame.filter(item => (
            item.props.frameWood === wood && obj === item.props.frameMat && item.props.frameProfile === profile && item.props.frameGlass.toLowerCase().includes('no')));
          if (matchingPrice.length > 0) {
            //console.log("matching matPrice is " + matchingPrice[0].key)
            matPrice = matchingPrice[0].props.price
          } else {
            matPrice = 0.0
          }
        }

        var key = "" + obj
        // var name = "" + obj + " $(" + parseFloat(matPrice).toFixed(2) + ")"
        var name = "" + obj + " " + matchingPrice[0].props.formattedname.split("mat ")[1]

        formattedMatSize = <a key={key} formattedname={name} style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { finishingOptionTwoFunction(e, "finishingOptionTwo", key, name) }} className="w-dropdown-link line-item-select-link"> {name} </a>
        catTwoOptions.push(formattedMatSize)
      })
      // console.log("catTwoOptions: " + catTwoOptions)
      //<option key={"" + wood + "-" + profile + "-" + obj} value={"" + wood + "-" + profile + "-" + obj}> {obj} mat </option> )
    } else if (finishingCategory === "hardwood_float_canvas_frame") {

      let nameTokens = val.split('-')
      let wood = nameTokens[0]
      let profile = nameTokens[1]
      let formattedMatSize

      let matchingOptions = finishingServiceOptions.hardwood_float_canvas_frame.filter(item => (
        item.props.frameWood === wood && item.props.frameProfile === profile));

      let matSizes = Array.from(new Set(matchingOptions.map((obj) => obj.props.frameMat))).filter(Boolean)
      //catTwoOptions.push( <a key="category" style={{textAlign: 'center', whiteSpace: 'pre-line' }} className="w-dropdown-link line-item-select-link"> FRAME MAT</a>)
      //<option key="category" value="">FRAME MAT</option>)

      //matSizes[0] = "no mat"
      //matSizes[1] = "2\" mat"
      //matSizes[2] = "3\" mat"
      //matSizes[3] = "4\" mat"

      matSizes.forEach((obj) => {

        let matchingPrice = finishingServiceOptions.hardwood_float_canvas_frame.filter(item => (
          item.props.frameWood === wood && obj === item.props.frameMat && item.props.frameProfile === profile));

        if (matchingPrice.length > 0) {
          //console.log("matching matPrice is " + matchingPrice[0].key)
          matPrice = matchingPrice[0].props.price
        } else {
          matPrice = 0.0
        }

        var key = "" + obj
        var name = "" + obj + " ($" + parseFloat(matPrice).toFixed(2) + ")"

        formattedMatSize = <a key={key} formattedname={name} style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { finishingOptionTwoFunction(e, "finishingOptionTwo", key, name) }} className="w-dropdown-link line-item-select-link"> {name} </a>
        catTwoOptions.push(formattedMatSize)
      })
      // console.log("catTwoOptions: " + catTwoOptions)
      //<option key={"" + wood + "-" + profile + "-" + obj} value={"" + wood + "-" + profile + "-" + obj}> {obj} mat </option> )


    } else if (finishingCategory === "contemporary_frame") {
      console.log("contemporary_frame ln710", val)
      let nameTokens = val.split('-')
      console.log("nameTokens", nameTokens)
      let wood = nameTokens[0]
      //let profile = nameTokens[1]
      let formattedMatSize

      let matchingOptions = finishingServiceOptions.contemporary_frame.filter(item => (
        item.props.frameWood === wood));

      let matSizes = Array.from(new Set(matchingOptions.map((obj) => obj.props.frameMat))).filter(Boolean)
      //catTwoOptions.push( <a key="category" style={{textAlign: 'center', whiteSpace: 'pre-line' }} className="w-dropdown-link line-item-select-link"> FRAME MAT</a>)
      //<option key="category" value="">FRAME MAT</option>)

      matSizes[0] = "no mat"
      matSizes[1] = "2 inch mat"
      matSizes[2] = "3 inch mat"
      matSizes[3] = "4 inch mat"

      matSizes.forEach((obj) => {

        let matchingPrice = finishingServiceOptions.contemporary_frame.filter(item => (
          item.props.frameWood === wood && obj === item.props.frameMat && item.props.frameGlass.toLowerCase().includes('uv')));

        if (matchingPrice.length > 0) {
          //console.log("matching matPrice is " + matchingPrice[0].key)
          matPrice = matchingPrice[0].props.price
        } else {
          matPrice = 0.0
        }

        var key = "" + obj
        if (matchingPrice && matchingPrice.length > 0 ) {
          var name = "" + obj + " " + matchingPrice[0].props.formattedname.split("mat ")[1]
        } else {
          var name = "" + obj + " ($" + parseFloat(matPrice).toFixed(2) + ")"
        }

        formattedMatSize = <a key={key} formattedname={name} style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { finishingOptionTwoFunction(e, "finishingOptionTwo", key, name) }} className="w-dropdown-link line-item-select-link"> {name} </a>
        catTwoOptions.push(formattedMatSize)
      })

      // console.log("catTwoOptions: " + catTwoOptions)
      //<option key={"" + wood + "-" + profile + "-" + obj} value={"" + wood + "-" + profile + "-" + obj}> {obj} mat </option> )

    } else if (finishingCategory === "desktop_frame") {
      console.log("desktop_frame ln880", val)
      let nameTokens = val.split('-')
      console.log("nameTokens", nameTokens)
      let wood = nameTokens[0]
      //let profile = nameTokens[1]
      let formattedMatSize

      let matchingOptions = finishingServiceOptions.desktop_frame.filter(item => (
        item.props.frameWood === wood));

      let matSizes = Array.from(new Set(matchingOptions.map((obj) => obj.props.frameMat))).filter(Boolean)
      //catTwoOptions.push( <a key="category" style={{textAlign: 'center', whiteSpace: 'pre-line' }} className="w-dropdown-link line-item-select-link"> FRAME MAT</a>)
      //<option key="category" value="">FRAME MAT</option>)

      matSizes.forEach((obj) => {

        let matchingPrice = finishingServiceOptions.contemporary_frame.filter(item => (
          item.props.frameWood === wood && obj === item.props.frameMat && item.props.frameGlass.toLowerCase().includes('uv')));

        if (matchingPrice.length > 0) {
          //console.log("matching matPrice is " + matchingPrice[0].key)
          matPrice = matchingPrice[0].props.price
        } else {
          matPrice = 0.0
        }

        var key = "" + obj
        if (matchingPrice && matchingPrice.length > 0 ) {
          var name = "" + obj + " " + matchingPrice[0].props.formattedname.split("mat ")[1]
        } else {
          var name = "" + obj + " ($" + parseFloat(matPrice).toFixed(2) + ")"
        }

        formattedMatSize = <a key={key} formattedname={name} style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { finishingOptionTwoFunction(e, "finishingOptionTwo", key, name) }} className="w-dropdown-link line-item-select-link"> {name} </a>
        catTwoOptions.push(formattedMatSize)
      })

      // console.log("catTwoOptions: " + catTwoOptions)
      //<option key={"" + wood + "-" + profile + "-" + obj} value={"" + wood + "-" + profile + "-" + obj}> {obj} mat </option> )

    } else if (finishingCategory === "mount") {
      //catTwoOptions.push(<a key="category" style={{textAlign: 'center', whiteSpace: 'pre-line' }} className="w-dropdown-link line-item-select-link"> MOUNT COLOR </a>)
      finishingServiceOptions[val].includedItems.forEach(obj => {
        var key = "" + obj.name + " ($" + parseFloat(+obj.price).toFixed(2) + ")";
        let formattedMountDetail = <a key={obj.guid} formattedname={key} price={parseFloat(+obj.price).toFixed(2)} style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { finishingOptionTwoFunction(e, "finishingOptionTwo", obj.guid, key) }} className="w-dropdown-link line-item-select-link"> {key} </a>

        //let formattedMountDetail = <option key={obj.guid} price={parseFloat(+obj.price).toFixed(2)} value={obj.guid}> {obj.name} (${parseFloat(+obj.price).toFixed(2)}) </option>;
        catTwoOptions.push(formattedMountDetail)
      })
    }

    return catTwoOptions
  }

  setupFrameGlassOptions = (val, finishingServiceOptions, finishingOptionOne, frameGlassFunction) => {
    let key, name
    let frameGlassOptions = []
    console.log("ln847 setting up frame glass options")

    let nameTokens = finishingOptionOne.split('-')
    let wood = nameTokens[0]
    let profile = nameTokens[1]
    let mat = val
    let frameGlassOption, glassPriceMuseum
    console.log("wood", wood)
    console.log("profile", profile)
    console.log("mat", mat)

    //console.log("wood " + wood)
    //console.log("profile " + profile)
    //console.log("mat " + mat)
    var matchingOptions = finishingServiceOptions.hardwood_frame.filter(item => (
      item.props.frameWood === wood && item.props.frameProfile === profile && item.props.frameMat === mat));

    let museumPrice = finishingServiceOptions.hardwood_frame.filter(item => (
      item.props.frameWood === wood && item.props.frameProfile === profile && item.props.frameMat === mat && item.props.frameGlass.toLowerCase().includes('museum')));

    let uvPrice = finishingServiceOptions.hardwood_frame.filter(item => (
      item.props.frameWood === wood && item.props.frameProfile === profile && item.props.frameMat === mat && item.props.frameGlass.toLowerCase().includes('uv')));

    let noPrice = finishingServiceOptions.hardwood_frame.filter(item => (
      item.props.frameWood === wood && item.props.frameProfile === profile && item.props.frameMat === mat && item.props.frameGlass.toLowerCase().includes('no')));

    if (museumPrice.length > 0 && uvPrice.length > 0) {
      glassPriceMuseum = museumPrice[0].props.price - uvPrice[0].props.price
    } else {
      glassPriceMuseum = 0.0
    }

    //console.log("matchingOptions " + matchingOptions.length)

    //frameGlassOptions.push( <a key="category" style={{textAlign: 'center', whiteSpace: 'pre-line' }} className="w-dropdown-link line-item-select-link"> ACRYLIC </a> )
    //<option key="category" value="">FRAME GLASS</option>)

    matchingOptions.forEach((obj) => {
      // console.log("obj.props.frameGlass: ", obj.props.frameGlass)
      var frameName = obj.props.frameGlass.split(" for")[0]
      console.log("frameName: " + frameName)
      if (frameName.toLowerCase().includes('museum')) {
        var name = frameName + " + ($" + parseFloat(glassPriceMuseum).toFixed(2) + ")"
      } else {
        var name = frameName + " + ($" + parseFloat(0.0).toFixed(2) + ")"
      }
      var key = obj.key
      frameGlassOption = <a key={key} formattedname={name} style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { frameGlassFunction(e, "frameGlass", key, name) }} className="w-dropdown-link line-item-select-link"> {name} </a>
      frameGlassOptions.push(frameGlassOption)
    })

    //<option key={obj.key} value={obj.key}> { obj.props.frameGlass.split(" for")[0]} </option> )
    //console.log("frameGlassOptions length " + frameGlassOptions.length)

    return frameGlassOptions
  }

  setupFrameGlassOptionsDesktop = (val, finishingServiceOptions, finishingOptionOneName, finishingOptionOne, frameGlassFunction) => {
    let key, name
    let frameGlassOptions = []
    var allKeys = ""

    if (finishingOptionOneName.toLowerCase().includes("profile")) {
      console.log("ln998 setting up frame glass options for desktop hardwood frame")

      let nameTokens = finishingOptionOne.split('-')
      let wood = nameTokens[0]
      let profile = nameTokens[1]
      let mat = val
      let frameGlassOption, glassPriceMuseum
      console.log("wood", wood)
      console.log("profile", profile)
      console.log("mat", mat)

      //console.log("wood " + wood)
      //console.log("profile " + profile)
      //console.log("mat " + mat)
      var matchingOptions = finishingServiceOptions.desktop_frame.filter(item => (
        item.props.frameWood === wood && item.props.frameProfile === profile && item.props.frameMat === mat));

      let museumPrice = finishingServiceOptions.desktop_frame.filter(item => (
        item.props.frameWood === wood && item.props.frameProfile === profile && item.props.frameMat === mat && item.props.frameGlass.toLowerCase().includes('museum')));

      let uvPrice = finishingServiceOptions.desktop_frame.filter(item => (
        item.props.frameWood === wood && item.props.frameProfile === profile && item.props.frameMat === mat && item.props.frameGlass.toLowerCase().includes('uv')));

      let noPrice = finishingServiceOptions.desktop_frame.filter(item => (
        item.props.frameWood === wood && item.props.frameProfile === profile && item.props.frameMat === mat && item.props.frameGlass.toLowerCase().includes('no')));

      if (museumPrice.length > 0 && uvPrice.length > 0) {
        glassPriceMuseum = museumPrice[0].props.price - uvPrice[0].props.price
      } else {
        glassPriceMuseum = 0.0
      }

      //console.log("matchingOptions " + matchingOptions.length)

      //frameGlassOptions.push( <a key="category" style={{textAlign: 'center', whiteSpace: 'pre-line' }} className="w-dropdown-link line-item-select-link"> ACRYLIC </a> )
      //<option key="category" value="">FRAME GLASS</option>)

      matchingOptions.forEach((obj) => {
        // console.log("obj.props.frameGlass: ", obj.props.frameGlass)
        allKeys += obj.key + "_"
        var frameName = obj.props.frameGlass.split(" for")[0]
        console.log("frameName: " + frameName)
        if (frameName.toLowerCase().includes('museum')) {
          var name = frameName + " + ($" + parseFloat(glassPriceMuseum).toFixed(2) + ")"
        } else {
          var name = frameName + " + ($" + parseFloat(0.0).toFixed(2) + ")"
        }
        var key = obj.key
        frameGlassOption = <a key={key} formattedname={name} style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { frameGlassFunction(e, "frameGlass", key, name) }} className="w-dropdown-link line-item-select-link"> {name} </a>
          frameGlassOptions.push(frameGlassOption)
      })

    } else {
      console.log("ln1052 setting up frame glass options for desktop contemporary frame")
      console.log("setting up uv frame glass")

      let nameTokens = finishingOptionOne.split('-')
      let wood = nameTokens[0]
      //let profile = nameTokens[1]
      let mat = val
      let frameGlassOption, glassPriceMuseum

      //console.log("wood " + wood)
      //console.log("profile " + profile)
      console.log("mat " + mat)
      console.log("val", val)
      console.log("wood", wood)
      console.log("finishingServiceOptions contemp frame", finishingServiceOptions.desktop_frame)
      var matchingOptions = finishingServiceOptions.desktop_frame.filter(item => (
        item.props.frameWood === wood && item.props.frameMat === mat));

      let museumPrice = finishingServiceOptions.desktop_frame.filter(item => (
        item.props.frameWood === wood && item.props.frameMat === mat && item.props.frameGlass.toLowerCase().includes('museum')));

      let uvPrice = finishingServiceOptions.desktop_frame.filter(item => (
        item.props.frameWood === wood && item.props.frameMat === mat && item.props.frameGlass.toLowerCase().includes('uv')));

      if (museumPrice.length > 0 && uvPrice.length > 0) {
        glassPriceMuseum = museumPrice[0].props.price - uvPrice[0].props.price
      } else {
        glassPriceMuseum = 0.0
      }

      //console.log("matchingOptions " + matchingOptions.length)

      //frameGlassOptions.push( <a key="category" style={{textAlign: 'center', whiteSpace: 'pre-line' }} className="w-dropdown-link line-item-select-link"> ACRYLIC </a> )
      //<option key="category" value="">FRAME GLASS</option>)

      console.log("matchingOptions", matchingOptions)
      matchingOptions.forEach((obj) => {
        allKeys += obj.key + "_"
        var frameName = obj.props.frameGlass.split(" for")[0]
        if (frameName.toLowerCase().includes('museum')) {
          var name = frameName + " + ($" + parseFloat(glassPriceMuseum).toFixed(2) + ")"
        } else {
          var name = frameName + " + ($" + parseFloat(0.0).toFixed(2) + ")"
        }
        var key = obj.key
        frameGlassOption = <a key={key} formattedname={name} style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { frameGlassFunction(e, "frameGlass", key, name) }} className="w-dropdown-link line-item-select-link"> {name} </a>
          frameGlassOptions.push(frameGlassOption)
      })
  }
    var sampleKeys = "sample_" + allKeys
    console.log("sampleKeys", sampleKeys)
    var sampleFrameGlassOption = <a key={sampleKeys} formattedname="SAMPLE (UV and Museum Acrylic)" style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { frameGlassFunction(e, "frameGlass", sampleKeys,"SAMPLE (UV and Museum Acrylic)") }} className="w-dropdown-link line-item-select-link"> SAMPLE (UV and Museum Acrylic) </a>
      frameGlassOptions.push(sampleFrameGlassOption)

    //<option key={obj.key} value={obj.key}> { obj.props.frameGlass.split(" for")[0]} </option> )
    //console.log("frameGlassOptions length " + frameGlassOptions.length)

    return frameGlassOptions
  }

  setupFrameGlassOptionsHardwoodFloat = (val, finishingServiceOptions, finishingOptionOne, frameGlassFunction) => {
    let key, name
    let frameGlassOptions = []
    console.log("ln907 setting up frame glass options hardwood float")

    let nameTokens = finishingOptionOne.split('-')
    let wood = nameTokens[0]
    let profile = nameTokens[1]
    let mat = val
    let frameGlassOption
    console.log("wood", wood)
    console.log("profile", profile)
    console.log("mat", mat)

    //console.log("wood " + wood)
    //console.log("profile " + profile)
    //console.log("mat " + mat)
    var matchingOptions = finishingServiceOptions.hardwood_float_canvas_frame.filter(item => (
      item.props.frameWood === wood && item.props.frameProfile === profile && item.props.frameMat === mat));

    matchingOptions.forEach((obj) => {
      // console.log("obj.props.frameGlass: ", obj.props.frameGlass)
      var frameName = obj.props.frameGlass.split(" for")[0]
      console.log("frameName: " + frameName)
      var name = frameName + " + ($" + parseFloat(0.0).toFixed(2) + ")"
      var key = obj.key
      frameGlassOption = <a key={key} formattedname={name} style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { frameGlassFunction(e, "frameGlass", key, name) }} className="w-dropdown-link line-item-select-link"> {name} </a>
      frameGlassOptions.push(frameGlassOption)
    })

    //<option key={obj.key} value={obj.key}> { obj.props.frameGlass.split(" for")[0]} </option> )
    //console.log("frameGlassOptions length " + frameGlassOptions.length)

    return frameGlassOptions
  }

  setupUvFrameGlassOptions = (val, finishingServiceOptions, finishingOptionOne, frameGlassFunction) => {
    console.log("setting up uv frame glass")
    let key, name
    let frameGlassOptions = []

    let nameTokens = finishingOptionOne.split('-')
    let wood = nameTokens[0]
    //let profile = nameTokens[1]
    let mat = val
    let frameGlassOption, glassPriceMuseum

    //console.log("wood " + wood)
    //console.log("profile " + profile)
    console.log("mat " + mat)
    console.log("val", val)
    console.log("wood", wood)
    console.log("finishingServiceOptions contemp frame", finishingServiceOptions.contemporary_frame)
    var matchingOptions = finishingServiceOptions.contemporary_frame.filter(item => (
      item.props.frameWood === wood && item.props.frameMat === mat));

    let museumPrice = finishingServiceOptions.contemporary_frame.filter(item => (
      item.props.frameWood === wood && item.props.frameMat === mat && item.props.frameGlass.toLowerCase().includes('museum')));

    let uvPrice = finishingServiceOptions.contemporary_frame.filter(item => (
      item.props.frameWood === wood && item.props.frameMat === mat && item.props.frameGlass.toLowerCase().includes('uv')));

    let noPrice = finishingServiceOptions.contemporary_frame.filter(item => (
      item.props.frameWood === wood && item.props.frameMat === mat && item.props.frameGlass.toLowerCase().includes('no')));

    if (museumPrice.length > 0 && uvPrice.length > 0) {
      glassPriceMuseum = museumPrice[0].props.price - uvPrice[0].props.price
    } else {
      glassPriceMuseum = 0.0
    }

    //console.log("matchingOptions " + matchingOptions.length)

    //frameGlassOptions.push( <a key="category" style={{textAlign: 'center', whiteSpace: 'pre-line' }} className="w-dropdown-link line-item-select-link"> ACRYLIC </a> )
    //<option key="category" value="">FRAME GLASS</option>)

    console.log("matchingOptions", matchingOptions)
    matchingOptions.forEach((obj) => {
      var frameName = obj.props.frameGlass.split(" for")[0]
      if (frameName.toLowerCase().includes('museum')) {
        var name = frameName + " + ($" + parseFloat(glassPriceMuseum).toFixed(2) + ")"
      } else {
        var name = frameName + " + ($" + parseFloat(0.0).toFixed(2) + ")"
      }
      var key = obj.key
      frameGlassOption = <a key={key} formattedname={name} style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { frameGlassFunction(e, "frameGlass", key, name) }} className="w-dropdown-link line-item-select-link"> {name} </a>
      frameGlassOptions.push(frameGlassOption)
    })

    //<option key={obj.key} value={obj.key}> { obj.props.frameGlass.split(" for")[0]} </option> )
    //console.log("frameGlassOptions length " + frameGlassOptions.length)

    return frameGlassOptions
  }

  setupFrameStainOptions = (val, finishingServiceOptions, frameStainFunction) => {
    let frameStainOptions = []
    if (finishingServiceOptions[val] && finishingServiceOptions[val].includedItems.length > 0) {
      finishingServiceOptions[val].includedItems.forEach(obj => {
          frameStainOptions.push(<a key={obj.guid} formattedname={obj.name} style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { frameStainFunction(e, "frameStain", obj.guid, obj.name) }} className="w-dropdown-link line-item-select-link"> {obj.name} </a>)
        })
      // map(obj => (<a key={obj.guid} formattedname={obj.name} style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { frameStainFunction(e, "frameStain", obj.guid, obj.name) }} className="w-dropdown-link line-item-select-link"> {obj.name} </a>))
      //<option key={obj.guid} value={obj.guid}> {obj.name}</option>))
      return frameStainOptions
    } else {
      frameStainOptions.push(<a key="none" formattedname="NO STAINS FOR FRAME" style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { frameStainFunction(e, "frameStain", "none", "NO STAINS FOR THIS FRAME") }} className="w-dropdown-link line-item-select-link"> NO STAINS FOR THIS FRAME </a>)
      return frameStainOptions
    }

  }

  sortNames = (names) => {
    let mappedNames = names.map(function (value, index) {
      let numericPart, stringPart;
      let splitString = value.split("_")
      if (!isNaN(splitString[0])) {
        numericPart = splitString[0]
        if (splitString[1]) {
          stringPart = splitString[1].toLowerCase()
        } else {
          stringPart = ""
        }
      } else {
        stringPart = splitString[0].toLowerCase()
        if (splitString[1]) {
          numericPart = splitString[1]
        } else {
          numericPart = ""
        }
      }
      return {
        index: index,
        numericPart: numericPart,
        stringPart: stringPart,
      }
    })
    mappedNames.sort((a, b) => {
      if (a.stringPart > b.stringPart)
        return 1;
      if (a.stringPart < b.stringPart)
        return -1;
      return 0;
    })
    return mappedNames;
  }




}

export default MuseaApi;
