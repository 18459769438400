import React, { Component } from "react";
import { Link } from "react-router-dom";
import Auth from "../modules/Auth";
import axios from "axios";

import MuseaApi from "../modules/MuseaApi.js";
import noPhoto from "../img/no-photo.png";
//import downloadCloud from "../img/outlineclouddownloadblack48dp.png"
import adminNotesIcon from "../img/adminNotesIcon.png"
import expiditedShippingIcon from "../img/expiditedShippingIcon.png"
import orderNotesIcon from "../img/orderNotesIcon.png"

//import "../css/newWebflow.css";

// <button onClick={() => this.props.mergeOrder()} >Merge</button>

class AdminLabOrderCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: this.props.guid,
      updatingLabOrder: false,
      guid: this.props.guid,
      orderId: this.props.orderId,
      orderPhotographerBusinessName: this.props.orderPhotographerBusinessName,
      orderPhotographerName: this.props.orderPhotographerName,
      orderName: this.props.orderName,
      orderStatus: this.props.orderStatus,
      orderCreatedAt: this.props.orderCreatedAt,
      orderUpdatedAt: this.props.orderUpdatedAt,
      orderPaidAt: this.props.orderPaidAt,
      orderShippedAt: this.props.orderShippedAt,
      orderTrackingUrl: this.props.orderTrackingUrl,
      orderTrackingNumber: this.props.orderTrackingNumber,
      orderBoutiqueShippingNote: this.props.orderBoutiqueShippingNote,
      orderBoutiqueShippingPrice: this.props.orderBoutiqueShippingPrice,
      orderShippingMethod: this.props.orderShippingMethod,
      coverPhoto: this.props.coverPhoto,
      orderExternalId: this.props.orderExternalId,
      orderNotes: this.props.orderNotes,
      orderHasNotes: this.props.orderHasNotes,
      orderAdminNotes: this.props.orderAdminNotes,
      orderHasAdminNotes: this.props.orderHasAdminNotes,
      orderIsApi: this.props.orderIsApi,
      orderApiVendor: this.props.orderApiVendor,
      orderHasFraming: this.props.orderHasFraming,
      orderHasMounting: this.props.orderHasMounting,
      orderHasMatting: this.props.orderHasMatting,
      parsedDate: this.props.parsedDate,
      includedAttributes: this.props.includedAttributes,
      printsColor: 'rgba(74, 144, 226, 1.0)',
      mountColor: 'rgba(144, 19, 254, 1.0)',
      matColor: 'rgba(245, 166, 35, 1.0)',
      frameColor: 'rgba(75, 208, 178, 1.0);',
      canvasColor: 'rgba(119, 199, 31, 1.0)',
      apiOrderColor: 'rgba(208, 2, 27, 1.0)',
      boutiqueColor: 'rgba(231, 54, 214, 1.0)',
      resetColor: 'rgba(0, 0, 0, 1.0)',
      downloadAllLink: this.props.downloadAllLink
    };

    this.updateLabOrder = this.updateLabOrder.bind(this);
  }

  formatOrderDate(paidAt) {
    let options = { month: 'long', day: 'numeric', year: 'numeric', hour12: 'true', hour: '2-digit', minute: '2-digit' };
    let rawParsedDate = new Date(Date.parse(paidAt)).toLocaleDateString("en-US", options)
    let parsedDate = rawParsedDate.toUpperCase()
    return parsedDate
  }


  toggleDropdownOpen(e, guid) {
    e.preventDefault()
    var dropdownId = `status-dropdown-${guid}`
    if (e.target.classList.contains("w--open")) {
      e.target.classList.remove("w--open")
      document.querySelector(`#${dropdownId}`).classList.remove('w--open');
    } else {
      e.target.classList.add("w--open")
      document.querySelector(`#${dropdownId}`).classList.add('w--open');
    }
  }

  updateLabOrder(e, guid, type, value) {
    e.preventDefault()
    this.setState({ updatingLabOrder: true })

    const name = e.target.name;
    const val = e.target.value;

    var token = Auth.getToken()

    if (name === "socialMediaShare") {
      type = "permission_to_share"
      if (val === "false") {
        value = false
      } else {
        value = true
      }
    }
    axios
      .patch(`/api/v2/lab_orders/${guid}`,
        {
          type: type,
          value: value
        }, { headers: { 'token': token } })
      .then(res => {
        //console.log("updated lab order")

        let currentUserAdmin, currentUserApiVendor, viewAsPhotographerId, viewingAsPhotographer
        if (res.data.meta) {
          currentUserAdmin = res.data.meta.current_user_is_admin
          currentUserApiVendor = res.data.meta.current_user_is_api_vendor
          viewAsPhotographerId = res.data.meta.view_as_photographer_id
          viewingAsPhotographer = res.data.meta.view_as_photographer
        } else {
          currentUserAdmin = false
          currentUserApiVendor = false
          viewAsPhotographerId = null
          viewingAsPhotographer = null
        }
        let museaApi = new MuseaApi()
        var parsedOrder = museaApi.mergeAttributesForLabOrder(res.data);
        this.setState({
          labOrder: parsedOrder,
          updatingLabOrder: false,
          formattedLineItems: parsedOrder.lineItems,
          userIsAdmin: currentUserAdmin,
          userIsApiVendor: currentUserApiVendor,
          viewAsPhotographerId: viewAsPhotographerId,
          viewingAsPhotographer: viewingAsPhotographer,
        })
      })
      .catch(err =>
        this.throwError(err))
  }

  updateStatus(guid, newStatus) {
    //console.log("updating " + guid + " to status " + newStatus)
    //e.preventDefault();
    this.setState({ updatingLabOrder: true })

    var token = Auth.getToken()
    //console.log("got guid")
    axios
      .patch(`api/v2/lab_orders/${guid}`, { type: "status", value: newStatus }, { headers: { 'token': token } })
      .then(res => {
        //console.log("res " + res.data['data'].attributes);
        //console.log("got a response")
        var parsedOrder = this.mergeAttributesForLabOrder(res.data);
        //console.log("setting state")
        this.setState({
          redirectToReferrer: true,
          updatingLabOrder: false,
          auth: Auth.isAuthenticated(),
          from: { pathname: "/" },
          guid: guid,
          labOrder: parsedOrder,
          formattedLineItems: parsedOrder.lineItems,
          loading: false,
          errorState: false
        });
        return true;
      })
      .catch(err => {
        this.throwError(err)
        //let error = JSON.parse(err.response)
        //this.throwError(error)
      })
  }

  throwError(err) {
    let errorMessage
    if (err.response != null) {
      let errorObject = err.response.data.error
      if (errorObject != null) {
        errorMessage = "\nERROR CODE: " + errorObject.status + "\n ERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.developer_message
        if (err.response.data.error.more_info != null) {
          alert("ERROR MESSAGE: " + errorMessage)
          console.log("ERROR MESSAGE: " + errorMessage + "\nMORE INFO: " + err.response.data.error.more_info.join(" ,").split(",").join("\n"))
        } else {
          alert("ERROR MESSAGE: " + errorMessage)
          console.log(errorMessage + " More info not available.")
        }
      } else {
        errorMessage = "\nERROR CODE: " + err.response
      }
    } else {
      alert("ERROR MESSAGE: " + err.stack)
      errorMessage = err.stack
    }
  }

  mergeAttributesForLabOrder(response) {
    //let labOrderData = response['data'];
    let includedObjects = response['included'];
    let includedRelationships = response['data']['relationships'];
    let baseLabOrder = response['data']['attributes'];
    let labOrder = {};
    Object.assign(labOrder, baseLabOrder);

    if (includedRelationships.images.data.length > 0) {
      const foundImages = includedRelationships.images.data.map(obj => {
        return this.returnItem(includedObjects, obj);
      })
      //let cropOptions = {}
      //let updatedImages = this.updateCropOptionsForImages(foundImages, cropOptions)
      labOrder["images"] = foundImages

    } else {
      labOrder["images"] = [];
    }

    if (includedRelationships.line_items.data.length > 0) {
      const lineItems = includedRelationships.line_items.data.map(obj => {
        return this.returnItem(includedObjects, obj);
      })
      let formattedLineItems = this.formatLineItemsForCart(lineItems, labOrder)
      labOrder["lineItems"] = formattedLineItems;
    } else {
      labOrder["lineItems"] = [];
    }

    return labOrder
  }

  returnItem(includedObjects, obj) {
    var foundItem = includedObjects.filter(item => (
      obj.id === item.id && obj.type === item.type));
    if (foundItem.length > 0) {
      return foundItem.shift().attributes;
    } else {
      return {}
    }
  }

  formatLineItemsForCart(lineItems, labOrder) {
    let formattedLineItems = lineItems.map(line_item => {
      let lineItem = {}
      let photoUrl
      let formattedPrintBorder
      if (line_item.photograph_url != null) {
        photoUrl = line_item.photograph_url
      } else {
        photoUrl = this.mergePhotoAndLineItem(line_item, labOrder)
      }

      if (line_item.print_border != null && line_item.print_border.length > 0) {
        formattedPrintBorder = this.convertBorderToNumber(line_item.print_border)
      } else {
        formattedPrintBorder = "no border"
      }

      //let notesString
      //if (line_item.notes.length > 0) {
      //notesString = line_item.notes
      //} else {
      //notesString = "none"
      //}

      let category
      let detailOne
      let detailTwo
      let detailThree
      let finishPrice
      if (line_item.finishingService != null && Object.keys(line_item.finishingService).length > 0) {
        category = line_item.finishing_service.finishing_category
        detailOne = line_item.finishing_service.formatted_detail_one
        detailTwo = line_item.finishing_service.formatted_detail_two
        detailThree = line_item.finishing_service.formatted_detail_three
        finishPrice = line_item.finishing_service.price
      } else {
        category = "none"
        detailOne = ""
        detailTwo = ""
        detailThree = ""
        finishPrice = 0.00
      }

      lineItem.labOrderGuid = labOrder.guid
      lineItem.guid = line_item.guid
      lineItem.photoUrl = photoUrl
      lineItem.paper = line_item.print_size_attributes.paper
      lineItem.paperGuid = line_item.print_size_attributes.paper_guid
      lineItem.printSize = line_item.print_size_attributes.print_size
      lineItem.printSizeGuid = line_item.print_size_attributes.guid
      lineItem.printBorder = formattedPrintBorder
      lineItem.printBorderRaw = line_item.printBorder
      lineItem.printPrice = parseFloat(+line_item.print_size_attributes.price).toFixed(2)
      lineItem.externalId = line_item.external_id
      lineItem.notes = line_item.notes
      lineItem.internalNotes = line_item.internal_notes
      lineItem.cropAttributes = {}
      lineItem.cropAttributes.x = parseFloat(+line_item.photograph_attributes.crop_x).toFixed(2)
      lineItem.cropAttributes.y = parseFloat(+line_item.photograph_attributes.crop_y).toFixed(2)
      lineItem.cropAttributes.height = parseFloat(+line_item.photograph_attributes.crop_h).toFixed(2)
      lineItem.cropAttributes.width = parseFloat(+line_item.photograph_attributes.crop_w).toFixed(2)
      lineItem.quantity = line_item.quantity
      lineItem.totalPrice = parseFloat(+line_item.subtotal).toFixed(2)
      lineItem.finishingCategory = category
      lineItem.finishingDetailOne = detailOne
      ////RISING WARM - ASH - 3/4"
      lineItem.finishingDetailTwo = detailTwo
      ////2" MAT BORDER
      lineItem.finishingDetailThree = detailThree
      ////STANDARD ACRYLIC
      lineItem.finishingPrice = parseFloat(+finishPrice).toFixed(2)

      return lineItem
    })
    return formattedLineItems
  }

  convertBorderToNumber(border) {
    let formattedBorderString
    if (border === "none") {
      formattedBorderString = "no border"
    } else if (border === "one_eighth") {
      formattedBorderString = "1/8\" border"
    } else if (border === "one_fourth") {
      formattedBorderString = "1/4\" border"
    } else if (border === "one_half") {
    } else if (border === "three_fourths") {
    } else if (border === "one") {
    } else if (border === "one_and_one_half") {
    } else if (border === "two") {
    } else if (border === "three") {
    } else {
      formattedBorderString = "no border"
    }
    return formattedBorderString
  }


  mergePhotoAndLineItem(line_item, labOrder) {
    let photoUrl
    let foundImages = labOrder.images.filter(obj => obj.guid === line_item.photograph_guid)
    if (foundImages.length > 0) {
      photoUrl = foundImages[0].presigned_photo_url
    } else {
      photoUrl = ""
    }
    return photoUrl
  }

  //applyCreditOrDiscountCode(e, type, value) {
  //e.preventDefault()
  //var token = Auth.getToken()
  //var guid = this.props.location.pathname["split"]("/")[2];
  //axios
  //.patch(`/api/v2/lab_orders/${guid}`,
  //.then(res => {
  //console.log("updated lab order")
  //var parsedOrder = this.mergeAttributesForLabOrder(res.data);
  //this.setState({
  //labOrder: parsedOrder,
  //formattedLineItems: parsedOrder.lineItems,
  //})
  //})
  //.catch(err =>
  //this.throwError(err))
  //}


  render() {
    //key, orderId, orderNotes, orderAdminNotes, orderIsApi, orderApiVendor, orderHasFraming, orderHasMounting, orderHasMatting, labOrderCard, downloadAllLink, 
    //let {printsColor, mountColor, matColor, frameColor, canvasColor,
    //apiOrderColor, boutiqueColor, resetColor} = this.state

    const { orderExternalId, orderNotes, orderShippingMethod, orderAdminNotes, orderPhotographerBusinessName, orderPhotographerName, parsedDate, includedAttributes, guid, orderName, coverPhoto, orderPaidAt, orderUpdatedAt } = this.state
    let orderStatus

    if (this.state.labOrder != null) {
      orderStatus = this.state.labOrder.status
    } else {
      orderStatus = this.state.orderStatus
    }

    let formattedPaidAt, coverPhotoSrcSet
    //downloadAllLinkDiv, , sizes,
    //if (downloadAllLink && downloadAllLink.length > 0) {
    //downloadAllLinkDiv = <a
    //href={downloadAllLink}
    //target="_blank"
    //rel="noopener noreferrer"
    //className="downloadAllLink"
    //>
    //<img anima-src={downloadCloud}
    //alt="download cloud"
    //className="outlineclouddownloadblack48dp"
    //src={downloadCloud}
    //href="target='_blank'"
    //style={{display: 'block', marginRight: 'auto',
    //marginLeft: 'auto', height: '1.5em', width: '1.5em'}}
    ///>
    //</a>
    //} else{
    //downloadAllLinkDiv = <div></div>
    //}

    let dropdownId = `status-dropdown-${guid}`
    if (orderPaidAt) {
      formattedPaidAt = this.formatOrderDate(orderPaidAt)
    } else {
      formattedPaidAt = this.formatOrderDate(orderUpdatedAt)
    }

    let notesIcons = []
    let adminIcon = <div style={{ display: 'inline-block', marginLeft: 'auto', marginRight: 'auto' }}>

      <img src={adminNotesIcon}
        style={{ marginLeft: 'auto', marginRight: 'auto', float: 'none', display: 'inline-block' }}
        alt=""
        className="image-21" />

    </div>
    let notesIcon = <div style={{ marginLeft: 'auto', marginRight: 'auto', float: 'none', display: 'inline-block' }}>
      <img src={orderNotesIcon}
        style={{ marginLeft: 'auto', marginRight: 'auto', float: 'none', display: 'inline-block' }}
        alt=""
        className="image-20" />
    </div>

    let shippingIcon = <div style={{ display: 'inline-block', marginLeft: 'auto', marginRight: 'auto' }}>

      <img src={expiditedShippingIcon}
        style={{ marginLeft: 'auto', marginRight: 'auto', float: 'none', display: 'inline-block', height: '20px', width: "35px" }}
        alt=""
        className="image-21" />

    </div>

    //if (downloadAllLink && downloadAllLink.length > 0) {
    //notesIcons.push(downloadAllLinkDiv)
    //}
    

    if (orderAdminNotes && orderAdminNotes !== null && orderAdminNotes.length > 0) {
      notesIcons.push(adminIcon)
    }

    if (orderNotes && orderNotes !== null && orderNotes.length > 0) {
      notesIcons.push(notesIcon)
    }

    if ((orderStatus !== "open" && orderShippingMethod === "two_day") || (orderStatus !== "open" && orderShippingMethod === "overnight")) {
      notesIcons.push(shippingIcon)
    }

    //let parsedDates = []
    let coverPhotoUrl
    if (coverPhoto) {
      coverPhotoUrl = coverPhoto;
      coverPhotoSrcSet = `${coverPhotoUrl} 500w`
    } else {
      coverPhotoUrl = noPhoto;
      coverPhotoSrcSet = `${noPhoto} 500w`
    }
    let orderCardLink
    if (this.props.mergeToOrderGuid !== null) {
      orderCardLink =
        <div className="div-block" style={{
          height: '260px',
          textAlign: 'center',
          verticalAlign: 'middle'
        }}>
          <Link to={{
            pathname: `/lab-orders/${this.props.mergeToOrderGuid}/merge-order/${guid}/review`,
          }}>
            <img alt="cover"
              anima-src={coverPhotoUrl}
              className=""
              srcSet={coverPhotoSrcSet}
              sizes="(max-width: 479px) 125px, (max-width: 767px) 26vw, (max-width: 991px) 17vw, 20vw"
              style={{ maxHeight: '260px' }}
              src={coverPhotoUrl} />
          </Link>
        </div>

    } else {
      orderCardLink = <div className="div-block" style={{
        height: '260px',
        textAlign: 'center',
        verticalAlign: 'middle'
      }}>
        <Link to={{
          pathname: `/lab-orders/${guid}`,
          state: {
            orderGuid: `${guid}`
          }
        }}>
          <img alt="cover"
            anima-src={coverPhotoUrl}
            className=""
            srcSet={coverPhotoSrcSet}
            sizes="(max-width: 479px) 125px, (max-width: 767px) 26vw, (max-width: 991px) 17vw, 20vw"
            style={{ maxHeight: '260px' }}
            src={coverPhotoUrl} />
        </Link>
      </div>
    }
    if (this.state.updatingLabOrder) {
      return (
        <div style={{}}>

          <div className="w-clearfix">
            <span className="text-block-10" style={{ width: '100%', display: 'inline-flex' }}>
              <span style={{ width: '70%', textAlign: 'left', marginLeft: '1em', display: 'inline-flex' }}>{parsedDate} </span>
              <span style={{ width: '30%', direction: 'rtl', display: 'inline-flex' }}> {includedAttributes}</span>
            </span>
          </div>

          <div className="div-block" style={{
            height: '260px',
            textAlign: 'center',
            verticalAlign: 'middle'
          }}>
            <Link to={{
              pathname: `/lab-orders/${guid}`,
              state: {
                orderGuid: `${guid}`
              }
            }}>
              <img alt="cover"
                anima-src={coverPhotoUrl}
                className=""
                srcSet={coverPhotoSrcSet}
                sizes="(max-width: 479px) 125px, (max-width: 767px) 26vw, (max-width: 991px) 17vw, 20vw"
                style={{ maxHeight: '260px' }}
                src={coverPhotoUrl} />
            </Link>
          </div>

          <div className="div-block-2">
            <div className="text-block-10">
              <div className="bold-text-2" style={{ textTransform: 'uppercase' }}>
                <strong> updating lab order... </strong>
              </div>
            </div>
          </div>
        </div>
      )

    } else {

      return (
        <div style={{}}>
          <div className="w-clearfix">
            <span className="text-block-10" style={{ width: '100%', display: 'inline-flex' }}>
              <span style={{ width: '70%', textAlign: 'left', marginLeft: '1em', display: 'inline-flex' }}>{parsedDate} </span>
              <span style={{ width: '30%', direction: 'rtl', display: 'inline-flex' }}> {includedAttributes}</span>
            </span>
          </div>

          {orderCardLink}

          <div className="w-clearfix" style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
            {notesIcons}
          </div>

          <div className="div-block-2">
            <div className="text-block-10">
              <div className="bold-text-2" style={{ textTransform: 'uppercase' }}>
                <strong> {orderName} </strong>
              </div>


              <br />

              {orderPhotographerName}

              <br />

              {orderPhotographerBusinessName}

              <br />

              {formattedPaidAt}

              <br />

              {orderExternalId}

              <br />

              <div data-delay="0"
                onClick={(e) => { this.toggleDropdownOpen(e, guid) }}
                style={{ textAlign: 'center' }}
                className="dropdown w-dropdown">
                <div className="dropdown-toggle w-dropdown-toggle">
                  <div className="icon w-icon-dropdown-toggle"
                    style={{ marginTop: '24px' }} >
                  </div>
                  <div className="text-block-11">
                    <strong style={{ textTransform: 'uppercase' }}>{orderStatus}</strong>
                  </div>
                </div>
                <nav className="w-dropdown-list" id={dropdownId}>
                  <div className="dropdown-link w-dropdown-link"
                    onClick={(e) => { this.updateLabOrder(e, guid, "status", "open") }}>
                    OPEN</div>
                  <div className="dropdown-link w-dropdown-link"
                    onClick={(e) => { this.updateLabOrder(e, guid, "status", "paid") }}>
                    PAID</div>
                  <div className="dropdown-link-2 w-dropdown-link"
                    onClick={(e) => { this.updateLabOrder(e, guid, "status", "printing") }}>
                    PRINTING</div>
                  <div className="dropdown-link-3 w-dropdown-link"
                    onClick={(e) => { this.updateLabOrder(e, guid, "status", "framing") }}>
                    FRAMING</div>
                  <div className="dropdown-link-10 w-dropdown-link"
                    onClick={(e) => { this.updateLabOrder(e, guid, "status", "shipped") }}>
                    SHIPPED</div>
                  <div className="dropdown-link-11 w-dropdown-link"
                    onClick={(e) => { this.updateLabOrder(e, guid, "status", "cancelled") }}>
                    CANCELLED</div>
                </nav>
              </div>

            </div>
          </div>

        </div>
      )
    }

  }
}

export default AdminLabOrderCard;
