import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
//import escapeRegExp from 'escape-string-regexp'
//import _sortBy from 'lodash';
import LineItemSummary from "./components/LineItemSummary.js";
import OrderSummary from "./components/OrderSummary.js";
import OrderSummaryAdmin from "./components/OrderSummaryAdmin.js";

import Honeybadger from 'honeybadger-js'
import ErrorBoundary from '@honeybadger-io/react'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import NavBar from "./components/NavBar.js";
import axios from "axios";
import Auth from "./modules/Auth.js";
import MuseaApi from "./modules/MuseaApi.js"

//import photo1 from "./img/order-summary-20-47270002web.png";
//import photo2 from "./img/order-summary-20-47270002web.png";
//import photo3 from "./img/order-summary-20-47630007web.png";
//import photo4 from "./img/order-summary-20-47250010web.png";
//import photo5 from "./img/order-summary-20-47250007web.png";

//import "./css/orderPreviewOpen.css";
//import "./css/newWebflow.css";

class OrderPreview extends Component {
  constructor(props) {
    super(props);
    const hb_config = {
      api_key: process.env.REACT_APP_HB_KEY,
      environment: process.env.REACT_APP_CURRENT_ENV,
      revision: 'current'
    }
    this.honeybadger = Honeybadger.configure(hb_config)
    this.state = {
      loading: true,
      honeybadger: this.honeybadger,
      updatingLabOrder: false,
      showShippingAddressForm: false,
      guid: this.props.location.pathname["split"]("/")[2],
      errorState: false,
      shippingInfo: null,
    };
    this.throwError = this.throwError.bind(this);
    this.changeQty = this.changeQty.bind(this);
    this.updateLabOrder = this.updateLabOrder.bind(this);
    this.sendToApi = this.sendToApi.bind(this);
    this.createDownloadAllLink = this.createDownloadAllLink.bind(this);
    this.refundLabOrder = this.refundLabOrder.bind(this);
    this.toggleShippingAddressForm = this.toggleShippingAddressForm.bind(this);
    this.bustLabOrderCache = this.bustLabOrderCache.bind(this);
  }

  componentDidMount() {
    let museaApi = new MuseaApi
    museaApi.checkAndResetCache()
    this.getLabOrder()
    console.log(this.state.parsedOrder)
  }

  componentWillReceiveProps(nextProps) {
    //this.getLabOrder()
  }

  getLabOrder = (e, data) => {
    //e.preventDefault();
    var token = Auth.getToken()
    var guid = this.props.location.pathname["split"]("/")[2];
    axios
      .get(`api/v2/lab_orders/${guid}`, { headers: { 'token': token } })
      .then(res => {

        //console.log("res " + res.data['data'].attributes);
        let museaApi = new MuseaApi()
        var parsedOrder = museaApi.mergeAttributesForLabOrder(res.data);

        let currentUserAdmin, currentUserApiVendor, viewAsPhotographerId, viewingAsPhotographer
        if (res.data.meta) {
          currentUserAdmin = res.data.meta.current_user_is_admin
          currentUserApiVendor = res.data.meta.current_user_is_api_vendor
          viewAsPhotographerId = res.data.meta.view_as_photographer_id
          viewingAsPhotographer = res.data.meta.view_as_photographer
        } else {
          currentUserAdmin = false
          currentUserApiVendor = false
          viewAsPhotographerId = null
          viewingAsPhotographer = null
        }

        this.setState({
          redirectToReferrer: true,
          auth: Auth.isAuthenticated(),
          from: { pathname: "/" },
          labOrder: parsedOrder,
          shippingAddress: parsedOrder.shipping_address,
          formattedLineItems: parsedOrder.lineItems,
          userIsAdmin: currentUserAdmin,
          userIsApiVendor: currentUserApiVendor,
          viewAsPhotographerId: viewAsPhotographerId,
          viewingAsPhotographer: viewingAsPhotographer,
          loading: false,
          errorState: false
        });
        return true;
      })
      .catch(err => {
        this.throwError(err)
        //let error = JSON.parse(err.response)
        //this.throwError(error)
      })
  };

  toggleShippingAddressForm(e, shippingInfo) {
    e.preventDefault()
    if (this.state.showShippingAddressForm) {
      this.setState({
        showShippingAddressForm: false,
      })
    } else {
      this.setState({
        showShippingAddressForm: true,
        shippingInfo: shippingInfo
      })
    }
  }

  bustLabOrderCache(e) {
    e.preventDefault()
    this.updateLabOrder(e, "updated_at", "now")

  }

  createDownloadAllLink(e, orderGuid) {
    e.preventDefault()
    this.setState({ updatingLabOrder: true })
    var token = Auth.getToken()
    axios
      .post(`api/v2/lab_orders/${orderGuid}/create_zip_file`, {}, { headers: { 'token': token } })
      .then(res => {
        //console.log("sent lab order to api")

        let currentUserAdmin, currentUserApiVendor, viewAsPhotographerId, viewingAsPhotographer
        if (res.data.meta) {
          currentUserAdmin = res.data.meta.current_user_is_admin
          currentUserApiVendor = res.data.meta.current_user_is_api_vendor
          viewAsPhotographerId = res.data.meta.view_as_photographer_id
          viewingAsPhotographer = res.data.meta.view_as_photographer
        } else {
          currentUserAdmin = false
          currentUserApiVendor = false
          viewAsPhotographerId = null
          viewingAsPhotographer = null
        }
        let museaApi = new MuseaApi()
        var parsedOrder = museaApi.mergeAttributesForLabOrder(res.data);
        this.setState({
          labOrder: parsedOrder,
          shippingAddress: parsedOrder.shipping_address,
          updatingLabOrder: false,
          formattedLineItems: parsedOrder.lineItems,
          userIsAdmin: currentUserAdmin,
          userIsApiVendor: currentUserApiVendor,
          viewAsPhotographerId: viewAsPhotographerId,
          viewingAsPhotographer: viewingAsPhotographer,
        })
      })
      .catch(err =>
        this.throwError(err))
  }

  sendToApi(e, orderGuid) {
    e.preventDefault()

    var token = Auth.getToken()
    this.setState({ updatingLabOrder: true })
    axios
      .post(`/api/v2/lab_orders/${orderGuid}/send_to_api`, {},
        { headers: { 'token': token } })
      .then(res => {
        //console.log("sent lab order to api")

        let currentUserAdmin, currentUserApiVendor, viewAsPhotographerId, viewingAsPhotographer
        if (res.data.meta) {
          currentUserAdmin = res.data.meta.current_user_is_admin
          currentUserApiVendor = res.data.meta.current_user_is_api_vendor
          viewAsPhotographerId = res.data.meta.view_as_photographer_id
          viewingAsPhotographer = res.data.meta.view_as_photographer
        } else {
          currentUserAdmin = false
          currentUserApiVendor = false
          viewAsPhotographerId = null
          viewingAsPhotographer = null
        }
        let museaApi = new MuseaApi()
        var parsedOrder = museaApi.mergeAttributesForLabOrder(res.data);
        this.setState({
          labOrder: parsedOrder,
          shippingAddress: parsedOrder.shipping_address,
          updatingLabOrder: false,
          formattedLineItems: parsedOrder.lineItems,
          userIsAdmin: currentUserAdmin,
          userIsApiVendor: currentUserApiVendor,
          viewAsPhotographerId: viewAsPhotographerId,
          viewingAsPhotographer: viewingAsPhotographer,
        })
      })
      .catch(err =>
        this.throwError(err))
  }

  refundLabOrder(guid, refundAmount, refundNote) {
    this.setState({ updatingLabOrder: true })

    var token = Auth.getToken()
    var parsedRefundAmount = parseFloat(+refundAmount).toFixed(2)

    axios
      .post(`/api/v2/lab_orders/${guid}/refund`,
        {
          refund_amount: parsedRefundAmount,
          refund_note: refundNote
        }, { headers: { 'token': token } })
      .then(res => {
        //console.log("updated lab order")

        let currentUserAdmin, currentUserApiVendor, viewAsPhotographerId, viewingAsPhotographer
        if (res.data.meta) {
          currentUserAdmin = res.data.meta.current_user_is_admin
          currentUserApiVendor = res.data.meta.current_user_is_api_vendor
          viewAsPhotographerId = res.data.meta.view_as_photographer_id
          viewingAsPhotographer = res.data.meta.view_as_photographer
        } else {
          currentUserAdmin = false
          currentUserApiVendor = false
          viewAsPhotographerId = null
          viewingAsPhotographer = null
        }
        let museaApi = new MuseaApi()
        var parsedOrder = museaApi.mergeAttributesForLabOrder(res.data);
        this.setState({
          labOrder: parsedOrder,
          shippingAddress: parsedOrder.shipping_address,
          updatingLabOrder: false,
          formattedLineItems: parsedOrder.lineItems,
          userIsAdmin: currentUserAdmin,
          userIsApiVendor: currentUserApiVendor,
          viewAsPhotographerId: viewAsPhotographerId,
          viewingAsPhotographer: viewingAsPhotographer,
        })
      })
      .catch(err =>
        this.throwError(err))
  }

  updateLabOrder(e, type, value) {
    e.preventDefault()
    this.setState({ updatingLabOrder: true })

    var guid = this.props.location.pathname["split"]("/")[2];
    const name = e.target.name;
    const val = e.target.value;


    var token = Auth.getToken()

    if (name === "socialMediaShare") {
      type = "permission_to_share"
      if (val === "false") {
        value = false
      } else {
        value = true
      }
    }
    axios
      .patch(`/api/v2/lab_orders/${guid}`,
        {
          type: type,
          value: value
        }, { headers: { 'token': token } })
      .then(res => {
        //console.log("updated lab order")

        let currentUserAdmin, currentUserApiVendor, viewAsPhotographerId, viewingAsPhotographer
        if (res.data.meta) {
          currentUserAdmin = res.data.meta.current_user_is_admin
          currentUserApiVendor = res.data.meta.current_user_is_api_vendor
          viewAsPhotographerId = res.data.meta.view_as_photographer_id
          viewingAsPhotographer = res.data.meta.view_as_photographer
        } else {
          currentUserAdmin = false
          currentUserApiVendor = false
          viewAsPhotographerId = null
          viewingAsPhotographer = null
        }
        let museaApi = new MuseaApi()
        var parsedOrder = museaApi.mergeAttributesForLabOrder(res.data);
        this.setState({
          labOrder: parsedOrder,
          shippingAddress: parsedOrder.shipping_address,
          updatingLabOrder: false,
          formattedLineItems: parsedOrder.lineItems,
          userIsAdmin: currentUserAdmin,
          userIsApiVendor: currentUserApiVendor,
          viewAsPhotographerId: viewAsPhotographerId,
          viewingAsPhotographer: viewingAsPhotographer,
        })
      })
      .catch(err =>
        this.throwError(err))
  }



  changeQty(UpOrDown, currentQty, lineItemGuid) {
    let newQty
    if (UpOrDown === "increase") {
      newQty = currentQty + 1
    } else {
      newQty = currentQty - 1
    }

    if (newQty > 0) {
      var token = Auth.getToken()
      axios
        .patch(`api/v2/line_items/${lineItemGuid}/update_quantity`, {
          line_items: [{ quantity: newQty }]
        },
          { headers: { 'token': token } }
        )
        .then(res => {
          //console.log("res " + res.data['data'].attributes);

          let currentUserAdmin, currentUserApiVendor, viewAsPhotographerId, viewingAsPhotographer
          if (res.data.meta) {
            currentUserAdmin = res.data.meta.current_user_is_admin
            currentUserApiVendor = res.data.meta.current_user_is_api_vendor
            viewAsPhotographerId = res.data.meta.view_as_photographer_id
            viewingAsPhotographer = res.data.meta.view_as_photographer
          } else {
            currentUserAdmin = false
            currentUserApiVendor = false
            viewAsPhotographerId = null
            viewingAsPhotographer = null
          }

          let museaApi = new MuseaApi()
          var parsedOrder = museaApi.mergeAttributesForLabOrder(res.data);
          this.setState({
            redirectToReferrer: true,
            auth: Auth.isAuthenticated(),
            from: { pathname: "/" },
            labOrder: parsedOrder,
            shippingAddress: parsedOrder.shipping_address,
            userIsAdmin: currentUserAdmin,
            userIsApiVendor: currentUserApiVendor,
            viewAsPhotographerId: viewAsPhotographerId,
            viewingAsPhotographer: viewingAsPhotographer,
            formattedLineItems: parsedOrder.lineItems,
            loading: false,
            errorState: false
          });
          return true;
        })
        .catch(err => {
          this.throwError(err)
          //let error = JSON.parse(err.response)
          //this.throwError(error)
        })
    }
  }

  deleteLineItem(lineItemGuid) {
    var token = Auth.getToken()
    axios
      .delete(`api/v2/line_items/${lineItemGuid}`,
        { headers: { 'token': token } }
      )
      .then(res => {
        //console.log("res " + res.data['data'].attributes);

        let currentUserAdmin, currentUserApiVendor, viewAsPhotographerId, viewingAsPhotographer
        if (res.data.meta) {
          currentUserAdmin = res.data.meta.current_user_is_admin
          currentUserApiVendor = res.data.meta.current_user_is_api_vendor
          viewAsPhotographerId = res.data.meta.view_as_photographer_id
          viewingAsPhotographer = res.data.meta.view_as_photographer
        } else {
          currentUserAdmin = false
          currentUserApiVendor = false
          viewAsPhotographerId = null
          viewingAsPhotographer = null
        }
        let museaApi = new MuseaApi()
        var parsedOrder = museaApi.mergeAttributesForLabOrder(res.data);
        this.setState({
          redirectToReferrer: true,
          auth: Auth.isAuthenticated(),
          from: { pathname: "/" },
          labOrder: parsedOrder,
          shippingAddress: parsedOrder.shipping_address,
          formattedLineItems: parsedOrder.lineItems,
          userIsAdmin: currentUserAdmin,
          userIsApiVendor: currentUserApiVendor,
          viewAsPhotographerId: viewAsPhotographerId,
          viewingAsPhotographer: viewingAsPhotographer,
          loading: false,
          errorState: false
        });
        return true;
      })
      .catch(err => {
        this.throwError(err)
        //let error = JSON.parse(err.response)
        //this.throwError(error)
      })
  }

  throwError(err) {
    let errorMessage, errorObject
    if (err.response && err.response.data && err.response.data["error"].status === 401) {
      this.setState({
        userLoggedOut: true
      })
      return false
    } else {
      if (err.response && err.response.data["error"] != null) {
        errorObject = err.response.data["error"]
        errorMessage = "\nERROR CODE: " + errorObject.status + "\nERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.user_message["message"]
        if (errorObject["more_info"] != null) {
          this.state.honeybadger.notify(err)
          alert("ERROR: " + errorMessage)
          console.log("ERROR: " + errorMessage + "\nMORE INFO: " + errorObject["more_info"].join(" ,").split(",").join("\n"))
        } else {
          this.state.honeybadger.notify(err)
          alert("ERROR: " + errorMessage)
          console.log(errorMessage + " More info not available.")
        }
      } else if (err.response) { 
        errorMessage = "\nERROR CODE: " + err.response.status + "\n ERROR MESSAGE: " + err.response.message
      } else if (err.message) { 
        errorMessage = "\nERROR MESSAGE: " + err.message
      } else {
        errorMessage = "\nERROR"
      }
    }
    this.setState({
      loading: false,
      selections: [],
      errorState: true,
      addingToCart: false,
      updatingLabOrder: false,
      errorMessage: errorMessage
    })
  }

  notify() {
    toast('Order needs to be in open status to checkout!', { position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true });
  }
  sortNames = (names) => {
    let mappedNames = names.map(function (value, index) {
      let fileName = value["imageName"].split(".")
      fileName = fileName[0]
      console.log("FileName", fileName)
      let numericPart, stringPart;
      let splitString = fileName.split("_")
      if (!isNaN(splitString[0])) {
        numericPart = splitString[0]
        if (splitString[1]) {
          stringPart = splitString[1].toLowerCase()
        } else {
          stringPart = ""
        }
      } else {
        if (splitString[1]) {
          numericPart = splitString[1]
        } else {
          numericPart = 999999999
        }
        stringPart = splitString[0].toLowerCase()
      }
      return {
        index: index,
        numericPart: numericPart,
        stringPart: stringPart,
      }
    })
    mappedNames.sort((a, b) => {
      if (a.numericPart > b.numericPart)
        return 1;
      if (a.numericPart < b.numericPart)
        return -1;
      // if (a.stringPart > b.stringPart)
      //   return 1;
      // if (a.stringPart < b.stringPart)
      //   return -1;
      return 0;
    })
    let result = [];
    mappedNames.forEach((value) => result.push(names[value["index"]]))
    return result;
  }
  render() {
    const { loading, errorState, errorMessage, formattedLineItems, labOrder, shippingAddress, userIsAdmin } = this.state

    let summaryInfo
    if (this.state.updatingLabOrder) {
      summaryInfo = <div>
        <div className="orderSummaryInfo">

          <div className="actionFields" style={{
            display: 'inline-block',
            width: '80%',
            fontWeight: '700',
            textAlign: 'center',
            marginTop: '10%',
            marginLeft: '10%',
            verticalAlign: 'top'
          }}>
            updating lab order...
          </div>
        </div>
      </div>
    } else if (userIsAdmin && labOrder) {
      summaryInfo = <OrderSummaryAdmin bustLabOrderCache={this.bustLabOrderCache} toggleShippingAddressForm={this.toggleShippingAddressForm} shippingAddress={shippingAddress} showShippingForm={this.state.showShippingAddressForm} refundLabOrder={this.refundLabOrder} createDownloadAllLink={this.createDownloadAllLink} sendToApi={this.sendToApi} updateLabOrder={this.updateLabOrder} key={labOrder.guid} trackingUrl={labOrder.tracking_url} labOrder={labOrder} />
    } else if (labOrder) {
      summaryInfo = <OrderSummary key={labOrder.guid} labOrder={labOrder} />
    } else {
      summaryInfo = <div></div>
    }

    let continueOrderButton, editDisabled

    if (labOrder && labOrder.status === "open") {
      editDisabled = true
      continueOrderButton = <Link to={{ pathname: `/lab-orders/${this.state.guid}/edit/order-builder` }}
        style={{ textDecoration: 'none', color: 'white' }} >
        <div className="button-28 w-button">
          CONTINUE ORDER
          </div>
      </Link>
    } else {
      continueOrderButton = <div></div>
      editDisabled = true
    }

    let lineItems
    if (labOrder != null && labOrder.lineItems != null) {
      lineItems = labOrder.lineItems
    }
    let currentIndex = 0
    let renderedLineItems, updateQtyValue, canDeleteValue
    if (labOrder != null && labOrder.status === "open") {
      updateQtyValue = false
      canDeleteValue = true
    } else {
      updateQtyValue = true
      canDeleteValue = false
    }
    if (lineItems != null) {
      // renderedLineItems = formattedLineItems
      //   .sort((a, b) => a.guid - b.guid)
      //   .map(lineItem => {
      //     let index = currentIndex += 1
      //     return <LineItemSummary key={lineItem.guid + "" + index} editDisabled={editDisabled} isReviewScreen={false} userIsAdmin={userIsAdmin} deleteLineItem={this.deleteLineItem} canDeleteItem={canDeleteValue} qtyNoEdit={updateQtyValue} changeQty={this.changeQty} labOrderGuid={lineItem.labOrderGuid} index={index} guid={lineItem.guid} lineItem={lineItem} lineItemId={lineItem.guid} photoGuid={lineItem.photoGuid} coverPhotoUrl={lineItem.photoUrl} imageName={lineItem.imageName} externalId={lineItem.externalId} notes={lineItem.notes} adminNotes={lineItem.internalNotes} printSize={lineItem.printSize} printSizeGuid={lineItem.printSizeGuid} printBorder={lineItem.printBorder} printBorderRaw={lineItem.printBorderRaw} paper={lineItem.paper} paperGuid={lineItem.paperGuid} printPrice={lineItem.printPrice} finishingCategory={lineItem.finishingCategory} finishingService={lineItem.finishingService} finishingServiceGuid={lineItem.finishingServiceGuid} finishingServiceDetailGuid={lineItem.finishingServiceDetailGuid} finishingDetailOne={lineItem.finishingDetailOne} finishingDetailTwo={lineItem.finishingDetailTwo} finishingDetailThree={lineItem.finishingDetailThree} finishingPrice={lineItem.finishingPrice} cropAttributes={lineItem.cropAttributes} quantity={lineItem.quantity} totalPrice={lineItem.totalPrice} />
      //   })

      renderedLineItems = this.sortNames(formattedLineItems)
        .map(lineItem => {
          let index = currentIndex += 1
          return <LineItemSummary key={lineItem.guid + "" + index} editDisabled={editDisabled} isReviewScreen={false} userIsAdmin={userIsAdmin} deleteLineItem={this.deleteLineItem} canDeleteItem={canDeleteValue} qtyNoEdit={updateQtyValue} changeQty={this.changeQty} labOrderGuid={lineItem.labOrderGuid} index={index} guid={lineItem.guid} lineItem={lineItem} lineItemId={lineItem.guid} photoGuid={lineItem.photoGuid} coverPhotoUrl={lineItem.photoUrl} croppedPhotoUrl={lineItem.photograph_cropped_url} imageName={lineItem.imageName} externalId={lineItem.externalId} notes={lineItem.notes} adminNotes={lineItem.internalNotes} printSize={lineItem.printSize} printSizeGuid={lineItem.printSizeGuid} printBorder={lineItem.printBorder} printBorderRaw={lineItem.printBorderRaw} paper={lineItem.paper} paperGuid={lineItem.paperGuid} printPrice={lineItem.printPrice} finishingCategory={lineItem.finishingCategory} finishingService={lineItem.finishingService} finishingServiceGuid={lineItem.finishingServiceGuid} finishingServiceDetailGuid={lineItem.finishingServiceDetailGuid} finishingDetailOne={lineItem.finishingDetailOne} finishingDetailTwo={lineItem.finishingDetailTwo} finishingDetailThree={lineItem.finishingDetailThree} frameGlassName={lineItem.frameGlassName} finishingPrice={lineItem.finishingPrice} cropAttributes={lineItem.cropAttributes} quantity={lineItem.quantity} totalPrice={lineItem.totalPrice} />
        })

    }

    if (this.props.location.state && this.props.location.state.notifyUserOfBadStatus) {
      this.notify()
    }

    if (loading) {
      return (
        <div>
          <NavBar />
          <div className="OrderPreviewOpen">
            <div className="smithorder"
              style={{
                marginTop: '10%',
                fontSize: '22px',
                fontWeight: 600,
                marginLeft: '50%'
              }}>
              loading...
            </div>
          </div>
        </div>
      )
    } else if (errorState) {
      return (
        <div>
          <NavBar />
          <div className="OrderPreviewOpen">
            <div className="smithorder"
              style={{
                marginTop: '10%',
                textAlign: 'center',
                width: '50%',
                fontSize: '22px',
                fontWeight: 600,
                marginLeft: '25%',
                overflowWrap: 'break-word'
              }}>
              ERROR LOADING: {errorMessage}
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <NavBar />
          <ToastContainer position="top-right" autoClose={10000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
          <div className="OrderPreviewOpen">

            {summaryInfo}

          </div>

          <div>
            <div className="text-block-77">ORDER SUMMARY</div>
          </div>

          {continueOrderButton}

          <div className="LineItemSummary">

            <div className="div-block-15">
              {renderedLineItems}
            </div>

          </div>
        </div>
      );
    }
  }
}

export default OrderPreview;
