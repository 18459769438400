import React, { Component } from "react";
import ShippingAddressForm from "./ShippingAddressForm.js";
//import { Link } from "react-router-dom";

//import "../css/newWebflow.css";

class OrderSummaryInfoAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labOrder: this.props.labOrder,
      trackingUrl: this.props.trackingUrl,
      loading: false
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        labOrder: nextProps.labOrder,
        trackingUrl: nextProps.trackingUrl
      })
    }
  }

  updateStatus(guid, newStatus) {
  }

  toggleDropdownOpen(e, guid) {
    e.preventDefault()
    var dropdownId = `status-dropdown-${guid}`
    if (e.target.classList.contains("w--open")) {
      e.target.classList.remove("w--open")
      document.querySelector(`#${dropdownId}`).classList.remove('w--open');
    } else {
      e.target.classList.add("w--open")
      document.querySelector(`#${dropdownId}`).classList.add('w--open');
    }
  }

  handleChange(e) {
    const name = e.target.name;
    const val = e.target.value;
    this.setState({
      [name]: val
    });
  }


  formatSentAtDate(sentAt) {
    let options = { hour: '2-digit', minute: '2-digit' };
    let rawParsedDate = new Date(Date.parse(sentAt)).toLocaleDateString("en-US", options)
    let parsedDate = rawParsedDate.toUpperCase()
    return parsedDate
  }

  render() {
    const { labOrder, trackingUrl } = this.state
    let sentToApi, downloadAllButton, guid, orderStatus, dropdownId, sentToApiAt, shippingAddressForm, shippingMethod, shippingColor

    guid = labOrder.guid
    orderStatus = labOrder.status
    dropdownId = `status-dropdown-${guid}`


    if (this.props.showShippingAddressForm) {
      shippingAddressForm = <ShippingAddressForm labOrder={labOrder} labOrderGuid={labOrder.guid} showAddressForm={true} updateLabOrder={this.props.updateLabOrder} newAddress={true} />
      // TODO Add autcomplete user address
    } else {
      shippingAddressForm = <div></div>
    }

    if (labOrder && labOrder.download_all_link && labOrder.download_all_link.length > 0) {
      downloadAllButton = <div>
        <div href="#" className="button-39 w-button" style={{ marginTop: '10px', marginBottom: '8px' }}>
          <div className="downloadAllButton">
            <a
              href={labOrder.download_all_link}
              target="_blank"
              style={{ color: 'black' }}
              rel="noopener noreferrer">
              DOWNLOAD ALL
                        </a>
          </div>
        </div>
        <div style={{ display: 'inline-flex', marginBottom: '10px' }} className="text-block-125">
          <div style={{ cursor: 'pointer', color: 'blue', marginLeft: '70px' }} onClick={(e) => { this.props.createDownloadAllLink(e, guid) }}>CREATE AGAIN</div>
        </div>
      </div>
    } else if (labOrder && guid && guid.length > 1) {
      downloadAllButton = <div href="#" className="button-39 w-button" style={{ marginTop: '10px', marginBottom: '40px' }}>
        <input type="submit" style={{}} value="CREATE ZIP" onClick={(e) => { this.props.createDownloadAllLink(e, guid) }} className="downloadAllButton" />
      </div>
    } else {
      downloadAllButton = <div href="#" className="button-39 w-button" style={{ marginTop: '10px', marginBottom: '40px' }}>
        <input type="submit" style={{}} value="LOADING..." className="downloadAllButton" />
      </div>
    }

    if (labOrder && labOrder.vendor_id && labOrder.last_sent_to_vendor_at) {
      sentToApiAt = this.formatSentAtDate(labOrder.last_sent_to_vendor_at)
      sentToApi = <div style={{ display: 'inline-flex', marginBottom: '20px' }}>
        <div className="sendToVendor text-block-123" style={{ marginTop: '10px', fontSize: '10pt', fontStyle: 'italic' }}>
          Sent to {labOrder.api_vendor}, {sentToApiAt}
        </div>

        <input type="submit" style={{ marginLeft: '10px', fontSize: '8pt', width: '110px' }} value="SEND AGAIN" onClick={(e) => { this.props.sendToApi(e, guid) }} className="submit-button-9 w-button" />
      </div>
    } else if (labOrder && labOrder.vendor_id) {
      sentToApi = <div style={{ display: 'inline-flex', marginBottom: '20px' }}>
        <div className="sendToVendor text-block-123" style={{ marginTop: '10px', fontSize: '10pt', fontStyle: 'italic' }}>
          Not sent to API yet.
                    </div>

        <input type="submit" style={{ marginLeft: '10px', fontSize: '8pt', width: '110px' }} value="SEND AGAIN" onClick={(e) => { this.props.sendToApi(e, guid) }} className="submit-button-9 w-button" />
      </div>

    } else {
      sentToApi = <div> </div>
    }

    if (labOrder && labOrder.shipping_method) {
      shippingMethod = "SHIPPING: " + labOrder.shipping_method.toUpperCase()
      if (labOrder.shipping_method === "overnight" || labOrder.shipping_method === "two_day") {
        shippingColor = 'red'
      } else {
        shippingColor = 'black'
      }
    } else if (labOrder && !labOrder.shipping_method) {
      shippingMethod = ""
      shippingColor = 'black'
    } else {
      shippingMethod = "SHIPPING: " + labOrder.shipping_method.toUpperCase()
      shippingColor = 'black'
    }

    if (this.state.loading) {
      return (
        <div>
          <div className="orderSummaryInfo">

            <div className="actionFields" style={{
              display: 'inline-block',
              width: '80%',
              fontWeight: '700',
              textAlign: 'center',
              verticalAlign: 'top'
            }}>
              loading...
          </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="orderSummaryInfo div-block-43" style={{ marginTop: '30px' }}>

            <div className="w-row">
              {shippingAddressForm}
            </div>

            <div className="w-row">

              <div className="w-col w-col-3">
                {downloadAllButton}

                <div className="w-form">
                  <form id="email-form" name="email-form" data-name="Email Form">
                    <input type="text"
                      className="text-field-33 w-input"
                      name="adminNote"
                      onChange={this.handleChange}
                      placeholder="ADMIN NOTE" />

                    <input type="submit" style={{ textAlign: 'center' }} value="SAVE NOTE" onClick={(e) => { this.props.updateLabOrder(e, "internal_notes", this.state.adminNote) }} className="submit-button-8 w-button" />
                  </form>
                </div>
              </div>

              <div className="w-col w-col-5">

                <div data-delay="0"
                  onClick={(e) => { this.toggleDropdownOpen(e, guid) }}
                  style={{ textAlign: 'center', height: '50px' }}
                  className="dropdown-10 w-dropdown">
                  <div className="dropdown-toggle w-dropdown-toggle" style={{ lineHeight: '12px' }}>
                    <div className="icon w-icon-dropdown-toggle"
                      style={{ marginTop: '24px', lineHeight: '3px' }} >
                    </div>
                    <div className="text-block-11">
                      <strong style={{ textTransform: 'uppercase' }}>{orderStatus}</strong>
                    </div>
                  </div>
                  <nav className="w-dropdown-list" id={dropdownId}>
                    <div className="dropdown-link w-dropdown-link"
                      onClick={(e) => { this.props.updateLabOrder(e, "status", "open") }}>
                      OPEN</div>
                    <div className="dropdown-link w-dropdown-link"
                      onClick={(e) => { this.props.updateLabOrder(e, "status", "paid") }}>
                      PAID</div>
                    <div className="dropdown-link-2 w-dropdown-link"
                      onClick={(e) => { this.props.updateLabOrder(e, "status", "printing") }}>
                      PRINTING</div>
                    <div className="dropdown-link-3 w-dropdown-link"
                      onClick={(e) => { this.props.updateLabOrder(e, "status", "framing") }}>
                      FRAMING</div>
                    <div className="dropdown-link-10 w-dropdown-link"
                      onClick={(e) => { this.props.updateLabOrder(e, "status", "shipped") }}>
                      SHIPPED</div>
                    <div className="dropdown-link-11 w-dropdown-link"
                      onClick={(e) => { this.props.updateLabOrder(e, "status", "cancelled") }}>
                      CANCELLED</div>
                  </nav>
                </div>

                <div className="w-form">
                  <form id="email-form" name="email-form" data-name="Email Form">
                    <input type="text"
                      className="text-field-33 w-input"
                      name="trackingUrl"
                      onChange={this.handleChange}
                      value={this.state.trackingUrl}
                      placeholder={trackingUrl ? trackingUrl : "TRACKING URL"} />

                    <input type="submit" value="SAVE" onClick={(e) => { this.props.updateLabOrder(e, "tracking_url", this.state.trackingUrl) }} className="submit-button-8 w-button" />
                  </form>

                </div>
              </div>


              <div className="w-col w-col-4">
                <div className="w-form">
                  <form id="email-form-2" name="email-form-2" data-name="Email Form 2" style={{ marginTop: '10px' }} className="form-13">
                    <div>
                      <input type="text" className="text-field-34 w-input" style={{ marginBottom: '5px' }} name="refundAmount" onChange={this.handleChange} placeholder="REFUND AMOUNT" />
                      <span style={{ marginLeft: '30%', color: 'red', fontWeight: '600' }}>current refund: ${parseFloat(+labOrder.refund_amount).toFixed(2)} </span>
                    </div>
                    <input type="text" className="text-field-35 w-input" name="refundNote" onChange={this.handleChange} placeholder="REASON FOR REFUND" />
                    <input type="submit" value="SUBMIT" onClick={(e) => { this.props.refundLabOrder(guid, this.state.refundAmount, this.state.refundNote) }} className="submit-button-9 w-button" />
                  </form>

                  <br />

                  <div style={{
                    display: 'inline-block',
                    marginTop: '10px'
                  }}>
                    {sentToApi}
                  </div>


                </div>
              </div>


              <div className="div-block-44">
                <div className="columns-5 w-row">
                  <div className="w-col w-col-2">
                    <div className="text-block-130">ADMIN NOTE</div>
                  </div>
                  <div className="w-col w-col-4">
                    <div className="text-block-132" style={{ color: 'red', fontWeight: '700' }}>
                      <span style={{ color: shippingColor }}>{shippingMethod}</span>
                      <br />
                    NOTES: {labOrder.internal_notes}
                    </div>
                  </div>
                  <div className="w-col w-col-2">
                    <div className="text-block-131">BOUTIQUE NOTES</div>
                  </div>
                  <div className="w-col w-col-4">
                    <div style={{ color: 'red', fontWeight: '700' }}>
                      {labOrder.boutique_shipping_note}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      );
    }
  }
}

export default OrderSummaryInfoAdmin;
