import React, { Component } from "react";
import Auth from "../modules/Auth.js"
import axios from "axios"
import validatedCheck from "../img/sign-up-1-v2-baselinecheckcircleblack48dp-copy-green@2x.png";

class DiscountCodeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      discountCodeSelected: true,
      discountCodeValidated: false,
      dcError: false,
      creditError: false,
      creditAmountValidated: false,
      credit: false
    };
   this.toggleDiscountOrCredit = this.toggleDiscountOrCredit.bind(this)
   this.validateDiscountCode = this.validateDiscountCode.bind(this);
   this.validateCreditAmount = this.validateCreditAmount.bind(this);
   this.handleChange = this.handleChange.bind(this);
  }

  toggleDiscountOrCredit(e) {
    if (this.state.discountCodeSelected) {
       this.setState({
         discountCodeSelected: false
       });
    } else {
       this.setState({
         discountCodeSelected: true
       });
    }
  }

  handleChange(e) {
    if (e.target.validity.valid) {
      const name = e.target.name;
      const val = e.target.value;
      if (name === "discountCode" && val.length >= 4) {
        this.validateDiscountCode(val)
      } else if (name === "discountCode" && val.length < 4) {
        this.setState({
          [name]: val,
          dcError: false,
          discountCodeValidated: false
        })
      } else if (name === "credit") {
        this.validateCreditAmount(val)
      } else {
        this.setState({
          [name]: val
        })
      }
    } else {
      e.target.value = ""
    }
  }

  validateDiscountCode(code) {
    var token = Auth.getToken()
    axios
    .post('/api/v2/validate_discount_code',{code: code}, {headers: {token: token}})
         .then(res => {
           this.setState({
             discountCodeValidated: true,
             dcError: false,
             discountCode: code
           });
         })
         .catch(err =>
                this.setState({
                  discountCodeValidated: false,
                  dcError: true,
                  discountCode: code
                })
               )
  }

  validateCreditAmount(amount) {
    let creditAmount = parseFloat(amount)
    if (Number.isNaN(creditAmount)) {
      this.setState({
        creditAmountValidated: false,
        creditError: false,
        credit: 0.0
      })
    } else if (this.props.creditAvailable && creditAmount <= this.props.creditAvailable) {
      this.setState({
        creditAmountValidated: true,
        creditError: false,
        credit: amount
      })
    } else {
      this.setState({
        creditAmountValidated: false,
        creditError: true,
        credit: amount
      })
    }
  }

  render() {
    let dcValue, creditValue, validatedDiscountCodeCheck, validatedCreditCheck, dcErrorField, dcValidImage, dcValidColor, creditValidImage, creditValidColor, creditErrorField
    if (this.state.discountCodeValidated) {
      validatedDiscountCodeCheck = <a href="#" onClick={(e) => {this.props.updateLabOrder(e, "discount_code", this.state.discountCode)}} className="button-36 w-button">APPLY</a>

      dcValidImage = "url('" + validatedCheck + "')"
      dcValidColor = 'green'
    } else {
      validatedDiscountCodeCheck = <a href="#" style={{opacity: '0.5', cursor: 'default', pointerEvents: 'none'}} className="button-36 w-button">APPLY</a>
      dcValidImage = ""
      dcValidColor = 'red'
    }

    if (this.state.dcError) {
      dcErrorField = <div>
        <p className="w-form-fail"
          name="dcError"
          style={{marginTop: '5px',
            display: 'block',
            textAlign: 'center',
            color: 'red',
             }}>

          This is not a valid discount code.
        </p>
      </div>

    } else {
      dcErrorField = <div></div>
    }

    if (this.state.creditAmountValidated) {
      validatedCreditCheck = <a href="#" onClick={(e) => {this.props.updateLabOrder(e, "credit", this.state.credit)}} className="button-36 w-button">APPLY</a>
      creditValidImage = "url('" + validatedCheck + "')"
      creditValidColor = 'green'
    } else {
      validatedCreditCheck = <a href="#" style={{opacity: '0.5', cursor: 'default', pointerEvents: 'none'}}  className="button-36 w-button">APPLY</a>
      creditValidImage = ""
      creditValidColor = 'red'
    }

    if (this.state.creditError) {
      creditErrorField = <div>
        <p className="w-form-fail"
          name="dcError"
          style={{marginTop: '5px',
            display: 'block',
            color: 'red',
             }}>

          You do not have this much credit available.
        </p>
      </div>
    } else {
      creditErrorField = <div></div>
    }

    if (this.state.discountCode) {
      dcValue = this.state.discountCode
    } else {
      dcValue = ""
    }

    if (this.state.credit && this.state.credit.length > 0) {
      creditValue = this.state.credit
    } else {
      creditValue = ""
    }

    return (
      <div>
        <div className="div-block-40">
          <div className="form-block-5 w-clearfix w-form">
            <form className="form-9 w-clearfix">

            {validatedDiscountCodeCheck}
            <input type="text"
            className="text-field-25 w-input"
            name="discountCode"
            style={{
              background: dcValidImage + ' 98% 50% /20px no-repeat',
              color: dcValidColor }}
            value={dcValue}
            onChange={this.handleChange}
            placeholder="ENTER DISCOUNT CODE"/>

            {dcErrorField}


            </form>
          </div>
        </div>

        <div className="div-block-40">
          <div className="form-block-5 w-clearfix w-form">
            <form className="form-9 w-clearfix">


            {validatedCreditCheck}

            <input type="text"
            value={creditValue}
            onChange={this.handleChange}

            style={{
              background: creditValidImage + ' 98% 50% /20px no-repeat',
              color: creditValidColor }}
            name="credit"
            className="text-field-26 w-input"
            placeholder="ENTER CREDIT AMOUNT"
            id="field-2"/>
            {creditErrorField}

            </form>
          </div>
        </div>


      </div>
    )
  }
}

export default DiscountCodeForm;
