import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import escapeRegExp from 'escape-string-regexp'

import NavBar from "./components/NavBar.js";
import axios from "axios";
import Auth from "./modules/Auth.js";
import MuseaApi from "./modules/MuseaApi.js";

import LabOrderCard from "./components/LabOrderCard.js";
import AdminLabOrderCard from "./components/AdminLabOrderCard.js";


class ApiInvoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errorState: false,
      query: "",
      filters: ["unapproved"],
      photographers: "",
      approvedColor: 'rgba(74, 144, 226, 1.0)',
      apiVendorColor: 'rgba(144, 19, 254, 1.0)',
      resetColor: 'rgba(112,112,112, 0.8)'
    };
    this.handleChange = this.handleChange.bind(this);
    this.setStyle = this.setStyle.bind(this);
    this.addToFilter = this.addToFilter.bind(this);
    this.formatOrderDate = this.formatOrderDate.bind(this);
    this.filterQueryMatchingOrders = this.filterQueryMatchingOrders.bind(this)
  }

  componentDidMount() {
    let museaApi = new MuseaApi
    museaApi.checkAndResetCache()
    this.getPhotographers()
  }

  handleChange(e) {
    const val = e.target.value;
    const query = val.trim()

    this.setState({
      query: query
    });
    //console.log(this.state);
  }

  addToFilter(attribute) {
    //console.log("adding filter")
    let filters = this.state.filters
    let newFilters

    if (filters.includes(attribute)) {
      newFilters = filters.filter(item => item !== attribute)
    } else {
      filters.push(attribute)
      newFilters = filters
    }
    if (attribute === "reset") {
      newFilters = []
    }

    //console.log("newFilters " + newFilters.toString())
    this.setState({
      filters: newFilters
    })
  }

  setStyle(name, color, filters) {
    //this.setStyle("hide_my_orders", resetColor, filters)}
    //style={{borderColor: resetColor, color: resetColor}}
    let style = {}
    if (filters.includes(name)) {
      style = {borderColor: color,
        backgroundColor: color,
        color: 'white' }
    } else if (name === "hide_my_orders" || name === "hide_open") {
      if (filters.includes(name)) {
        style = {backgroundColor: '' + color + ' !important',
          color: 'white !important' }
      } else {
        style = {borderColor: color,
          backgroundColor: 'white',
          color: color }
      }
    } else {
      style = {borderColor: color,
        color: color }
    }



    //console.log("style for " + name + "\n" + style.backgroundColor)
    return style
  }

  filterQueryMatchingOrders(labOrders, filters, photographerId) {
    let matchingOrders
    let appliedFilters = []
    filters.forEach(filter => {
      if (["mat", "mount", "frame", "canvas"].includes(filter)) {
        appliedFilters.push("labOrder.attributes.has_" + filter + " === true")
      } else if (filter === "hide_open") {
        appliedFilters.push("labOrder.attributes.status !== 'open'")
      } else if (filter === "hide_my_orders") {
        //if (this.state.viewAsPhotographerId) {
          var filterStringPhotog = "labOrder.attributes.photographer_id !=" + photographerId
          //console.log("filter string " + filterStringPhotog)
          appliedFilters.push(filterStringPhotog)
        //}
      } else if (filter === "api_order") {
        appliedFilters.push("labOrder.attributes.api_order === true")
      } else if (filter === "boutique"){
        appliedFilters.push("labOrder.attributes.boutique_shipping === true")
      }
    })

    let filterString = appliedFilters.join(" && ")
    if (filterString.length > 0){
    matchingOrders = labOrders.filter(labOrder =>
                                      eval(filterString)
    )
    } else {
      matchingOrders = labOrders
    }

    return matchingOrders
  }

  throwError(err) {
    let errorMessage, errorObject, stackTrace
    if (err.response && err.response.status === 401) {
      this.setState({
        userLoggedOut: true
      })
      return false
    } else {
      if (err.response) {
        errorObject = err.response.data.error
        stackTrace = err.response.data.error.more_info.join(" ,").split(",").join("\n")
      } else {
        errorObject = {status: 500, class_name: "" + err.name , developer_message: err.message}
        stackTrace = err.stack
      }
      errorMessage = "\nERROR CODE: " + errorObject.status + "\n ERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.developer_message
      console.log("ERROR MESSAGE: " + errorMessage + "\n MORE INFO: " + stackTrace)

      this.setState({
        loading: false,
        query: "",
        errorState: true,
        errorMessage: errorMessage
      })
    }
  }

  getPhotographers = (e, data) => {
    //e.preventDefault();
    var token = Auth.getToken()
    axios
      .get("/api/v2/photographers", {headers: {'token': token}})
      .then(res => {
        //console.log(res.data);
        let currentUserAdmin, currentUserApiVendor, viewAsPhotographerId, viewingAsPhotographer
        if (res.data.meta) {
          currentUserAdmin = res.data.meta.current_user_is_admin
          currentUserApiVendor = res.data.meta.current_user_is_api_vendor
          viewAsPhotographerId = res.data.meta.view_as_photographer_id
          viewingAsPhotographer = res.data.meta.view_as_photographer
        } else {
          currentUserAdmin = false
          currentUserApiVendor = false
          viewAsPhotographerId = null
          viewingAsPhotographer = null
        }
        this.setState({
          redirectToReferrer: true,
          auth: Auth.isAuthenticated(),
          from: { pathname: "/" },
          labOrders: res.data,
          userIsAdmin: currentUserAdmin,
          userIsApiVendor: currentUserApiVendor,
          viewAsPhotographerId: viewAsPhotographerId,
          viewingAsPhotographer: viewingAsPhotographer,
          query: "",
          loading: false,
          errorState: false,
          parsedDates: []
        });
        return true;
      })
      .catch(err =>
             this.throwError(err)
            )
  };


  formatOrderDate(labOrder) {
    let options = {month: 'long', day: 'numeric', year: 'numeric' };
    let rawParsedDate
    if (labOrder.paid_at) {
      rawParsedDate = new Date(Date.parse(labOrder.attributes.paid_at)).toLocaleDateString("en-US", options) + " ORDERS"
    } else {
      rawParsedDate = new Date(Date.parse(labOrder.attributes.updated_at)).toLocaleDateString("en-US", options) + " ORDERS"
    }
    let parsedDate = rawParsedDate.toUpperCase()
    return parsedDate

  }

  appendIncludedAttributes(photographer) {

    let includedAttributes = []
    //let printsIndicator = <div key="prints" className="orderBoxIndicator" style={{backgroundColor: this.state.printsColor}}> </div>
    //includedAttributes.push(printsIndicator)

    if (photographer.attributes.user_approved) {
      let approvedIndicator = <div key="mat" className="orderBoxIndicator" style={{backgroundColor: this.state.approvedColor}}> </div>
      includedAttributes.push(approvedIndicator)
    }

    if (photographer.attributes.api_vendor) {
      let apiVendorIndicator= <div key="frame" className="orderBoxIndicator" style={{backgroundColor: this.state.apiVendorColor}}> </div>
      includedAttributes.push(apiVendorIndicator)
    }

    if (photographer.attributes.api_user) {
      let apiUserIndicator = <div key="mount" className="orderBoxIndicator" style={{backgroundColor: this.state.resetColor}}> </div>
      includedAttributes.push(apiUserIndicator)
    }
    return includedAttributes
  }

  filteredByQuery(labOrders) {
    const match = new RegExp(escapeRegExp(this.state.query), 'i')
    return labOrders.filter((labOrder) => (match.test(labOrder.attributes.order_name) ||
                                    match.test(labOrder.attributes.status) ||
                                    match.test(labOrder.attributes.api_vendor) ||
                                    match.test(labOrder.attributes.external_order_id) ||
                                    match.test(labOrder.attributes.guid) ||
                                    match.test(labOrder.attributes.photographer_name) ||
                                    match.test(labOrder.attributes.photographer_business)
                                          ) )
  }

  comparePaidAt(a, b) {
    return a.paid_at - b.paid_at;
  }


  render() {
    let {query, filters, labOrders, mountColor, matColor, frameColor, canvasColor, userIsAdmin, loading, errorState, errorMessage, apiOrderColor, testPrintColor, boutiqueColor, userLoggedOut} = this.state

    if (userLoggedOut) {
      return <Redirect to={{ pathname: '/login' , state: { notifyUserOfLogout: true } }} push />
    }

    let showingLabOrders
    let parsedDates = []

    if ((query) || (filters && filters.length > 0)) {
      let queryMatchingOrders = this.filteredByQuery(labOrders['data'])
      showingLabOrders = this.filterQueryMatchingOrders(queryMatchingOrders, filters, this.state.viewAsPhotographerId).sort(this.comparePaidAt)
    } else {
      showingLabOrders = labOrders['data'].sort(this.comparePaidAt)
    }

    let adminFilterActions
    if (labOrders && labOrders['data'].length > 0 && userIsAdmin) {
      adminFilterActions = <div> 
            <div className="container-8 w-container">
              <div className="button-19 w-button"
              style={this.setStyle("mat", matColor, filters)}
              name="mat"
              onClick={(e) => { this.addToFilter('mat')}}> 
               MATTING </div>

              <div className="button-20 w-button"
              style={this.setStyle("mount", mountColor, filters)}
              name="mount"
              onClick={(e) => { this.addToFilter('mount')}}> 
              MOUNTING
              </div>

              <div className="button-21 w-button"
              style={this.setStyle("frame", frameColor, filters)}
              name="frame"
              onClick={(e) => { this.addToFilter('frame')}}> 
               FRAMING </div>

              <div className="button-22 w-button"
              style={this.setStyle("canvas", canvasColor, filters)}
              name="canvas"
              onClick={(e) => { this.addToFilter('canvas')}}> 
               CANVAS </div>

              <div className="button-23 w-button"
              style={this.setStyle("api_order", apiOrderColor, filters)}
              name="api_order"
              onClick={(e) => { this.addToFilter('api_order')}}> 
               API ORDER </div>

              <div className="button-24 w-button"
              style={this.setStyle("test_print", testPrintColor, filters)}
              name="test_print"
              onClick={(e) => { this.addToFilter('test_print')}}> 
               TEST PRINTS </div>

              <div className="button-25 w-button"
              style={this.setStyle("boutique", boutiqueColor, filters)}
              name="boutique"
              onClick={(e) => { this.addToFilter('boutique')}}> 
               BOUTIQUE </div>

              <div className="button-27 w-button"
              style={{borderColor: this.state.resetColor, color: this.state.resetColor}}
              name="reset"
              onClick={(e) => { this.addToFilter('reset')}}>
               RESET
               </div>
             </div>

            <div className="container-8 w-container" style={{marginLeft: 'auto', marginRight: 'auto'}}>
              <div className="button-37 w-button" style={this.setStyle("hide_open", this.state.resetColor, filters)} name="hide_open" onClick={(e) => { this.addToFilter('hide_open')}}> HIDE OPEN </div>
              <div className="button-47 w-button" style={this.setStyle("hide_my_orders", this.state.resetColor, filters)} name="hide_my_orders" onClick={(e) => { this.addToFilter('hide_my_orders')}}> HIDE MY ORDERS </div>
            </div>

          </div>
    } else {
      adminFilterActions = <div></div>

    }

    let labOrderCards
    if (labOrders && labOrders['data'].length > 0 && userIsAdmin ) {
      labOrderCards = showingLabOrders.map(labOrder => {
          let parsedDate
          let formattedDate = this.formatOrderDate(labOrder)

          if (!parsedDates.includes(formattedDate)) {
            parsedDates.push(formattedDate)
            parsedDate = formattedDate
          } else {
            parsedDate = ""
          }

          let includedAttributes = this.appendIncludedAttributes(labOrder)
          return (<AdminLabOrderCard key={labOrder.attributes.guid}
                  guid={labOrder.attributes.guid}
                  orderId={labOrder.id}
                  orderName={labOrder.attributes.order_name}
                  orderPhotographerName={labOrder.attributes.photographer_name}
                  orderPhotographerBusinessName={labOrder.attributes.photographer_business}
                  orderStatus={labOrder.attributes.status}
                  orderCreatedAt={labOrder.attributes.created_at}
                  orderUpdatedAt={labOrder.attributes.updated_at}
                  orderPaidAt={labOrder.attributes.paid_at}
                  includedAttributes={includedAttributes}
                  parsedDate={parsedDate}
                  orderShippedAt={labOrder.attributes.shipped_at}
                  orderTrackingUrl={labOrder.attributes.tracking_url}
                  orderTrackingNumber={labOrder.attributes.tracking_number}
                  orderBoutiqueShippingNote={labOrder.attributes.boutique_shipping_note}
                  orderBoutiqueShippingPrice={labOrder.attributes.boutique_shipping_price}
                  orderShippingService={labOrder.attributes.shipping_number}
                  coverPhoto={labOrder.attributes.cover_photo_url}
                  orderExternalId={labOrder.attributes.external_order_id}
                  orderNotes={labOrder.attributes.notes}
                  orderHasNotes={labOrder.attributes.has_notes}
                  orderAdminNotes={labOrder.attributes.internal_notes}
                  orderHasAdminNotes={labOrder.attributes.has_admin_notes}
                  orderIsApi={labOrder.attributes.api_order}
                  orderApiVendor={labOrder.attributes.api_vendor}
                  orderHasFraming={labOrder.attributes.has_frame}
                  orderHasMounting={labOrder.attributes.has_mount}
                  orderHasMatting={labOrder.attributes.has_mat}
                  downloadAllLink={labOrder.attributes.download_all_link}
                  />);
        })

    } else {
        labOrderCards = showingLabOrders.map(labOrder => {
          return (<LabOrderCard key={labOrder.attributes.guid}
                  guid={labOrder.attributes.guid}
                  orderId={labOrder.id}
                  orderName={labOrder.attributes.order_name}
                  orderPhotographerBusinessName={labOrder.attributes.photographer_business}
                  orderStatus={labOrder.attributes.status}
                  orderCreatedAt={labOrder.attributes.created_at}
                  orderUpdatedAt={labOrder.attributes.updated_at}
                  orderPaidAt={labOrder.attributes.paid_at}
                  coverPhoto={labOrder.attributes.cover_photo_url}
                  orderExternalId={labOrder.attributes.external_order_id}
                  orderNotes={labOrder.attributes.notes}
                  />);
        })
    }


    if(loading) {
      return (
        <div>
          <NavBar />

          <div className="LabOrders">

          <div className="container-3 w-container">
            <Link to="/lab-orders/upload">
              <div className="button-2 w-button">
                NEW LAB ORDER
              </div>
            </Link>

            <div className="search w-form">
              <h1 style={{textAlign: 'center'}}>loading</h1>
            </div>
          </div>

       </div>
     </div>
    )} else if(errorState !== false) {
      return(
        <div>
          <NavBar />
          <div className="LabOrders">

          <div className="container-3 w-container">
            <Link to="/lab-orders/upload">
              <div className="button-2 w-button">
                NEW LAB ORDER
              </div>
            </Link>

            <div className="search w-form">
             <div className="w-form-fail"
               style={{textAlign: "center",
                 display: 'inline-block',
                 overflowWrap: "break-word"}}>
               ERROR LOADING: {errorMessage}
             </div>
           </div>
          </div>

       </div>
     </div>
      )} else {
      return (
        <div>
          <NavBar />
          <div className="LabOrders">

            <div className="container-3">
              <Link to="/lab-orders/upload">
                <div className="button-2 w-button">
                  NEW LAB ORDER
                </div>
              </Link>

              <div className="search w-form">
                <input
                type="text"
                className="search-input w-input"
                onChange={this.handleChange}
                placeholder="Search by Order Name or Status"
                />
              </div>

                {adminFilterActions}

              <div className="w-layout-grid grid">
                {labOrderCards}
              </div>
            </div>

        </div>
      </div>
      );
    }
  }
}

export default ApiInvoices;
