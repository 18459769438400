import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Auth from "./modules/Auth";
import axios from "axios";
import validatedCheck from "./img/sign-up-1-v2-baselinecheckcircleblack48dp-copy-green@2x.png";

import logo1080 from "./img/MUSEAbadge-black-p-1080.jpeg";
import logo500 from "./img/MUSEAbadge-black-p-500.jpeg"
import logo800 from "./img/MUSEAbadge-black-p-800.jpeg"
import logoBlack from "./img/MUSEAbadge-black.jpg"

class SignUpTwo extends Component {
  constructor() {
    super();
    this.state = {
      errorState: false,
      referredSelected: true,
      referralCodeSubmitted: false,
      referralCodeValidated: false,
      referralCodeError: false
    };
   this.validateReferralCode = this.validateReferralCode.bind(this);
   this.toggleReferral = this.toggleReferral.bind(this);
   this.throwError = this.throwError.bind(this)
   this.handleChange = this.handleChange.bind(this)
   this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {

  }

  handleChange(e) {
    const name = e.target.name;
    const val = e.target.value;
    if (name === "referralCode" && val.length >= 3) {
      this.validateReferralCode(val)
    } else {
      this.setState({
        [name]: val
      });
    }
    //console.log(this.state);
  }

  toggleReferral(e) {
    if (e.target.value === "NO") {
       this.setState({
         referredSelected: false
       });
    } else {
       this.setState({
         referredSelected: true
       });
    }
  }

  validateReferralCode(code) {
    axios
    .post('/api/v2/check_referral_code',{code: code}, {headers: {}})
         .then(res => {
           //console.log(result);
           this.setState({
             referralCodeValidated: true,
             referralCode: code
           });
         })
         .catch(err =>
                //console.log(result);
                this.setState({
                  referralCodeValidated: false,
                  referralCodeError: true
                })
               )
  }

  handleSubmit(e) {
    e.preventDefault()
    var token = Auth.getToken()
    axios
    .patch('/api/v2/update_new_user',
          {code: this.state.referralCode}, {headers: {'token': token}})
          .then(res => {

            this.setState({
              referralCodeSubmitted: true
            })
          })
          .catch(err =>
                 this.throwError(err))
  }

  throwError(err) {
    let errorObject = err.response.data.error
    let errorMessage = "\nERROR CODE: " + errorObject.status + "\n ERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.developer_message
    console.log("ERROR MESSAGE: " + errorMessage + "\n MORE INFO: " + err.response.data.error.more_info.join(" ,").split(",").join("\n"))
    //alert(errorMessage)

    this.setState({
      loading: false,
      errorState: true,
      errorMessage: errorMessage
    })
  }

  render() {
    let logoSrcSet = `${logo500} 500w, ${logo800} 800w, ${logo1080} 1080w, ${logoBlack} 1200w`
    let updateUserButton, referralCodeField, referralCodeErrorField, codeValidImage, referredSelectedOptionValue

    if (this.state.referralCodeSubmitted) {
      return <Redirect push to={{ pathname: "/signup/3-of-3", state: {} }} />; 
    }

    if (this.state.referredSelected && this.state.referralCodeValidated) {
      referralCodeErrorField = <div></div>
      codeValidImage = "url('" + validatedCheck + "')"

    } else if (this.state.referredSelected && this.state.referralCodeError ){
      referralCodeErrorField = <p className="w-form-fail"
                style={{marginTop: '5px',
                  color: 'red' }}>

                This referral code does not exist.
              </p>
      codeValidImage = ""
    } else {
      referralCodeErrorField = <div></div>
      codeValidImage = ""
    }

    if (this.state.referredSelected && this.state.referralCodeValidated) {
      updateUserButton = <input type="submit"
                  onClick={(e) => {this.handleSubmit(e)}}
                  className="button-15 w-button"
                  value="NEXT"/>
    } else if (!this.state.referredSelected) {
      updateUserButton = <input type="submit"
                  onClick={(e) => {this.handleSubmit(e)}}
                  className="button-15 w-button"
                  value="NEXT"/>
      referredSelectedOptionValue = "NO"
    } else {
      updateUserButton = <input type="submit"
                  className="button-15 w-button"
                  disabled
                  style={{opacity: '0.5'}}
                  value="NEXT"/>
      referredSelectedOptionValue = "YES"
    }



    if (this.state.referredSelected) {

      referralCodeField = <input type="text"
                className="text-field-19 w-input"
                name="referralCode"
                onChange={this.handleChange}
                style={{width: '450px', background: codeValidImage + ' 98% 50% /20px no-repeat' }}
                placeholder="ENTER REFERRAL CODE"/>
    } else {
      referralCodeField = <div></div>
    }

    return (
      <div>
        <div className="SignUp">
          <img alt="musea logo"
            srcSet={logoSrcSet}
            sizes="144px"
            className="image"
            src={logoBlack}
            style={{width: "144"}}
            />

          <div className="text-block-19">
            <strong>SIGN UP<br/></strong>
              <br/>
            1 • <strong> 2 </strong>• 3
          </div>


          <div className="text-block-20">
            <strong className="bold-text-3">
              Were you referred by another photographer?
            </strong>
          </div>

          <div className="w-form" style={{textAlign: 'center'}}>
            <div id="email-form-3">
              <select value={referredSelectedOptionValue}
              className="select-field-3 w-select"
              onChange={(e) => {this.toggleReferral(e)}}>
                <option value="">Select one...</option>
                <option value="YES">YES</option>
                <option value="NO">NO</option>
              </select>
            </div>
          </div>

          <div className="text-block-21">
            If yes, enter their referral code below.
            <br/>
            <br/>
            If no, click &#x27;NEXT&#x27;
          </div>


          <div className="container-5 w-container">
            <div className="w-form" style={{textAlign: 'center'}}>
              <div id="email-form-2"
              className="form-7" >

                {referralCodeField}

                {referralCodeErrorField}



              </div>
            </div>
          </div>

          <div className="container-4 w-container">
            {updateUserButton}
          </div>



        </div>
      </div>
    );
  }
}

export default SignUpTwo;
