import React, { Component } from "react";
import Auth from "./modules/Auth.js"
import axios from "axios"
import MuseaApi from "./modules/MuseaApi.js"
import LineItemSidebar from "./components/LineItemSidebar.js";

import ReactCrop, { makeAspectCrop } from 'react-image-crop';

import Honeybadger from 'honeybadger-js'
import 'react-toastify/dist/ReactToastify.css';

import NavBar from "./components/NavBar.js";
import { GridList, GridListTile } from '@material-ui/core';


import border from "./img/frames/premium_frames/Aged Cherry_.75in.jpg"

class CartPreview extends Component {
    constructor(props) {
        super(props);
        const hb_config = {
            api_key: process.env.REACT_APP_HB_KEY,
            environment: process.env.REACT_APP_CURRENT_ENV,
            revision: 'current'
        }
        this.honeybadger = Honeybadger.configure(hb_config)
        this.state = {
            loading: true,
            labOrder: {},
            honeybadger: this.honeybadger,
            guid: this.props.location.pathname["split"]("/")[2],
            currentStyle: null,
            localPickup: false,
            clearingCart: false,
            originalImages: [],
            errorState: false

        };
        this.drawCanvas = this.drawCanvas.bind(this);
        this.getLabOrderPhotograph = this.getLabOrderPhotograph.bind(this)
        this.getLabOrderPhotographs = this.getLabOrderPhotographs.bind(this)

    }


    componentDidMount() {
        let museaApi = new MuseaApi()
        museaApi.checkAndResetCache()
        this.getLabOrder()
        this.getLabOrderPhotographs()
    }


    getLabOrderPhotograph(photoGuid) {
        //e.preventDefault();
        var labOrderPhoto;
        var token = Auth.getToken()
        var guid = this.props.location.pathname["split"]("/")[2];
        axios
            .get(`api/v2/lab_orders/${guid}/photographs/${photoGuid}`, { headers: { 'token': token } })
            // /api/v2/lab_orders/:id/lab_order_photographs
            .then(res => {
                console.log("getLabOrderPhotograph: " + res.data.data);
                labOrderPhoto = res.data.data
                return res.data.data
            })
            .catch(err => {
                this.throwError(err)
            })
    };
    getLabOrderPhotographs() {
        //e.preventDefault();
        var token = Auth.getToken()
        var guid = this.props.location.pathname["split"]("/")[2];
        //console.log("getting all images function")
        axios
            .get(`api/v2/lab_orders/${guid}/lab_order_photographs`, { headers: { 'token': token } })
            // /api/v2/lab_orders/:id/lab_order_photographs
            .then(res => {
                let museaApi = new MuseaApi()
                let labOrder = this.state.labOrder
                labOrder['images'] = museaApi.mergeImages(labOrder, res.data)
                this.setState({
                    originalImages: labOrder['images']
                });
                return true;
            })
            .catch(err => {
                this.throwError(err)
                //let error = JSON.parse(err.response)
                //this.throwError(error)
            })
    };
    getLabOrder = (e, data) => {
        //e.preventDefault();
        var token = Auth.getToken()
        var guid = this.props.location.pathname["split"]("/")[2];
        axios
            .get(`api/v2/lab_orders/${guid}`, { headers: { 'token': token } })
            .then(res => {
                let museaApi = new MuseaApi()
                var parsedOrder = museaApi.mergeAttributesForLabOrder(res.data);
                console.log(parsedOrder)
                this.setState({
                    redirectToReferrer: false,
                    auth: Auth.isAuthenticated(),
                    from: { pathname: "/" },
                    labOrder: parsedOrder,
                    formattedLineItems: parsedOrder.lineItems,
                    loading: false,
                    //loading: true,
                    errorState: false
                });
                return true;
            })
            .catch(err => {
                this.throwError(err)
                //let error = JSON.parse(err.response)
                //this.throwError(error)
            })
    };

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            //console.log("resetting props")
            this.setState({
                labOrder: nextProps.labOrder,
                formattedLineItems: nextProps.lineItems,
            })
        }
    }

  throwError(err) {
    let errorMessage, errorObject
    if (err.response.data["error"] && err.response.data["error"].status === 401) {
      this.setState({
        userLoggedOut: true
      })
      return false
    } else {
      if (err.response.data["error"] != null) {
        errorObject = err.response.data["error"]
        errorMessage = "\nERROR CODE: " + errorObject.status + "\nERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.user_message["message"]
        if (errorObject["more_info"] != null) {
          this.state.honeybadger.notify(err)
          alert("ERROR: " + errorMessage)
          console.log("ERROR: " + errorMessage + "\nMORE INFO: " + errorObject["more_info"].join(" ,").split(",").join("\n"))
        } else {
          this.state.honeybadger.notify(err)
          alert("ERROR: " + errorMessage)
          console.log(errorMessage + " More info not available.")
        }
      } else { 
        errorMessage = "\nERROR CODE: " + err.response.status + "\n ERROR MESSAGE: " + err.response.message
      }
    }
      this.setState({
          loading: false,
          errorMessage: errorMessage
      })
  }


    drawCanvas = (lineItem) => {
        {
            console.log("LineItem: ", lineItem)
            const canvas = this.refs.canvas
            const ctx = canvas.getContext("2d")

            const printSize = lineItem.printSize.split('x');

            let frameWidth = parseInt(printSize[0]) + 4;
            let frameHeight = parseInt(printSize[1]) + 4;


            var img = new Image();
            img.onload = function () {
                let borderSize = (this.naturalWidth / frameWidth) * 2
                console.log('borderSize', borderSize)


                canvas.width = this.naturalWidth;
                canvas.height = this.naturalHeight;

                console.log(canvas.width, canvas.height)

                switch (lineItem.paper) {
                    default:
                        ctx.drawImage(img, 0, 0, img.width, img.height);
                        break;
                    case "Canvas - Gallery Wrap":
                        ctx.drawImage(img, 0, 0, img.width, img.height);
                        ctx.beginPath();
                        ctx.moveTo(borderSize, 0);
                        ctx.lineTo(borderSize, canvas.height);
                        ctx.moveTo(canvas.width - borderSize, 0);
                        ctx.lineTo(canvas.width - borderSize, canvas.height);
                        ctx.moveTo(0, borderSize);
                        ctx.lineTo(canvas.width, borderSize);
                        ctx.moveTo(0, canvas.height - borderSize);
                        ctx.lineTo(canvas.width, canvas.height - borderSize);
                        ctx.closePath();
                        ctx.strokeStyle = "#46ecea"
                        ctx.stroke();
                        ctx.lineWidth = 1;
                        ctx.strokeStyle = "#000"
                        ctx.strokeRect(0, 0, canvas.width, canvas.height);

                        // ctx.drawImage(img, 0, 0, img.width, img.height);
                        // ctx.beginPath();
                        // ctx.moveTo(borderSize / 2, 0);
                        // ctx.lineTo(borderSize / 2, canvas.height);
                        // ctx.moveTo(canvas.width - borderSize / 2, 0);
                        // ctx.lineTo(canvas.width - borderSize / 2, canvas.height);
                        // ctx.moveTo(0, borderSize / 2);
                        // ctx.lineTo(canvas.width, borderSize / 2);
                        // ctx.moveTo(0, canvas.height - borderSize / 2);
                        // ctx.lineTo(canvas.width, canvas.height - borderSize / 2);
                        // ctx.closePath();
                        // ctx.strokeStyle = "#46ecea"
                        // ctx.stroke();
                        // ctx.lineWidth = 1;
                        // ctx.strokeStyle = "#000"
                        // ctx.strokeRect(0, 0, canvas.width, canvas.height);
                        break;
                    case "Canvas - White Wrap":
                        ctx.drawImage(img, borderSize / 2, borderSize / 2, img.width, img.height);
                        ctx.lineWidth = borderSize;
                        ctx.strokeStyle = "#fff"
                        ctx.strokeRect(0, 0, canvas.width, canvas.height);
                        ctx.lineWidth = 1;
                        ctx.strokeStyle = "#000"
                        ctx.strokeRect(0, 0, canvas.width, canvas.height);
                        break;
                    case "Canvas - Black Wrap":
                        ctx.drawImage(img, borderSize / 2, borderSize / 2, img.width, img.height);
                        // ctx.drawImage(img, borderSize, borderSize, img.width - borderSize * 2, img.height - borderSize * 2);
                        ctx.lineWidth = borderSize;
                        ctx.strokeStyle = "#000"
                        ctx.strokeRect(0, 0, canvas.width, canvas.height);
                        break;
                    case "Canvas - Mirror Wrap":
                        // TOP PART
                        ctx.drawImage(img, 0, 0, borderSize / 2, borderSize / 2, 0, 0, borderSize / 2, borderSize / 2)
                        ctx.drawImage(img, img.width - borderSize / 2, 0, borderSize / 2, borderSize / 2, canvas.width - borderSize / 2, 0, borderSize / 2, borderSize / 2)
                        ctx.drawImage(img, borderSize / 2, 0, img.width - borderSize / 2, borderSize / 2, borderSize / 2, 0, img.width, borderSize / 2)

                        // // BOTTOM PART

                        ctx.drawImage(img, 0, img.height - borderSize / 2, borderSize / 2, borderSize / 2, 0, canvas.height - borderSize / 2, borderSize / 2, borderSize / 2)
                        ctx.drawImage(img, img.width - borderSize / 2, img.height - borderSize / 2, borderSize / 2, borderSize / 2, canvas.width - borderSize / 2, canvas.height - borderSize / 2, borderSize / 2, borderSize / 2)

                        ctx.drawImage(img, borderSize / 2, img.height - borderSize / 2, img.width - borderSize / 2, borderSize / 2, borderSize / 2, canvas.height - borderSize / 2, img.width, borderSize / 2)

                        // // LEFT SIDE
                        ctx.drawImage(img, 0, img.height - borderSize * 4, borderSize / 2, canvas.height - borderSize / 2, 0, borderSize / 2, borderSize / 2, canvas.height - borderSize / 2);

                        // // RIGHT SIDE

                        // ctx.rotate(90 * Math.PI / 18)
                        ctx.drawImage(img, img.width - borderSize / 2, img.height - borderSize * 4, borderSize / 2, canvas.height - borderSize / 2, canvas.width - borderSize / 2, borderSize / 2, borderSize / 2, canvas.height - borderSize / 2);
                        // ctx.rotate(-90 * Math.PI / 18)

                        // GUIDES 
                        ctx.drawImage(img, borderSize / 2, borderSize / 2, img.width, img.height);
                        ctx.beginPath();
                        ctx.moveTo(borderSize / 2, 0);
                        ctx.lineTo(borderSize / 2, canvas.height);
                        ctx.moveTo(canvas.width - borderSize / 2, 0);
                        ctx.lineTo(canvas.width - borderSize / 2, canvas.height);
                        ctx.moveTo(0, borderSize / 2);
                        ctx.lineTo(canvas.width, borderSize / 2);
                        ctx.moveTo(0, canvas.height - borderSize / 2);
                        ctx.lineTo(canvas.width, canvas.height - borderSize / 2);
                        ctx.closePath();
                        ctx.strokeStyle = "#46ecea"
                        ctx.stroke();
                        ctx.lineWidth = 1;
                        ctx.strokeStyle = "#000"
                        ctx.strokeRect(0, 0, canvas.width, canvas.height);
                        break;
                }
            }
            // if (lineItem.paper === "Canvas - Gallery Wrap")
            //     img.src = this.state.originalImages.filter((item) => item.guid === lineItem.photoGuid)[0].smallest_photo
            // else
            img.src = lineItem.photograph_cropped_url;
        }
    }
    render() {
        const { loading, formattedLineItems, currentStyle } = this.state

        if (loading) {
            return (
                <div>
                    <NavBar />
                    <div className="w-row">
                        <div className="column-2000 w-col w-col-9">
                            <div id="w-node-dd7d45327c78-d0052ddd">
                                <div style={{ textAlign: 'center', fontWeight: '800', marginLeft: '10%', marginTop: '10%' }} className="text-block-103">loading...</div>
                                <div className="div-block-39"></div>

                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            console.log(border);
            return <div>
                <NavBar />
                <br />
                <div style={{ textAlign: "center" }}>
                    <div style={currentStyle}>
                        <canvas ref="canvas" style={{ maxWidth: "80%", maxHeight: "800px", borderImage: "url('/static/media/Aged Cherry_.75in.6ee9ae3f.jpg')" }} />
                    </div>
                </div>

                <br />
                <ul style={{
                    listStyleType: "none", overflow: "auto",
                    whiteSpace: "nowrap"
                }}>
                    {formattedLineItems.map((lineItem) => (
                        <li style={{ display: "inline-block", padding: "10px" }} key={lineItem.guid}
                            onClick={() => this.drawCanvas(lineItem)}>
                            <img src={lineItem.photoUrl} alt={lineItem.imageName} height="150px" />
                        </li>
                    ))
                    }
                </ul >
            </div >
        }


    }
}

export default CartPreview;
