import React, { Component } from "react";

class AddressCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {primary, recipient, address, city, state, zip} = this.props

    if(primary) {

    return (

      <div>
        <div className="AddressCard" >
          <div className="AddressText">
            {recipient}
            <br />
            {address}
            <br />
            {city}, {state} {zip}
          </div>
        </div>
      </div>
    )
    } else {
    return (
      <div></div>
    )
    }
  }
}

export default AddressCard;
