import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import escapeRegExp from 'escape-string-regexp'

import Honeybadger from 'honeybadger-js'
import ErrorBoundary from '@honeybadger-io/react'

import NavBar from "./components/NavBar.js";
import axios from "axios";
import Auth from "./modules/Auth.js";
import MuseaApi from "./modules/MuseaApi.js";

import LabOrderCard from "./components/LabOrderCard.js";
import AdminLabOrderCard from "./components/AdminLabOrderCard.js";


class LabOrders extends Component {
  constructor(props) {
    super(props);
    const hb_config = {
      api_key: process.env.REACT_APP_HB_KEY,
      environment: process.env.REACT_APP_CURRENT_ENV,
      revision: 'current'
    }
    this.honeybadger = Honeybadger.configure(hb_config)

    this.state = {
      loading: true,
      errorState: false,
      query: "",
      filters: [],
      labOrders: { 'data': [] },
      honeybadger: this.honeybadger,
      printsColor: 'rgba(74, 144, 226, 1.0)',
      mountColor: 'rgba(144, 19, 254, 1.0)',
      matColor: 'rgba(245, 166, 35, 1.0)',
      frameColor: 'rgba(75, 208, 178, 1.0)',
      canvasColor: 'rgba(119, 199, 31, 1.0)',
      apiOrderColor: 'rgba(208, 2, 27, 1.0)',
      testPrintColor: '#c511a1',
      boutiqueColor: 'rgba(231, 54, 214, 1.0)',
      resetColor: 'rgba(112,112,112, 0.8)',
      mergeToOrderGuid : null,
      selectedPhotographerId: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.setStyle = this.setStyle.bind(this);
    this.addToFilter = this.addToFilter.bind(this);
    this.destroyLabOrder = this.destroyLabOrder.bind(this);
    this.formatOrderDate = this.formatOrderDate.bind(this);
    this.filterQueryMatchingOrders = this.filterQueryMatchingOrders.bind(this)
  }

  componentDidMount() {
    let museaApi = new MuseaApi
    museaApi.checkAndResetCache()
    this.getLabOrders()
  }


  handleChange(e) {
    const val = e.target.value;
    const query = val.trim()

    this.setState({
      query: query
    });
    //console.log(this.state);
  }



  destroyLabOrder(labOrderGuid) {
    console.log("deleting lab order " + labOrderGuid)
    var token = Auth.getToken()
    axios.delete(`api/v2/lab_orders/${labOrderGuid}`,
      { headers: { 'token': token } }
    )
      .then(async res => {
        this.setState({ loading: true })
        await this.getLabOrders()
      })
      .catch(err => {
        console.log("ERROR: " + err)
        this.throwError(err)
        //let error = JSON.parse(err.response)
        //this.throwError(error)
      })
  }

  addToFilter(attribute) {
    let filters = this.state.filters
    let newFilters

    if (filters.includes(attribute)) {
      newFilters = filters.filter(item => item !== attribute)
    } else {
      filters.push(attribute)
      newFilters = filters
    }
    if (attribute === "reset") {
      newFilters = []

    }

    //console.log("newFilters " + newFilters.toString())
    this.setState({
      filters: newFilters,
      mergeToOrderGuid : null,
      selectedPhotographerId: null
    })
  }

  setStyle(name, color, filters) {
    //this.setStyle("hide_my_orders", resetColor, filters)}
    //style={{borderColor: resetColor, color: resetColor}}
    let style = {}
    if (filters.includes(name)) {
      style = {
        borderColor: color,
        backgroundColor: color,
        color: 'white'
      }
    } else if (name === "hide_my_orders" || name === "hide_open") {
      if (filters.includes(name)) {
        style = {
          backgroundColor: '' + color + ' !important',
          color: 'white !important'
        }
      } else {
        style = {
          borderColor: color,
          backgroundColor: 'white',
          color: color
        }
      }
    } else {
      style = {
        borderColor: color,
        color: color
      }
    }



    //console.log("style for " + name + "\n" + style.backgroundColor)
    return style
  }

  filterQueryMatchingOrders(labOrders, filters, photographerId) {
    let matchingOrders
    let appliedFilters = []
    filters.forEach(filter => {
      if (["mat", "mount", "frame", "canvas"].includes(filter)) {
        appliedFilters.push("labOrder.attributes.has_" + filter + " === true")
      } else if (filter === "hide_open") {
        appliedFilters.push("labOrder.attributes.status !== 'open'")
      } else if (filter === "hide_my_orders") {
        //if (this.state.viewAsPhotographerId) {
        var filterStringPhotog = "labOrder.attributes.photographer_id !=" + photographerId
        //console.log("filter string " + filterStringPhotog)
        appliedFilters.push(filterStringPhotog)
        //}
      } else if (filter === "api_order") {
        appliedFilters.push("labOrder.attributes.api_order === true")
      } else if (filter === "boutique") {
        appliedFilters.push("labOrder.attributes.boutique_shipping === true")
      }
    })

    let filterString = appliedFilters.join(" && ")
    if (filterString.length > 0) {
      matchingOrders = labOrders.filter(labOrder =>
        eval(filterString)
      )
    } else {
      matchingOrders = labOrders
    }

    return matchingOrders
  }

  // {"error":{"status":500,"developer_message":{"message":"undefined local variable or method `status_filter' for #\u003cPhotographers::Api::V2::LabOrdersController:0x00000000031308\u003e"},"error_code":"500","user_message":{"message":"undefined local variable or method `status_filter' for #\u003cPhotographers::Api::V2::LabOrdersController:0x00000000031308\u003e"},"class_name":"NameError","more_info":["/Users/jh/work/musea-work/musea/app/controllers/photographers/api/v2/lab_orders_controller.rb:72:in `index'","/Users/jh/work/musea-work/musea/vendor/bundle/ruby/2.7.0/gems/actionpack-7.0.1/lib/action_controller/metal/basic_implicit_render.rb:6:in `send_action'","/Users/jh/work/musea-work/musea/vendor/bundle/ruby/2.7.0/gems/actionpack-7.0.1/lib/abstract_controller/base.rb:214:in `process_action'","/Users/jh/work/musea-work/musea/vendor/bundle/ruby/2.7.0/gems/actionpack-7.0.1/lib/action_controller/metal/rendering.rb:53:in `process_action'","/Users/jh/work/musea-work/musea/vendor/bundle/ruby/2.7.0/gems/actionpack-7.0.1/lib/abstract_controller/callbacks.rb:234:in `block in process_action'","/Users/jh/work/musea-work/musea/vendor/bundle/ruby/2.7.0/gems/activesupport-7.0.1/lib/active_support/callbacks.rb:118:in `block in run_callbacks'","/Users/jh/work/musea-work/musea/vendor/bundle/ruby/2.7.0/gems/actiontext-7.0.1/lib/action_text/rendering.rb:20:in `with_renderer'","/Users/jh/work/musea-work/musea/vendor/bundle/ruby/2.7.0/gems/actiontext-7.0.1/lib/action_text/engine.rb:69:in `block (4 levels) in \u003cclass:Engine\u003e'","/Users/jh/work/musea-work/musea/vendor/bundle/ruby/2.7.0/gems/activesupport-7.0.1/lib/active_support/callbacks.rb:127:in `instance_exec'","/Users/jh/work/musea-work/musea/vendor/bundle/ruby/2.7.0/gems/activesupport-7.0.1/lib/active_support/callbacks.rb:127:ik"]}}

  throwError(err) {
    let errorMessage, errorObject
    if (err.response.data["error"] && err.response.data["error"].status === 401) {
      this.setState({
        userLoggedOut: true
      })
      return false
    } else {
      if (err.response.data["error"] != null) {
        errorObject = err.response.data["error"]
        errorMessage = "\nERROR CODE: " + errorObject.status + "\nERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.user_message["message"]
        if (errorObject["more_info"] != null) {
          this.state.honeybadger.notify(err)
          alert("ERROR: " + errorMessage)
          console.log("ERROR: " + errorMessage + "\nMORE INFO: " + errorObject["more_info"].join(" ,").split(",").join("\n"))
        } else {
          this.state.honeybadger.notify(err)
          alert("ERROR: " + errorMessage)
          console.log(errorMessage + " More info not available.")
        }
      } else { 
        errorMessage = "\nERROR CODE: " + err.response.status + "\n ERROR MESSAGE: " + err.response.message
      }
    }

    this.setState({
      loading: false,
      query: "",
      errorState: true,
      errorMessage: errorMessage
    })
  }

  getLabOrders = (e, data) => {
    //e.preventDefault();
    var token = Auth.getToken()
    axios
      .get("/api/v2/lab_orders", { headers: { 'token': token } })
      .then(res => {
        console.log(res);
        let currentUserAdmin, currentUserApiVendor, viewAsPhotographerId, viewingAsPhotographer, appliedFilters
        if (res.data.meta) {
          currentUserAdmin = res.data.meta.current_user_is_admin
          currentUserApiVendor = res.data.meta.current_user_is_api_vendor
          viewAsPhotographerId = res.data.meta.view_as_photographer_id
          viewingAsPhotographer = res.data.meta.view_as_photographer
        } else {
          currentUserAdmin = false
          currentUserApiVendor = false
          viewAsPhotographerId = null
          viewingAsPhotographer = null
        }
        if (currentUserAdmin) {
          appliedFilters = ["hide_my_orders", "hide_open"]
        } else {
          appliedFilters = []
        }
        this.setState({
          redirectToReferrer: true,
          auth: Auth.isAuthenticated(),
          from: { pathname: "/" },
          labOrders: res.data,
          filters: appliedFilters,
          userIsAdmin: currentUserAdmin,
          userIsApiVendor: currentUserApiVendor,
          viewAsPhotographerId: viewAsPhotographerId,
          viewingAsPhotographer: viewingAsPhotographer,
          query: "",
          loading: false,
          errorState: false,
          parsedDates: []
        });
        return true;
      })
      .catch(err =>
        this.throwError(err)
      )
  };


  formatOrderDate(labOrder) {
    let options = { month: 'long', day: 'numeric', year: 'numeric' };
    let rawParsedDate
    if (labOrder.paid_at) {
      rawParsedDate = new Date(Date.parse(labOrder.attributes.paid_at)).toLocaleDateString("en-US", options) + " ORDERS"
    } else {
      rawParsedDate = new Date(Date.parse(labOrder.attributes.updated_at)).toLocaleDateString("en-US", options) + " ORDERS"
    }
    let parsedDate = rawParsedDate.toUpperCase()
    return parsedDate

  }

  appendIncludedAttributes(labOrder) {

    let includedAttributes = []
    //let printsIndicator = <div key="prints" className="orderBoxIndicator" style={{backgroundColor: this.state.printsColor}}> </div>
    //includedAttributes.push(printsIndicator)

    if (labOrder.attributes.has_mat) {
      let matIndicator = <div key="mat" className="orderBoxIndicator" style={{ backgroundColor: this.state.matColor }}> </div>
      includedAttributes.push(matIndicator)
    }

    if (labOrder.attributes.has_frame) {
      let frameIndicator = <div key="frame" className="orderBoxIndicator" style={{ backgroundColor: this.state.frameColor }}> </div>
      includedAttributes.push(frameIndicator)
    }

    if (labOrder.attributes.has_mount) {
      let mountIndicator = <div key="mount" className="orderBoxIndicator" style={{ backgroundColor: this.state.mountColor }}> </div>
      includedAttributes.push(mountIndicator)
    }

    if (labOrder.attributes.api_order) {
      let apiIndicator = <div key="api_order" className="orderBoxIndicator" style={{ backgroundColor: this.state.apiOrderColor }}> </div>
      includedAttributes.push(apiIndicator)
    }

    if (labOrder.attributes.test_print) {
      let testPrintIndicator = <div key="test_print" className="orderBoxIndicator" style={{ backgroundColor: this.state.testPrintColor }}> </div>
      includedAttributes.push(testPrintIndicator)
    }

    if (labOrder.attributes.has_canvas) {
      let canvasIndicator = <div key="canvas" className="orderBoxIndicator" style={{ backgroundColor: this.state.canvasColor }}> </div>
      includedAttributes.push(canvasIndicator)
    }

    if (labOrder.attributes.boutique_shipping) {
      let boutiqueIndicator = <div key="boutique" className="orderBoxIndicator" style={{ backgroundColor: this.state.boutiqueColor }}> </div>
      includedAttributes.push(boutiqueIndicator)
    }
    return includedAttributes
  }

  filteredByQuery(labOrders) {
    const match = new RegExp(escapeRegExp(this.state.query), 'i')
    return labOrders.filter((labOrder) => (match.test(labOrder.attributes.order_name) ||
      match.test(labOrder.attributes.status) ||
      match.test(labOrder.attributes.api_vendor) ||
      match.test(labOrder.attributes.external_order_id) ||
      match.test(labOrder.attributes.guid) ||
      match.test(labOrder.attributes.photographer_name) ||
      match.test(labOrder.attributes.photographer_business)
    ))
  }

  comparePaidAt(a, b) {
    return a.paid_at - b.paid_at;
  }
  selectOrderToMerge(guid, photographerId) {
    this.setState({
      mergeToOrderGuid : guid,
      selectedPhotographerId: photographerId
    });
    console.log("Selected Order ", guid, "Selected Photographer", photographerId)
  }


  render() {
    let { query,selectedPhotographerId,mergeToOrderGuid, filters, labOrders, mountColor, matColor, frameColor, canvasColor, userIsAdmin, loading, errorState, errorMessage, apiOrderColor, testPrintColor, boutiqueColor, userLoggedOut, resetColor } = this.state

    if (userLoggedOut) {
      return <Redirect to={{ pathname: '/login', state: { notifyUserOfLogout: true } }} push />
    }

    let showingLabOrders
    let parsedDates = []

    if ((query) || (filters && filters.length > 0)) {
      let queryMatchingOrders = this.filteredByQuery(labOrders['data'])
      showingLabOrders = this.filterQueryMatchingOrders(queryMatchingOrders, filters, this.state.viewAsPhotographerId).sort(this.comparePaidAt)
    } else {
      showingLabOrders = labOrders['data'].sort(this.comparePaidAt)
    }

    let adminFilterActions;
    if (labOrders && labOrders['data'].length > 0 && userIsAdmin) {
      adminFilterActions = <div>
        <div className="container-8 w-container">
          <div className="button-19 w-button"
            style={this.setStyle("mat", matColor, filters)}
            name="mat"
            onClick={(e) => { this.addToFilter('mat') }}>
            MATTING </div>

          <div className="button-20 w-button"
            style={this.setStyle("mount", mountColor, filters)}
            name="mount"
            onClick={(e) => { this.addToFilter('mount') }}>
            MOUNTING
              </div>

          <div className="button-21 w-button"
            style={this.setStyle("frame", frameColor, filters)}
            name="frame"
            onClick={(e) => { this.addToFilter('frame') }}>
            FRAMING </div>

          <div className="button-22 w-button"
            style={this.setStyle("canvas", canvasColor, filters)}
            name="canvas"
            onClick={(e) => { this.addToFilter('canvas') }}>
            CANVAS </div>

          <div className="button-23 w-button"
            style={this.setStyle("api_order", apiOrderColor, filters)}
            name="api_order"
            onClick={(e) => { this.addToFilter('api_order') }}>
            API ORDER </div>

          <div className="button-24 w-button"
            style={this.setStyle("test_print", testPrintColor, filters)}
            name="test_print"
            onClick={(e) => { this.addToFilter('test_print') }}>
            TEST PRINTS </div>

          <div className="button-25 w-button"
            style={this.setStyle("boutique", boutiqueColor, filters)}
            name="boutique"
            onClick={(e) => { this.addToFilter('boutique') }}>
            BOUTIQUE </div>

          <div className="button-27 w-button"
            style={{ borderColor: this.state.resetColor, color: this.state.resetColor }}
            name="reset"
            onClick={(e) => { this.addToFilter('reset') }}>
            RESET
               </div>
        </div>
        <div className="container-8 w-container" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          <div className="button-37 w-button" style={this.setStyle("hide_open", this.state.resetColor, filters)} name="hide_open" onClick={(e) => { this.addToFilter('hide_open') }}> HIDE OPEN </div>
          <div className="button-47 w-button" style={this.setStyle("hide_my_orders", this.state.resetColor, filters)} name="hide_my_orders" onClick={(e) => { this.addToFilter('hide_my_orders') }}> HIDE MY ORDERS </div>
        </div>

      </div>
    } else {
      adminFilterActions = <div></div>
    }

    if(mergeToOrderGuid !== null && selectedPhotographerId !== null){
      showingLabOrders = labOrders['data'].filter((data) => data.attributes.photographer_id === selectedPhotographerId)
    }

    let labOrderCards

    let urgentOrdersCards = [];

    if (labOrders && labOrders['data'].length > 0 && userIsAdmin) {
      labOrders['data'].forEach(labOrder => {
        var url = labOrder.attributes.cover_photo_url
        var response = labOrder.attributes
        Auth.addDataIntoCache('coverPhotoCache', url, response)
      })
      showingLabOrders.map(labOrder => {
        let parsedDate
        let formattedDate = this.formatOrderDate(labOrder)

        if (!parsedDates.includes(formattedDate)) {
          parsedDates.push(formattedDate)
          parsedDate = formattedDate
        } else {
          parsedDate = ""
        }

        let includedAttributes = this.appendIncludedAttributes(labOrder)
        if (labOrder['attributes']['urgent'] === true)
          urgentOrdersCards.push(<LabOrderCard key={labOrder.attributes.guid}
            guid={labOrder.attributes.guid}
            orderId={labOrder.id}
            orderName={labOrder.attributes.order_name}
            orderPhotographerName={labOrder.attributes.photographer_name}
            orderPhotographerBusinessName={labOrder.attributes.photographer_business}
            orderStatus={labOrder.attributes.status}
            orderCreatedAt={labOrder.attributes.created_at}
            orderUpdatedAt={labOrder.attributes.updated_at}
            orderPaidAt={labOrder.attributes.paid_at}
            includedAttributes={includedAttributes}
            parsedDate={parsedDate}
            orderShippedAt={labOrder.attributes.shipped_at}
            orderTrackingUrl={labOrder.attributes.tracking_url}
            orderTrackingNumber={labOrder.attributes.tracking_number}
            orderBoutiqueShippingNote={labOrder.attributes.boutique_shipping_note}
            orderBoutiqueShippingPrice={labOrder.attributes.boutique_shipping_price}
            orderShippingMethod={labOrder.attributes.shipping_method}
            coverPhoto={labOrder.attributes.cover_photo_url}
            orderExternalId={labOrder.attributes.external_order_id}
            orderNotes={labOrder.attributes.notes}
            orderHasNotes={labOrder.attributes.has_notes}
            orderAdminNotes={labOrder.attributes.internal_notes}
            orderHasAdminNotes={labOrder.attributes.has_admin_notes}
            orderIsApi={labOrder.attributes.api_order}
            orderApiVendor={labOrder.attributes.api_vendor}
            orderHasFraming={labOrder.attributes.has_frame}
            orderHasMounting={labOrder.attributes.has_mount}
            orderHasMatting={labOrder.attributes.has_mat}
            downloadAllLink={labOrder.attributes.download_all_link}
          />)
      })
      labOrderCards = showingLabOrders.map(labOrder => {
        let parsedDate
        let formattedDate = this.formatOrderDate(labOrder)

        if (!parsedDates.includes(formattedDate)) {
          parsedDates.push(formattedDate)
          parsedDate = formattedDate
        } else {
          parsedDate = ""
        }

        let includedAttributes = this.appendIncludedAttributes(labOrder)
        return (

          <AdminLabOrderCard key={labOrder.attributes.guid}
            // TODO AM HERE
            mergeOrder={() => this.selectOrderToMerge(labOrder.attributes.guid, labOrder.attributes.photographer_id)}
            // 
            mergeToOrderGuid={this.state.mergeToOrderGuid}
            guid={labOrder.attributes.guid}
            orderId={labOrder.id}
            orderName={labOrder.attributes.order_name}
            orderPhotographerName={labOrder.attributes.photographer_name}
            orderPhotographerBusinessName={labOrder.attributes.photographer_business}
            orderStatus={labOrder.attributes.status}
            orderCreatedAt={labOrder.attributes.created_at}
            orderUpdatedAt={labOrder.attributes.updated_at}
            orderPaidAt={labOrder.attributes.paid_at}
            includedAttributes={includedAttributes}
            parsedDate={parsedDate}
            orderShippedAt={labOrder.attributes.shipped_at}
            orderTrackingUrl={labOrder.attributes.tracking_url}
            orderTrackingNumber={labOrder.attributes.tracking_number}
            orderBoutiqueShippingNote={labOrder.attributes.boutique_shipping_note}
            orderBoutiqueShippingPrice={labOrder.attributes.boutique_shipping_price}
            orderShippingMethod={labOrder.attributes.shipping_method}
            coverPhoto={labOrder.attributes.cover_photo_url}
            orderExternalId={labOrder.attributes.external_order_id}
            orderNotes={labOrder.attributes.notes}
            orderHasNotes={labOrder.attributes.has_notes}
            orderAdminNotes={labOrder.attributes.internal_notes}
            orderHasAdminNotes={labOrder.attributes.has_admin_notes}
            orderIsApi={labOrder.attributes.api_order}
            orderApiVendor={labOrder.attributes.api_vendor}
            orderHasFraming={labOrder.attributes.has_frame}
            orderHasMounting={labOrder.attributes.has_mount}
            orderHasMatting={labOrder.attributes.has_mat}
            downloadAllLink={labOrder.attributes.download_all_link}
          />
        );
      })

    } else {
      labOrderCards = showingLabOrders.map(labOrder => {
        return (
          <div key={labOrder.attributes.guid}>
            <LabOrderCard
              guid={labOrder.attributes.guid}
              orderId={labOrder.id}
              orderName={labOrder.attributes.order_name}
              orderPhotographerBusinessName={labOrder.attributes.photographer_business}
              orderStatus={labOrder.attributes.status}
              destroyLabOrder={this.destroyLabOrder}
              orderCreatedAt={labOrder.attributes.created_at}
              orderUpdatedAt={labOrder.attributes.updated_at}
              orderPaidAt={labOrder.attributes.paid_at}
              coverPhoto={labOrder.attributes.cover_photo_url}
              orderExternalId={labOrder.attributes.external_order_id}
              orderNotes={labOrder.attributes.notes}
            />
          </div>
        );
      })
    }


    if (loading) {
      return (
        <div>
          <NavBar currentUserAdmin={this.state.userIsAdmin} />

          <div className="LabOrders">

            <div className="container-3 w-container">
              <Link to="/lab-orders/upload">
                <div className="button-2 w-button">
                  NEW LAB ORDER
              </div>
              </Link>

              <div className="search w-form">
                <h1 style={{ textAlign: 'center' }}>loading</h1>
              </div>
            </div>

          </div>
        </div>
      )
    } else if (errorState !== false) {
      return (
        <div>
          <NavBar currentUserAdmin={this.state.userIsAdmin} />
          <div className="LabOrders">

            <div className="container-3 w-container">
              <Link to="/lab-orders/upload">
                <div className="button-2 w-button">
                  NEW LAB ORDER
              </div>
              </Link>

              <div className="search w-form">
                <div className="w-form-fail"
                  style={{
                    textAlign: "center",
                    display: 'inline-block',
                    overflowWrap: "break-word"
                  }}>
                  ERROR LOADING: {errorMessage}
                </div>
              </div>
            </div>

          </div>
        </div>
      )
    } else {
      return (
        <div>
          <NavBar currentUserAdmin={this.state.userIsAdmin} />
          <div className="LabOrders">

            <div className="container-3">
              <Link to="/lab-orders/upload">
                <div className="button-2 w-button">
                  NEW LAB ORDER
                </div>
              </Link>

              <div className="search w-form">
                <input
                  type="text"
                  className="search-input w-input"
                  onChange={this.handleChange}
                  placeholder="Search by Order Name or Status"
                />
              </div>

              {adminFilterActions}
              <div style={{ padding: "50px" }}>
                {urgentOrdersCards.length ?
                  <div>
                    <h4>Urgent Orders:</h4> <br></br>
                    <div className="w-layout-grid grid">
                      {urgentOrdersCards}
                    </div>
                    <hr></hr>
                  </div> :
                  <div></div>}
                <div>
                  <div className="w-layout-grid grid">
                    {labOrderCards}
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      );
    }
  }
}

export default LabOrders;
