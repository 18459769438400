import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Auth from "./modules/Auth";
import axios from "axios";
import Dropzone from 'react-dropzone';

import logo1080 from "./img/MUSEAbadge-black-p-1080.jpeg";
import logo500 from "./img/MUSEAbadge-black-p-500.jpeg"
import logo800 from "./img/MUSEAbadge-black-p-800.jpeg"
import logoBlack from "./img/MUSEAbadge-black.jpg"

//import "./css/signInWebflow.css";

class SignUpThree extends Component {
  constructor() {
    super();
    this.state = {
      errorState: false,
      businessLocationSubmitted: false,
      inTnSelected: true,
      uploading: false,
      accepted: [],
    };
   this.toggleLocation = this.toggleLocation.bind(this);
   this.throwError = this.throwError.bind(this)
   this.handleSubmit = this.handleSubmit.bind(this)
   this.getUserProfile = this.getUserProfile.bind(this)
  }

  componentDidMount() {
    this.getUserProfile()
  }

  getUserProfile() {
    var token = Auth.getToken()
    axios
    .get('/api/v2/profile', {headers: {token: token}})
         .then(res => {
           console.log(res);
           this.setState({
             creditCards: res.data.cards.data,
             addresses: res.data.addresses.data,
             user: res.data.user,
             guid: res.data.user.data.attributes.guid,
             defaultAddress: res.data.default_address,
             defaultCard: res.data.default_card
           });
         })
         .catch(err =>
                this.throwError(err))
  }

  toggleLocation(e) {
    if (e.target.value === "NO") {
       this.setState({
         inTnSelected: false
       });
    } else {
       this.setState({
         inTnSelected: true
       });
    }
  }

  onDrop(acceptedFiles, rejectedFiles) {
    this.setState({
      uploading: true,
      completedUpload: false
    })

    acceptedFiles.forEach(file => {

      var token = Auth.getToken()
      axios
      .post(`/api/v2/lab_orders/${this.state.guid}/create_presigned_url`,
            {guid: this.state.guid,
              file_name: file.name
            }, {headers: {'token': token}})
      .then(res => {
        let signedUrl = res.data.signedUrl;
        //alert(signedUrl);

        var options = {
          headers: {
            'Content-Type': file.type
          }
        };


        this.setState({
          certLink: signedUrl,
        })

        return axios.put(signedUrl, file, options);
      })
      .then(res => {
      axios
      .patch(`/api/v2/lab_orders/${this.state.guid}/photograph_uploaded`,
            {guid: this.state.guid,
              file_name: file.name
            }, {headers: {'token': token}})
      })
      .then(res => {
        this.setState({
          accepted: acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
          })),
          rejected: rejectedFiles,
          uploading: false,
          completedUpload: true,
          fileCount: (acceptedFiles.length + rejectedFiles.length)
        });
      })
      .catch(function (err) {
        alert(err);
      });

    });
  }

  handleSubmit(e) {
    e.preventDefault()
    let tn_business
    if (this.state.inTnSelected) {
      tn_business = true
    } else {
      tn_business = false
    }
    var token = Auth.getToken()
    axios
    .patch('/api/v2/update_new_user',
          {tn_business: tn_business}, {headers: {'token': token}})
          .then(res => {
            this.setState({
              businessLocationSubmitted: true
            });

          })
          .catch(err =>
                 this.throwError(err))
  }

  throwError(err) {
    let errorMessage
    if (err.response != null) {
      let errorObject = err.response.data.error
      let errorMessage = "\nERROR CODE: " + errorObject.status + "\n ERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.developer_message
      if (err.response.data.error.more_info != null){
        console.log("ERROR MESSAGE: " + errorMessage + "\nMORE INFO: " + err.response.data.error.more_info.join(" ,").split(",").join("\n"))
      } else {
        console.log(errorMessage + " More info not available.")
      }
    } else {
      errorMessage = err.stack
    }

    this.setState({
      loading: false,
      selections: [],
      errorState: true,
      addingToCart: false,
      errorMessage: errorMessage
    })
  }

  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    this.state.accepted.forEach(
      file =>
        URL.revokeObjectURL(file.preview)
    )
  }

  render() {
    const {fromEvent} = require('file-selector');
    let logoSrcSet = `${logo500} 500w, ${logo800} 800w, ${logo1080} 1080w, ${logoBlack} 1200w`
    let inTnOptionValue, submitApplicationButton, uploadCertificateSection, certLink

    let blanketCertFileName, blanketCertUpdate

    if (this.state.certLink && this.state.certLink.length) {
      certLink = this.state.certLink
    } else if (this.state.user) {
      certLink = this.state.user.data.attributes.blanket_certificate_of_resale_link
      if (certLink && certLink.length) {
        let certName = unescape(certLink.split("/")[certLink.split("/").length - 1])
        blanketCertFileName = unescape(certName.split("?")[0])
      } else {
        blanketCertFileName = ""
      }
    } else {
      certLink = ""
      blanketCertFileName = ""
    }

    if (certLink && certLink.length) {
      let certName = unescape(certLink.split("/")[certLink.split("/").length - 1])
      blanketCertFileName = unescape(certName.split("?")[0])
    } else {
      blanketCertFileName = ""
    }

    if (this.state.businessLocationSubmitted) {
      return <Redirect to={{ pathname: "/signup/confirmation", state: {} }} push />
    }

    if (this.state.inTnSelected && blanketCertFileName.length > 0) {
      inTnOptionValue = "YES"
      blanketCertUpdate = <div>
       <span className="text-block-23" style={{display: 'block', marginBottom: '10px'}}>{blanketCertFileName}</span>
      </div>
      uploadCertificateSection = <div>
            <div className="container-6 w-container">

            <Dropzone
              className="dropzone"
              accept="application/pdf"
              getDataTransferItems={evt => fromEvent(evt)}
              onDrop={this.onDrop.bind(this)}>

              {({getRootProps, open, getInputProps}) => (
                <div {...getRootProps()}
                   className="selectaphototobe"
                   style={{ }}>

                  <input {...getInputProps()} className="dropzone-area"/>

                  {blanketCertUpdate}
                  <input type="submit" style={{}}
                    value="UPLOAD CERTIFICATE OF RESALE"
                    className="button-17 w-button"/>

                </div>
              )}

            </Dropzone>
          </div>

      </div>


      submitApplicationButton = <input type="submit"
            className="button-16 w-button"
            onClick={(e) => {this.handleSubmit(e)}}
            style={{ width: '340px'}}
            value="SUBMIT APPLICATION"/>
    } else if (this.state.inTnSelected) {
      console.log('it is this one')
      inTnOptionValue = "YES"

      blanketCertUpdate = <div> </div>
      uploadCertificateSection = <div>
            <div className="container-6 w-container">

            <Dropzone
              className="dropzone"
              accept="application/pdf"
              getDataTransferItems={evt => fromEvent(evt)}
              onDrop={this.onDrop.bind(this)}>

              {({getRootProps, open, getInputProps}) => (
                <div {...getRootProps()}
                   className="selectaphototobe"
                   style={{ }}>

                  <input {...getInputProps()} className="dropzone-area"/>

                  {blanketCertUpdate}

                  <input type="submit" style={{}}
                  value="UPLOAD CERTIFICATE OF RESALE"
                  className="button-17 w-button"/>

                </div>
              )}

            </Dropzone>
          </div>
      </div>
      submitApplicationButton = <input type="submit"
            className="button-16 w-button"
            disabled
            style={{ opacity: '0.5', width: '340px'}}
            value="SUBMIT APPLICATION"/>

    } else {
      inTnOptionValue = "NO"
      uploadCertificateSection = <div></div>
      submitApplicationButton = <input type="submit"
            className="button-16 w-button"
            onClick={(e) => {this.handleSubmit(e)}}
            style={{ width: '340px'}}
            value="SUBMIT APPLICATION"/>
    }

    if (this.state.uploading) {
      return (
        <div>
          <div className="SignUp">
            <img alt="musea logo"
              srcSet={logoSrcSet}
              sizes="144px"
              className="image"
              src={logoBlack}
              style={{width: "144"}}
              />

            <div className="text-block-19">
              <strong>SIGN UP<br/></strong>
                <br/>
               1 • 2 • <strong>3</strong>
            </div>

            <div className="text-block-22">
              Is your business located in Tennessee?
            </div>

            <div className="w-form">
              <div>
                <select value={inTnOptionValue}
                onChange={(e) => {this.toggleLocation(e)}}
                className="select-field-2 w-select">
                  <option value="">Select one...</option>
                  <option value="YES">YES</option>
                  <option value="NO">NO</option>
                </select>
              </div>

            <div className="container-6 w-container" style={{textAlign: 'center', fontWeight: 700}}>
              uploading certificate...
            </div>


          </div>


          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="SignUp">
            <img alt="musea logo"
              srcSet={logoSrcSet}
              sizes="144px"
              className="image"
              src={logoBlack}
              style={{width: "144"}}
              />

            <div className="text-block-19">
              <strong>SIGN UP<br/></strong>
                <br/>
               1 • 2 • <strong>3</strong>
            </div>

            <div className="text-block-22">
              Is your business located in Tennessee?
            </div>

            <div className="w-form">
              <div>
                <select value={inTnOptionValue}
                onChange={(e) => {this.toggleLocation(e)}}
                className="select-field-2 w-select">
                  <option value="">Select one...</option>
                  <option value="YES">YES</option>
                  <option value="NO">NO</option>
                </select>
              </div>

              <div className="text-block-23">
                If yes, we require a copy of your Blanket Certificate of Resale
                <br/>
                <br/>
                If no, then you can click &#x27;SUBMIT&#x27;
              </div>

              {uploadCertificateSection}

              <div className="text-block-23">
                By entering your email, you consent to receive
                email updates from MUSEA.
              </div>
              {submitApplicationButton}
          </div>


          </div>
        </div>
      );

    }

  }
}

export default SignUpThree;
