import React, { Component } from "react";
//import { Link } from "react-router-dom";

//import axios from "axios";
//import "../css/Account.css";
//import "../css/newWebflow.css";
import AddressCard from "./AddressCard.js";

class AddressList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addresses: this.props.addresses,
      defaultAddress: this.props.defaultAddress
    };
   this.toggleDropdownOpen = this.toggleDropdownOpen.bind(this)
  }

  toggleDropdownOpen(e) {
    e.preventDefault()
    if (e.target.classList.contains("w--open")) {
      e.target.classList.remove("w--open")
      document.querySelector("nav.dropdown-list-2").classList.remove('w--open');
    } else {
      e.target.classList.add("w--open")
      document.querySelector("nav.dropdown-list-2").classList.add('w--open');
    }
  }

  throwError(err) {
    let errorObject = err.response.data.error
    let errorMessage = "\nERROR CODE: " + errorObject.status + "\n ERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.developer_message
    console.log("ERROR MESSAGE: " + errorMessage + "\n MORE INFO: " + err.response.data.error.more_info.join(" ,").split(",").join("\n"))
    //alert(errorMessage)

    this.setState({
      loading: false,
      errorState: true,
      errorMessage: errorMessage
    })
  }

  render() {
    let addressList, primaryAddress
    if (this.props.defaultAddress && this.props.defaultAddress.data) {
      let address = this.props.defaultAddress.data
      primaryAddress = <div className="div-block-27">
        <div className="text-block-78">
        <strong>PRIMARY ADDRESS</strong>
        <AddressCard key={address.attributes.guid} guid={address.attributes.guid} deleteItem={this.props.deleteItem} recipient={address.attributes.recipient} address={address.attributes.street} city={address.attributes.city} state={address.attributes.state_province} zip={address.attributes.zip} primary='true' />
        </div>
      </div>
    } else{
      primaryAddress = <div></div>
    }

    if(this.props.addresses != null && this.props.addresses.length > 0) {
      addressList = this.props.addresses.map(address => {
         return( <a href="#" onClick={(e) => {this.props.makePrimary(address.attributes.guid, "address")}} key={address.attributes.guid} className="dropdown-link-4 w-dropdown-link">
            {address.attributes.recipient} {address.attributes.street} {address.attributes.city}, {address.attributes.state} {address.attributes.zip}
          </a>)
      })

      addressList.push( <a key="clearAll" onClick={(e) => {this.props.clearAddressList(e) }} className="dropdown-link-4 w-dropdown-link" style={{textAlign: 'center'}}> CLEAR LIST </a>)
        //return(<AddressCard key={address.attributes.guid} deleteItem={this.props.deleteItem} makePrimary={this.props.makePrimary} id={address.attributes.guid} guid={address.attributes.guid} recipient={address.attributes.recipient} address={address.attributes.street} city={address.attributes.city} state={address.attributes.state_province} zip={address.attributes.zip} primary={address.attributes.is_primary} />)
    } else {
      addressList = <div className="AddressText">
        NO ADDRESSES SAVED
      </div>
    }

    return (
      <div>
        <br />
        {primaryAddress}
        <br />

        <div data-delay="0" 

        onClick={(e) => {this.toggleDropdownOpen(e)}}
        className="dropdown-2 w-dropdown">
          <div className="dropdown-toggle-2 w-dropdown-toggle">
            <div className="w-icon-dropdown-toggle">
            </div>
            <div>SET PRIMARY ADDRESS</div>
          </div>
          <nav className="dropdown-list-2 w-dropdown-list">
            {addressList}
          </nav>
        </div>

      </div>
    )
  }
}

export default AddressList;

