import React, { Component } from "react";
//import { Link } from "react-router-dom";

//import axios from "axios";
//import garbageIcon from "../img/account-page-garbage.png";

//import CreditCardListItem from "./CreditCardListItem.js";
//import NewCreditCardForm from "./NewCreditCardForm.js";
//import "../css/newWebflow.css";

class CreditCardList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creditCards: this.props.creditCards,
      updatingPrimaryCard: false
    };
   this.toggleDropdownOpen = this.toggleDropdownOpen.bind(this)
  }

  toggleDropdownOpen(e) {
    e.preventDefault()
    if (e.target.classList.contains("w--open")) {
      e.target.classList.remove("w--open")
      document.querySelector("nav.dropdown-list").classList.remove('w--open');
    } else {
      e.target.classList.add("w--open")
      document.querySelector("nav.dropdown-list").classList.add('w--open');
    }
  }

  render() {
    let creditCardList, primaryCard

    if (this.props.defaultCard && this.props.defaultCard.data) {
      let card = this.props.defaultCard.data[0]
      if (card && card.attributes) {
      primaryCard = <div style={{marginLeft: '160px', marginBottom: '30px'}} className="div-block-27">
        <div className="text-block-78">
        <strong>PRIMARY CARD</strong>
          <div className="CardText">
            {card.attributes.masked_card}
          </div>
        </div>
      </div>
      } else {
        primaryCard = <div></div>
      }
    } else{
      primaryCard = <div></div>
    }

    if(this.props.creditCards.length > 0) {
      creditCardList = this.props.creditCards.map(card => {
         return( <a key={card.attributes.id} onClick={(e) => {this.props.makePrimary(card.attributes.id, "card")}} className="dropdown-link-4 w-dropdown-link">
            {card.attributes.masked_card}
          </a>)
      })
      creditCardList.push(<a href="#" key="clear" onClick={(e) => {this.props.clearCardList(e)}} style={{textAlign: 'center'}} className="dropdown-link-4 w-dropdown-link">CLEAR LIST</a>)
    }

    return (
      <div>
        {primaryCard}
        <div className="dropdown-3 w-dropdown"
          onClick={(e) => {this.toggleDropdownOpen(e)}} >
          <div className="dropdown-toggle-3 w-dropdown-toggle">
            <div className="w-icon-dropdown-toggle">
            </div>
            <div>SET PRIMARY CREDIT CARD</div>
          </div>
          <nav className="dropdown-list w-dropdown-list">
            {creditCardList}
          </nav>
        </div>
      </div>
    )
  }
}

export default CreditCardList;




