import React, { Component } from "react";
import Auth from "../modules/Auth";
import axios from "axios";
import SelectUSState from 'react-select-us-states';

class NewAddressForm extends Component {
  constructor() {
    super();
    this.state = {
      stateValid: false,
      streetAddress: '',
      city: '',
      stateName: '',
      zip: '',
      review: true,
      addressFormShown: false,
      addressFormDisplayValue: "display: 'none !important'",
      errorState: false
    };
   this.showNewAddressForm = this.showNewAddressForm.bind(this);
   this.throwError = this.throwError.bind(this)
   this.handleChange = this.handleChange.bind(this)
   this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
  }

  handleChange(e) {
    if (!e.target) {
      this.checkForAutoCompletedAddressFields()
      this.setState({
        stateName: e
      });
    } else {
      const name = e.target.name;
      const val = e.target.value;
      this.setState({
        [name]: val
      });
    }
    //console.log(this.state);
  }

  checkForAutoCompletedAddressFields() {
    let attributesToCheck = ['streetAddress', 'zip', 'city']

    attributesToCheck.forEach(attribute => {
      let autoCompletedValue = document.getElementsByName(attribute)[0].value
      if (this.state[attribute].length < 1 && autoCompletedValue && autoCompletedValue.length > 0) {
        this.setState({
          [attribute]: autoCompletedValue
        });
      }
    })
  }

  handleSubmit(e) {
    e.preventDefault()
    var token = Auth.getToken()
    axios
    .post('/api/v2/addresses',
          { street_address: this.state.streetAddress,
            city: this.state.city,
            state: this.state.stateName,
            zip: this.state.zip
          }, {headers: {token: token}})
          .then(res => {
            console.log("updated")
          })
          .catch(err =>
                 this.throwError(err))
  }

  throwError(err) {
    let errorObject = err.response.data.error
    let errorMessage = "\nERROR CODE: " + errorObject.status + "\n ERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.developer_message
    console.log("ERROR MESSAGE: " + errorMessage + "\n MORE INFO: " + err.response.data.error.more_info.join(" ,").split(",").join("\n"))
    //alert(errorMessage)

    this.setState({
      loading: false,
      errorState: true,
      errorMessage: errorMessage
    })
  }

  showNewAddressForm(e) {
    console.log("setting it up");
    this.setState({
      addressFormShown: true,
      addressFormDisplayValue: "display: 'inline-block !important'"
    });
    console.log(this.state);
  }

  render() {
    let newAddressButton
    if (this.state.streetAddress.length > 0 && this.state.stateName.length > 0 && this.state.zip.length === 5 && this.state.city.length > 0) {
      newAddressButton = <div>
            <input type="submit"
              className="button-11 w-button"
              onClick={(e) => {this.props.createNewAddress(this.state.streetAddress, this.state.city, this.state.stateName, this.state.zip) }}
              value="ADD ADDRESS" />
              </div>
    } else {
      newAddressButton = <div>
              <input type="submit"
                className="button-11 w-button"
                disabled
                style={{opacity: '0.5'}}
                value="ADD ADDRESS" />
            </div>
    }

    return (

      <div className="w-form">
        <div className="text-block-16">
          SHIPPING ADDRESS
        </div>
        <div className="form-5" >
          <input type="text"
            className="text-field-10 w-input"
            name="streetAddress"
            onChange={this.handleChange}
            placeholder="Street Address" />

          <input type="text"
            className="text-field-11 w-input"
            name="city"
            onChange={this.handleChange}
            placeholder="City" />

            <SelectUSState className="text-field-14 w-input" name="state" onChange={this.handleChange}/>

          <input type="text"
            name="zip"
            onChange={this.handleChange}
            className="text-field-15 w-input"
            placeholder="Zip" />

          <br />

          {newAddressButton}
        </div>
      </div>
    )
  }
}

export default NewAddressForm;
