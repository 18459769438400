import React, { Component } from "react";
import NavBar from "./components/NavBar.js";
import LineItemSidebar from "./components/LineItemSidebar.js";
import LineItemSummary from "./components/LineItemSummary.js";
import Auth from "./modules/Auth.js"
import MuseaApi from "./modules/MuseaApi.js"
import axios from "axios"
import { Link, Redirect } from "react-router-dom";
import CartSidebar from "./components/CartSidebar.js";

import Honeybadger from 'honeybadger-js'
import ErrorBoundary from '@honeybadger-io/react'

//import "./css/orderReviewOpen.css";
//import "./css/newWebflow.css";

class OrderReview extends Component {

  constructor(props) {
    super(props);
    const hb_config = {
      api_key: process.env.REACT_APP_HB_KEY,
      environment: process.env.REACT_APP_CURRENT_ENV,
      revision: 'current'
    }
    this.honeybadger = Honeybadger.configure(hb_config)

    this.state = {
      loading: true,
      labOrder: {},
      honeybadger: this.honeybadger,
      guid: this.props.location.pathname["split"]("/")[2],
      localPickup: false,
      clearingCart: false,
      //labOrder: this.props.location.state.labOrder.labOrder,
      errorState: false
    };
    this.changeQty = this.changeQty.bind(this)
    this.deleteLineItem = this.deleteLineItem.bind(this)
    this.clearCart = this.clearCart.bind(this)
    this.updateLabOrder= this.updateLabOrder.bind(this)
    this.toggleLocalPickup = this.toggleLocalPickup.bind(this)
  }

  componentDidMount() {
    console.log("order Review", this.state.guid)
    let museaApi = new MuseaApi
    museaApi.checkAndResetCache()
    //if(this.state.labOrder.lineItems.length > 0 && Object.keys(this.state.labOrder.lineItems[0]).length > 0){
      //let formattedLineItems = this.formatLineItemsForCart(this.state.labOrder.lineItems, this.state.labOrder)
      //this.setState({
        //formattedLineItems: formattedLineItems
      //})
    //} else {
      this.getLabOrder()
      //console.log("got the lab order")

      let MuseaAPI = new MuseaApi()
      if (this.state.products && this.state.products.length < 1) {
        //console.log("getting products")
        let paperPricingFunction = new LineItemSidebar.handlePaperPricingChange()
        MuseaAPI.getProducts().then(products => {
          //console.log("getting paper options")
          let thePaperOptions = MuseaAPI.paperOptions(products, paperPricingFunction)
          this.setState({
            products: products,
            paperOptions: thePaperOptions,
            loadingProducts: false,
            errorState: false
          })
        })
        .catch(err =>
               this.throwError(err))
      }
  }

  componentWillReceiveProps(nextProps) {
    if(this.props !== nextProps) {
      //console.log("resetting props")
      this.setState({
        labOrder: nextProps.labOrder,
        formattedLineItems: nextProps.lineItems,
      })
    }
  }


  getLabOrder = (e, data) => {
    //e.preventDefault();
    var token = Auth.getToken()
    var guid = this.props.location.pathname["split"]("/")[2];
    axios
      .get(`api/v2/lab_orders/${guid}`, {headers: {'token': token}})
      .then(res => {
        let museaApi = new MuseaApi()
        var parsedOrder = museaApi.mergeAttributesForLabOrder(res.data);
        this.setState({
          redirectToReferrer: false,
          auth: Auth.isAuthenticated(),
          from: { pathname: "/" },
          labOrder: parsedOrder,
          formattedLineItems: parsedOrder.lineItems,
          loading: false,
          //loading: true,
          errorState: false 
        });
        return true;
      })
      .catch(err => {
        alert(err)
        this.throwError(err)
        //let error = JSON.parse(err.response)
        //this.throwError(error)
      })
  };


  changeQty(UpOrDown, currentQty, lineItemGuid){
    let newQty
    if (UpOrDown === "increase") {
      newQty = currentQty + 1
    } else {
      newQty = currentQty - 1
    }

    if (newQty > 0) {
      var token = Auth.getToken()
      //var guid = this.props.location.pathname["split"]("/")[2];
      axios
      .patch(`api/v2/line_items/${lineItemGuid}/update_quantity`, {
        line_items: [ { quantity: newQty } ]
      },
      {headers: {'token': token}}
            )
            .then(res => {

              let currentUserAdmin, currentUserApiVendor, viewAsPhotographerId, viewingAsPhotographer
              if (res.data.meta) {
                currentUserAdmin = res.data.meta.current_user_is_admin
                currentUserApiVendor = res.data.meta.current_user_is_api_vendor
                viewAsPhotographerId = res.data.meta.view_as_photographer_id
                viewingAsPhotographer = res.data.meta.view_as_photographer
              } else {
                currentUserAdmin = false
                currentUserApiVendor = false
                viewAsPhotographerId = null
                viewingAsPhotographer = null
              }

              let museaApi = new MuseaApi()
              var parsedOrder = museaApi.mergeAttributesForLabOrder(res.data);
              this.setState({
                redirectToReferrer: true,
                auth: Auth.isAuthenticated(),
                from: { pathname: "/" },
                labOrder: parsedOrder,
                userIsAdmin: currentUserAdmin,
                userIsApiVendor: currentUserApiVendor,
                viewAsPhotographerId: viewAsPhotographerId,
                viewingAsPhotographer: viewingAsPhotographer,
                formattedLineItems: parsedOrder.lineItems,
                loading: false,
                //loading: true,
                errorState: false
              });
              return true;
            })
            .catch(err => {
              this.throwError(err)
              //let error = JSON.parse(err.response)
              //this.throwError(error)
            })
    }
  }

  deleteLineItem(lineItemGuid, action){
    var token = Auth.getToken()
    let clearingCartAction
    if (action === "clearingCart") {
      clearingCartAction = true
    } else {
      clearingCartAction = false
    }

    this.setState({
      loading: true
    })
    //var guid = this.props.location.pathname["split"]("/")[2];
    axios
    .delete(`api/v2/line_items/${lineItemGuid}`,
            {headers: {'token': token}}
           )
           .then(res => {

             let museaApi = new MuseaApi()
             var parsedOrder = museaApi.mergeAttributesForLabOrder(res.data);

             if (parsedOrder.lineItems.length < 1) {
               clearingCartAction = false
             }

             this.setState({
               redirectToReferrer: true,
               auth: Auth.isAuthenticated(),
               from: { pathname: "/" },
               labOrder: parsedOrder,
               clearingCart: clearingCartAction,
               formattedLineItems: parsedOrder.lineItems,
               lineItems: parsedOrder.lineItems,
               selections: [],
               loading: false,
               //loading: true,
               errorState: false
             });
             return true;
           })
           .catch(err => {
             this.throwError(err)
             //let error = JSON.parse(err.response)
             //this.throwError(error)
           })
  }

  clearCart() {
    if (this.state.labOrder && this.state.labOrder !== null && this.state.labOrder.lineItems !== null && this.state.labOrder.lineItems.length > 0) {
      this.setState({clearingCart: true})
      let lineItemsLength = this.state.labOrder.lineItems.length

      this.state.labOrder.lineItems.map((lineItem,index) => {
        //console.log("line item length minus one " + (lineItemsLength - 1))
        if ( (index === (lineItemsLength - 1)) || (index >= (lineItemsLength - 1))) {
          //console.log("index equal or more than line item length")
          this.deleteLineItem(lineItem.guid, "delete")
          this.setState({
            redirectToOrderBuilder: true
          })
        } else {
          //console.log("index less than line item length")
          this.deleteLineItem(lineItem.guid, "clearingCart")
        }
      })
    } else {
      return true
    }
  }

  updateLabOrder(e, type, value) {
    e.preventDefault()

    var guid = this.props.location.pathname["split"]("/")[2];
    const name = e.target.name;
    const val = e.target.value;


    var token = Auth.getToken()

    if (name === "socialMediaShare") {
      type = "permission_to_share"
      if (val === "false") {
        value = false
      } else {
        value = true
      }
    }

    axios
    .patch(`/api/v2/lab_orders/${guid}`,
          {type: type,
            value: value }, {headers: {'token': token}})
          .then(res => {
            //console.log("updated lab order")

            let museaApi = new MuseaApi()
            var parsedOrder = museaApi.mergeAttributesForLabOrder(res.data);
            //console.log("parsedOrder: " + parsedOrder)

            this.setState({
              labOrder: parsedOrder,
              formattedLineItems: parsedOrder.lineItems,
            })
          })
          .catch(err =>
                 this.throwError(err))
  }

  toggleLocalPickup(e) {
    if (this.state.localPickup) {
      this.setState({
        localPickup: false
      });
      this.updateLabOrder(e, "local_pickup", false)
    } else {
      this.setState({
        localPickup: true
      });
      this.updateLabOrder(e, "local_pickup", true)
    }
  }

  renderLineItems(labOrder) {
    let currentIndex = 0
    let renderedLineItems, updateQtyValue, canDeleteValue
    if (labOrder && labOrder !== null && labOrder.status === "open") {
      updateQtyValue = false
      canDeleteValue = true
    } else {
      updateQtyValue = true
      canDeleteValue = false
    }
    if (this.state.formattedLineItems && this.state.formattedLineItems !== null) {
      console.log("returning line items")
      renderedLineItems = this.state.formattedLineItems
      .sort((a, b) => a.guid - b.guid)
      .map(lineItem => {
        let index = currentIndex += 1
        console.log("line item " + index)
        return <LineItemSummary key={lineItem.guid + "" + index} isReviewScreen={true} userIsAdmin={this.state.userIsAdmin} deleteLineItem={this.deleteLineItem} canDeleteItem={canDeleteValue} qtyNoEdit={updateQtyValue} changeQty={this.changeQty} labOrderGuid={lineItem.labOrderGuid} index={index} guid={lineItem.guid} lineItem={lineItem} products={this.state.products} paperOptions={this.state.paperOptions} lineItemId={lineItem.guid} photoGuid={lineItem.photoGuid} coverPhotoUrl={lineItem.photoUrl} croppedPhotoUrl={lineItem.photograph_cropped_url} imageName={lineItem.imageName} externalId={lineItem.externalId} notes={lineItem.notes} adminNotes={lineItem.internalNotes} printSize={lineItem.printSize} printSizeGuid={lineItem.printSizeGuid} printBorder={lineItem.printBorder} printBorderRaw={lineItem.printBorderRaw} paper={lineItem.paper} paperGuid={lineItem.paperGuid} printPrice={lineItem.printPrice} frameProfile={lineItem.frameProfile} finishingCategory={lineItem.finishingCategory} finishingCategoryName={lineItem.finishingCategory} finishingService={lineItem.finishingService} finishingServiceGuid={lineItem.finishingServiceGuid} finishingServiceDetailGuid={lineItem.finishingServiceDetailGuid} finishingDetailPricing={lineItem.finishingServiceDetailGuid} finishingDetailOne={lineItem.finishingDetailOne} finishingDetailTwo={lineItem.finishingDetailTwo} finishingDetailThree={lineItem.finishingDetailThree} frameGlassName={lineItem.frameGlassName} finishingPrice={lineItem.finishingPrice} cropAttributes={lineItem.cropAttributes} quantity={lineItem.quantity} totalPrice={lineItem.totalPrice} />
      })
    }
    return renderedLineItems
  }


  throwError(err) {
    let errorMessage, errorObject
    if (err.response.data["error"] && err.response.data["error"].status === 401) {
      this.setState({
        userLoggedOut: true
      })
      return false
    } else {
      if (err.response.data["error"] != null) {
        errorObject = err.response.data["error"]
        errorMessage = "\nERROR CODE: " + errorObject.status + "\nERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.user_message["message"]
        if (errorObject["more_info"] != null) {
          this.state.honeybadger.notify(err)
          alert("ERROR: " + errorMessage)
          console.log("ERROR: " + errorMessage + "\nMORE INFO: " + errorObject["more_info"].join(" ,").split(",").join("\n"))
        } else {
          this.state.honeybadger.notify(err)
          alert("ERROR: " + errorMessage)
          console.log(errorMessage + " More info not available.")
        }
      } else { 
        errorMessage = "\nERROR CODE: " + err.response.status + "\n ERROR MESSAGE: " + err.response.message
      }
    }
    this.setState({
      loading: false,
      //loading: true,
      selections: [],
      errorState: true,
      addingToCart: false,
      errorMessage: errorMessage
    })
  }


  render() {
    const {loading, clearingCart, guid, redirectToOrderBuilder, products, paperOptions, labOrder, formattedLineItems, userIsAdmin} = this.state
    let redirectUrl, orderName

    if (redirectToOrderBuilder) {
      const redirectPath = `/lab-orders/${guid}/edit/order-builder`;
      return <Redirect push to={{ pathname: redirectPath, state: { cartCleared: true, guid: guid } }} />
    }

    if (labOrder && labOrder.lineItems && labOrder.lineItems.length < 1) {
      let redirectOrderUrl = `/lab-orders/${labOrder.guid}/edit/order-builder`
      return <Redirect to={{ pathname: redirectOrderUrl , state: {notifyUserOfMissingLineItems: true} }} push />
    }

    if (labOrder && labOrder.length > 0  && labOrder.status !== "open") {
      return <Redirect to={{ pathname: `/lab-orders/${labOrder.guid}` , state: {notifyUserOfBadStatus: true} }} push />
    }

    if (labOrder && labOrder !== null) {
      redirectUrl = `/lab-orders/${labOrder.guid}/checkout-details`
      orderName = labOrder.order_name
    } else {
      redirectUrl = ''
      orderName = 'ORDER'
    }

    let renderedLineItems
    renderedLineItems = this.renderLineItems(labOrder)

    if (loading) {
      return (
        <div>
          <NavBar />
          <div className="OrderReviewOpen">

            <div className="w-row">
              <div className="column-18 w-col w-col-10">
                <div className="text-block-86">
                </div>


                <div className="LineItemSummary" style={{marginLeft: '50%', marginTop: '10%', display: 'inline-block', fontWeight: '700'}}>
                  loading...
                </div>
              </div>
            </div>

          </div>
        </div>
      )
    } else if (clearingCart) {

      return (
        <div>
          <NavBar />
          <div className="OrderReviewOpen">

            <div className="w-row">
              <div className="column-18 w-col w-col-10">
                <div className="text-block-86">
                </div>


                <div className="LineItemSummary" style={{marginLeft: '50%', marginTop: '10%', display: 'inline-block', fontWeight: '700'}}>
                  clearing cart...
                </div>
              </div>
            </div>

          </div>
        </div>
      )
    } else {
      return (
        <div>
        <NavBar />
        <div className="OrderReviewOpen">

        <div className="w-row">
          <div className="column-18 w-col w-col-10">
            <div className="text-block-86">
              {orderName}
            </div>

            <div className="LineItemSummary" style={{marginLeft: '30px', display: 'inline-block'}}>
              {renderedLineItems}
            </div>
          </div>


          <div className="CartSidebar column-15 w-col w-col-2" style={{}}>
            <CartSidebar orderReady={true} buttonText="CHECKOUT" showLocalPickup={null} redirectUrl={redirectUrl} guid={labOrder.guid} labOrderSubtotal={parseFloat(+labOrder.subtotal).toFixed(2)} labOrderDiscount={parseFloat(+labOrder.discount).toFixed(2)} labOrderCredit={parseFloat(+labOrder.credit_applied).toFixed(2)} labOrderBoutiqueTotal={parseFloat(+labOrder.boutique_shipping_price).toFixed(2)} shippingTotal={parseFloat(+labOrder.shipping_total).toFixed(2)} localPickup={labOrder.localPickup} toggleLocalPickup={this.toggleLocalPickup} total={parseFloat(+labOrder.total).toFixed(2)} clearCart={this.clearCart} />
          </div>

        </div>
      </div>
    </div>
      );
    }
  }
}

export default OrderReview;
