import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo1080 from "./img/MUSEAbadge-black-p-1080.jpeg";
import logo500 from "./img/MUSEAbadge-black-p-500.jpeg"
import logo800 from "./img/MUSEAbadge-black-p-800.jpeg"
import logoBlack from "./img/MUSEAbadge-black.jpg"

class LabOrderConfirmation extends Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.innerHtml = "";
    document.body.appendChild(script);
  }

  render() {

    let logoSrcSet = `${logo500} 500w, ${logo800} 800w, ${logo1080} 1080w, ${logoBlack} 1200w`
    return (
      <div className="SignUp">

      <img alt="musea logo"
        srcSet={logoSrcSet}
        sizes="144px"
        className="image"
        src={logoBlack}
        style={{width: "144"}}
        />

      <div class="text-block-24">
          Thank you for your order! We'll get started on it right away.
          <br/>
          You should receive a confirmation email and receipt shortly.
      </div>

          <Link style={{color: 'black'}} to={{
            pathname: `/lab-orders`
          }}>
            <div className="button-30 w-button">
                RETURN HOME
            </div>
          </Link>

      </div>
    );
  }
}

export default LabOrderConfirmation;
