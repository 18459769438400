import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Auth from "./modules/Auth.js";
import MuseaApi from "./modules/MuseaApi.js"

import Dropzone from 'react-dropzone';
import NavBar from "./components/NavBar.js";
//import outlineArrowDown from "./img/order-page-1-outlinearrowdownwardblack48dp.png";

//import "./css/newWebflow.css";

class UploaderProgress extends Component {
  constructor(props) {
    super(props)
    this.state = {
      guid: this.props.location.pathname["split"]("/")[2],
      files: [],
      fileCount: 0,
      uploadedCount: 0,
      uploading: false,
      completedUpload: false,
      processing: false,
      accepted: [],
      rejected: []
    };
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.innerHtml = "";
    document.body.appendChild(script);
    this.getLabOrder()
  }

  getLabOrder = (e, data) => {
    //console.log("getting lab order")
    //e.preventDefault();
    var token = Auth.getToken()
    var guid = this.props.location.pathname["split"]("/")[2];
    //console.log("got guid")
    axios
      .get(`api/v2/lab_orders/${guid}`, {headers: {'token': token}})
      .then(res => {
        //console.log("res " + res.data['data'].attributes);
        //console.log("got a response")

        let museaApi = new MuseaApi()
        var parsedOrder = museaApi.mergeAttributesForLabOrder(res.data);
        //console.log("setting state")
        this.setState({
          redirectToReferrer: true,
          auth: Auth.isAuthenticated(),
          from: { pathname: "/" },
          labOrder: parsedOrder,
          formattedLineItems: parsedOrder.lineItems,
          guid: this.props.location.pathname["split"]("/")[2],
          files: [],
          fileCount: 0,
          loadedLabOrder: true,
          uploading: false,
          completedUpload: false,
          accepted: [],
          rejected: [],
          loading: false,
          errorState: false
        });
      })
      .catch(err => {
        this.throwError(err)
        //let error = JSON.parse(err.response)
        //this.throwError(error)
      })
  };

  throwError(err) {
    let errorMessage, errorObject
    if (err.response.data["error"] && err.response.data["error"].status === 401) {
      this.setState({
        userLoggedOut: true
      })
      return false
    } else {
      if (err.response.data["error"] != null) {
        errorObject = err.response.data["error"]
        errorMessage = "\nERROR CODE: " + errorObject.status + "\nERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.user_message["message"]
        if (errorObject["more_info"] != null) {
          this.state.honeybadger.notify(err)
          alert("ERROR: " + errorMessage)
          console.log("ERROR: " + errorMessage + "\nMORE INFO: " + errorObject["more_info"].join(" ,").split(",").join("\n"))
        } else {
          this.state.honeybadger.notify(err)
          alert("ERROR: " + errorMessage)
          console.log(errorMessage + " More info not available.")
        }
      } else { 
        errorMessage = "\nERROR CODE: " + err.response.status + "\n ERROR MESSAGE: " + err.response.message
      }
    }
    this.setState({
      loading: false,
      selections: [],
      errorState: true,
      addingToCart: false,
      errorMessage: errorMessage
    })
  }

  onDrop(acceptedFiles, rejectedFiles) {
    this.setState({
      uploading: true,
      completedUpload: false
    })

    let errors = []
    acceptedFiles.forEach((file, index) => {

      var guid = this.props.location.pathname["split"]("/")[2];
      var token = Auth.getToken()
      axios
      .post(`api/v2/lab_orders/${guid}/create_presigned_url`,
            {lab_order_guid: this.state.guid,
              photo_file_name: file.name,
              content_type: file.type
            }, {headers: {'token': token}})
      .then(res => {
        var signedUrl = res.data.signedUrl;
        //alert(signedUrl);

        var options = {
          headers: {
            'Content-Type': file.type
          }
        };

        return axios.put(signedUrl, file, options);
      })
      .then(res => {
      axios
      .patch(`api/v2/lab_orders/${guid}/photograph_uploaded`,
            {lab_order_guid: this.state.guid,
              photo_file_name: file.name
            }, {headers: {'token': token}})
      })
      .then(res => {
        //console.log(result);
        let newUploadCount = this.state.uploadedCount + 1
        this.setState({
          accepted: acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
          })),
          rejected: rejectedFiles,
          uploadedCount: newUploadCount,
          uploading: false,
          completedUpload: true,
          processing: true,
          fileCount: (acceptedFiles.length + rejectedFiles.length)
        });
      })
      .then( res => {
        if (this.state.uploadedCount === acceptedFiles.length) {
          this.setState({
            uploading: false,
            completedUpload: true,
            processing: false
          })
        }
      })
      .catch(function (err) {
        console.log("Error uploading file number: Error: " + err)
        errors.push(err)
      });
    })

    if (errors.length > 1) {
      alert("Errors while uploading: " + errors.join("\n"))
    }

  }

  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    this.state.accepted.forEach(
      file =>
        URL.revokeObjectURL(file.preview)
    )
  }

  render() {
    const {fromEvent} = require('file-selector');
    const {labOrder, accepted, rejected} = this.state;
    let orderName, dropzoneInfo

    if (labOrder) {
      orderName = labOrder.order_name
    } else {
      orderName = "LAB ORDER UPLOADER"
    }
    let completedUploadButtons, uploadingInfo

   console.log("uploadedCount " + this.state.uploadedCount)
   if (this.state.completedUpload && !this.state.processing) {
     completedUploadButtons = <div className="columns w-row">
            <div className="w-col w-col-2"></div>
            <div className="w-col w-col-2"></div>

            <div className="column-3 w-col w-col-2">
                <Link style={{color: 'black'}} to={{
                  pathname: `/lab-orders/${this.state.guid}/upload-progress`,
                  state: {
                    orderGuid: `${this.state.guid}`
                  }
                }}>
                  <div className="button-7 w-button">
                      UPLOAD MORE
                  </div>
              </Link>
            </div>

            <div className="column-12 w-col w-col-2">

                <Link style={{color: 'white'}} to={{
                  pathname: `/lab-orders/${this.state.guid}/edit/order-builder`,
                  state: {
                    orderGuid: `${this.state.guid}`
                  }
                }}>
                  <div className="button-8 w-button">
                    SELECT PRODUCTS
                  </div>
                </Link>
            </div>
            <div className="w-col w-col-2"></div>
            <div className="w-col w-col-2"></div>
          </div>
    } else {
      completedUploadButtons = <div className="completedUploadButtons"> </div>
    }

   if (this.state.uploading) {
     uploadingInfo = <div className="text-block-13">uploading...</div>
     dropzoneInfo = <div></div>
   } else if (this.state.processing) {
     uploadingInfo = <div className="uploadingInfo">
        <div className="text-block-13">
          processing...
          <br/>
          {this.state.uploadedCount} of {this.state.fileCount} photos uploaded
          <br/>
          {this.state.rejected.length} photos rejected
        </div>
     </div>
     dropzoneInfo = <div></div>
   } else if (this.state.completedUpload){
     uploadingInfo = <div className="uploadingInfo">
        <div className="text-block-13">
          {this.state.accepted.length} of {this.state.fileCount} photos uploaded
          <br/>
          {this.state.rejected.length} photos rejected
        </div>
     </div>
     dropzoneInfo = <div></div>
   } else {
     uploadingInfo = <div className="uploadingInfo"></div>
     dropzoneInfo = <div>
                       <input type="submit" style={{width: '240px'}} value="SELECT PHOTOS TO UPLOAD" className="submit-button-2 w-button"/>
                    </div>
   }

    const rejectedThumbs = rejected.map(file => (
      <div className="uploadedImageContainer" key={file.name}>
        <div className="imageName" style={{color: 'red'}}>
          REJECTED: {file.name}
        </div>
        <br />
      </div>
    ));

    const acceptedThumbs = accepted.map(file => (
      <div>
        <div className="div-block" key={file.name} style={{height: '260px',
            textAlign: 'center',
            verticalAlign: 'middle' }}>

          <img anima-src={file.preview}
            className=""
            src={file.preview}
            sizes="(max-width: 479px) 125px, (max-width: 767px) 26vw, (max-width: 991px) 17vw, 20vw"
            style={{maxHeight: '230px'}}
            srcSet={"" + file.preview + " 500w"}
            alt={file.name} />

            <div className="div-block-2">
              <div className="text-block-10">
                <div className="bold-text-2" style={{textTransform: 'uppercase'}}>
                  <strong> {file.name} </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
    ));

    return (
      <div>
        <NavBar />

        <div className="Uploader">

          <div className="text-block-12">{orderName}</div>

              <Dropzone
                className="dropzone"
                accept="image/jpeg, image/png"
                getDataTransferItems={evt => fromEvent(evt)}
                onDrop={this.onDrop.bind(this)}>

                {({getRootProps, open, getInputProps}) => (
                  <div {...getRootProps()}
                     className="text-block-13"
                     style={{ textAlign: 'center' }}>

                    <input {...getInputProps()} className="dropzone-area"/>
                    {dropzoneInfo}

                  </div>
                )}


              </Dropzone>

            {completedUploadButtons}
          </div>

           <div className="uploadedPhotos">
             {uploadingInfo}

            <div className="uploadedImagesContainer">
              {rejectedThumbs}

              <div className="container-3">
                <div className="w-layout-grid grid">
                  {acceptedThumbs}
                </div>
              </div>
            </div>
        </div>
    </div>
    );
  }
}

export default UploaderProgress;
