import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo1080 from "./img/MUSEAbadge-black-p-1080.jpeg";
import logo500 from "./img/MUSEAbadge-black-p-500.jpeg"
import logo800 from "./img/MUSEAbadge-black-p-800.jpeg"
import logoBlack from "./img/MUSEAbadge-black.jpg"
import Auth from "./modules/Auth.js";
import axios from "axios";

class ForgotPasswordConfirm extends Component {

  constructor() {
    super();
    this.state = {
      creating: false,
      redirectToReferrer: false,
      errorState: false,
      auth: Auth.isAuthenticated()
    };
   this.handleChange = this.handleChange.bind(this);
   this.throwError = this.throwError.bind(this)
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.innerHtml = "";
    document.body.appendChild(script);
  }

  handleChange(e) {
    const name = e.target.name;
    const val = e.target.value;
    this.setState({
      [name]: val
    });
    //console.log(this.state);
  }

  handleSubmit(e) {
    e.preventDefault()
    this.setState({
      creating: true
    });

    var token = Auth.getToken()
    axios
    .post('/api/v2/lab_orders',
          {order_name: this.state.orderName,
          }, {headers: {'token': token}})
          .then(res => {
            //console.log(result);
            var orderGuid = res.data.data.attributes.guid
            this.setState({
              guid: orderGuid
            });
          })
          .catch(err =>
                 this.throwError(err))
  }

  throwError(err) {
    let errorMessage
    if (err.response != null) {
      let errorObject = err.response.data.error
      errorMessage = "\nCODE: " + errorObject.status + "\nERROR CLASS: " + errorObject.class_name + "\nERROR MESSAGE: " + errorObject.developer_message
      if (err.response.data.error.more_info != null){
        console.log("ERROR MESSAGE: " + errorMessage + "\nMORE INFO: " + err.response.data.error.more_info.join(" ,").split(",").join("\n"))
      } else {
        console.log(errorMessage + " More info not available.")
      }
    } else {
      errorMessage = err.stack
    }

    this.setState({
      loading: false,
      selections: [],
      errorState: true,
      addingToCart: false,
      errorMessage: errorMessage
    })
  }

  render() {
    let logoSrcSet = `${logo500} 500w, ${logo800} 800w, ${logo1080} 1080w, ${logoBlack} 1200w`

    if (this.state.errorState) {
    return (
      <div>
        <div className="ForgotPassword" style={{}}>
          <img alt="musea logo"
            srcset={logoSrcSet}
            sizes="144px"
            className="image"
            src={logoBlack}
            style={{width: "144"}}
            />

            <div className="w-form-fail" style={{
        textAlign: 'center',
        marginTop: '50px',
        marginBottom: '20px',
        display: 'inline-block',
        width: '100%'
      }} >
              <div>
                Oops! Something went wrong while submitting the form.
                <br/>
                {this.state.errorMessage}
              </div>
            </div>

            <div className="text-block">
              <Link to="/forgot-password">
                <span style={{marginLeft: '0.5em', textDecoration: 'underline', cursor: 'pointer'}}>RELOAD PAGE</span>
              </Link>
            </div>

       </div>
      </div>
    );

    } else {
    return (
      <div>
        <div className="ForgotPassword" style={{}}>
          <img alt="musea logo"
            srcset={logoSrcSet}
            sizes="144px"
            className="image"
            src={logoBlack}
            style={{width: "144"}}
            />

          <div className="emailsentyoucan" style={{textAlign: 'center', marginTop: '60px'}}>
            Email sent!<br />You can reset your password by following the instructions in the email.
          </div>

          <div className="text-block">
            <Link to="/login">
              <span style={{marginLeft: '0.5em', textDecoration: 'underline', cursor: 'pointer'}}> BACK TO SIGN IN PAGE</span>
            </Link>
          </div>

        </div>
      </div>
    );
    }
  }
}

export default ForgotPasswordConfirm;
