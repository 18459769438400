import axios from "axios"

class Auth {
  // Function to add our give data into cache
  static addDataIntoCache(cacheName, url, response) {
    // Converting our response into Actual Response form
    const data = new Response(JSON.stringify(response));

    // if ('caches' in window) {
    //   caches.delete(cacheName)
    // }
    if ('caches' in window) {
      // Opening given cache and putting our data into it
      caches.open(cacheName).then((cache) => {
        cache.put(url, data);
        // alert("url added to cache! " + url)
      });
    }
  }

  static authenticateUser(token, intercom_user_hash, email, name, businessName) {
    //sessionStorage.setItem("token", token);
    localStorage.setItem("token", token);
    localStorage.setItem("intercom_user_hash", intercom_user_hash);
    localStorage.setItem("email", email);
    localStorage.setItem("name", name);
    localStorage.setItem("businessName", businessName);
  }

  static authenticateLoggedInAsUser(token, email, name, businessName) {
    //sessionStorage.setItem("token", token);
    localStorage.setItem("logged_in_as_token", token);
    localStorage.setItem("logged_in_as_email", email);
    localStorage.setItem("logged_in_as_name", name);
    localStorage.setItem("logged_in_as_businessName", businessName);
  }

  static isAuthenticated() {
    //return sessionStorage.getItem("token") !== null;
    return localStorage.getItem("token") !== null && localStorage.getItem("email") !== null && localStorage.getItem("intercom_user_hash") !== null;
  }

  static deauthenticateUser() {
    let token = this.getToken()

    let logged_in_as = localStorage.getItem("logged_in_as_token")
    if (logged_in_as && logged_in_as.length > 0) {
        axios
        .delete("/api/v2/logout", {headers: {token: logged_in_as} })
        .then(res => {
          localStorage.removeItem("logged_in_as_token");
          localStorage.removeItem("logged_in_as_email");
          localStorage.removeItem("logged_in_as_name");
          localStorage.removeItem("logged_in_as_businessName");
        })
        .then(res => {
          window.location.href = "/lab-orders"
        })

    } else {
      axios
      .delete("/api/v2/logout", {headers: {token: token} })
      .then(res => {
        localStorage.removeItem("token");
        localStorage.removeItem("intercom_user_hash");
        localStorage.removeItem("email");
        localStorage.removeItem("name");
        localStorage.removeItem("businessName");
      })
      }
  }

  static deauthenticateUserAll() {
    let token = this.getToken()
    //sessionStorage.removeItem("token");
    axios
    .delete("/api/v2/logout_all_sessions", {headers: {token: token} })
    .then(res => {
      localStorage.removeItem("token");
      localStorage.removeItem("intercom_user_hash");
      localStorage.removeItem("email");
      localStorage.removeItem("name");
      localStorage.removeItem("businessName");
    })
  }

  static getToken() {
    //return sessionStorage.getItem("token");
    let logged_in_as = localStorage.getItem("logged_in_as_token")
    if (logged_in_as && logged_in_as.length > 0) {
      return localStorage.getItem("logged_in_as_token");
    } else {
      return localStorage.getItem("token");
    }
  }

  static getIntercomUserHash() {
    return localStorage.getItem("intercom_user_hash");
  }

  static getLastCacheAt() {
    return localStorage.getItem("lastCacheCheck");
  }

  static setLastCacheCheck() {
    localStorage.setItem("lastCacheCheck", Date.now());
  }

  static getCachedClientVersion() {
    return localStorage.getItem("cachedClientVersion");
  }

  static setCachedClientVersion(clientVersion) {
    localStorage.setItem("cachedClientVersion", clientVersion);
  }

  static checkIfCacheCheckExpired() {
    var lastCachedClientVersion = localStorage.getItem("cachedClientVersion");
    var lastCacheCheck = localStorage.getItem("lastCacheCheck");
    if (lastCachedClientVersion !== null && lastCacheCheck !== null) {
      var timePassed = (Date.now() - lastCacheCheck) % 60
      //(currentTime - time) % 60
      if (timePassed > 60) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }


  //from https://reacttraining.com/react-router/web/example/auth-workflow
  //const fakeAuth = {
  //isAuthenticated: false,
  //authenticate(cb) {
  //this.isAuthenticated = true;
  //setTimeout(cb, 100); // fake async
  //},
  //signout(cb) {
  //this.isAuthenticated = false;
  //setTimeout(cb, 100);
  //}
  //};

  //const AuthButton = withRouter(
  //({ history }) =>
  //fakeAuth.isAuthenticated ? (
  //<p>
  //Welcome!{" "}
  //<button
  //onClick={() => {
  //fakeAuth.signout(() => history.push("/"));
  //}}
  //>
  //Sign out
  //</button>
  //</p>
  //) : (
  //<p>You are not logged in.</p>
  //)
  //);
}

export default Auth;
