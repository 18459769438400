import React, { Component } from "react";
import { Link } from "react-router-dom";

import noPhoto from "../img/no-photo.png";
import trashCan from "../img/trash_can.png";

import MuseaApi from "../modules/MuseaApi.js"
import Auth from "../modules/Auth.js"
import axios from "axios"
//import "../css/newWebflow.css";
//var noPhotoPath = `${process.env.PUBLIC_URL} + "../img/no-photo.png"`
//var mainPageCssPath = `${process.env.PUBLIC_URL} + "../css/mainPage.css"`
//import noPhoto from noPhotoPath
//import mainPageCssPath


class LabOrderCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      guid: this.props.guid,
      orderId: this.props.orderId,
      orderName: this.props.orderName,
      orderStatus: this.props.orderStatus,
      orderCreatedAt: this.props.orderCreatedAt,
      orderUpdatedAt: this.props.orderUpdatedAt,
      orderPaidAt: this.props.orderPaidAt,
      orderShippedAt: this.props.orderShippedAt,
      orderTrackingUrl: this.props.orderTrackingUrl,
      orderTrackingNumber: this.props.orderTrackingNumber,
      orderBoutiqueShippingNote: this.props.orderBoutiqueShippingNote,
      orderBoutiqueShippingPrice: this.props.orderBoutiqueShippingPrice,
      orderShippingService: this.props.orderShippingService,
      coverPhoto: this.props.coverPhoto,
      orderExternalId: this.props.orderExternalId,
      orderNotes: this.props.orderNotes,
      orderIsApi: this.props.orderIsApi,
      orderApiVendor: this.props.orderApiVendor
    };
  }

  handleSubmit(labOrderGuid) {
    if (window.confirm("WAIT! Deleting this order cannot be undone. Are you sure you want to delete this order permanently?")) {
      this.props.destroyLabOrder(labOrderGuid)
    } else {
      return
    }
  }

  render() {
    const {orderExternalId, guid, orderName, orderStatus, coverPhoto } = this.state
    let coverPhotoUrl, coverPhotoSrcSet;
    if (coverPhoto) {
      coverPhotoUrl = coverPhoto;
      coverPhotoSrcSet = `${coverPhotoUrl} 500w`
    } else {
      coverPhotoUrl = noPhoto;
      coverPhotoSrcSet = `${noPhoto} 500w`
    }

    let orderStatusSection
    if (orderStatus === "open") {
      orderStatusSection = <div> {orderStatus}
              <img alt="trash can"
              anima-src={trashCan}
              onClick={(e) => {
                e.preventDefault()
                this.handleSubmit(guid)
              }}
              className=""
              srcSet={trashCan}
              style={{maxHeight: '13px', marginLeft: '3px'}}
              src={trashCan} />
      </div>
    } else {
      orderStatusSection = <div> {orderStatus} </div>
    }

    return (
      <div style={{}}>
        <div className="w-clearfix">
          <span className="text-block-10" style={{width: '100%', display: 'inline-flex'}}>
          </span>
        </div>
          <div className="div-block" style={{height: '260px',
            textAlign: 'center',
            verticalAlign: 'middle' }}>
            <Link to={{
              pathname: `/lab-orders/${guid}`,
              state: {
                orderGuid: `${guid}`
              }
            }}>
              <img alt="cover"
              anima-src={coverPhotoUrl}
              className=""
              srcSet={coverPhotoSrcSet}
              sizes="(max-width: 479px) 125px, (max-width: 767px) 26vw, (max-width: 991px) 17vw, 20vw"
              style={{maxHeight: '260px'}}
              src={coverPhotoUrl} />
            </Link>
           </div>

            <div className="div-block-2">
              <div className="text-block-10">
                <div className="bold-text-2" style={{}}>
                  <span style={{display: 'inline-flex'}}>
                    <strong> {orderName} </strong>
                  </span>


                  <br/>

                    {orderExternalId}

                  <br/>

                    {orderStatusSection}

                </div>

              </div>

            </div>
        </div>
    )
  }
}

export default LabOrderCard;
