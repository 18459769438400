import React, { Component } from "react";
import CopyToClipboard from 'react-copy-to-clipboard';
import OrderSummaryInfoAdmin from "./OrderSummaryInfoAdmin.js";

import axios from "axios";
import Auth from "../modules/Auth.js";
import MuseaApi from "../modules/MuseaApi.js"
import { Link } from "react-router-dom";

class OrderSummaryAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labOrder: this.props.labOrder,
      shippingAddress: this.props.shippingAddress,
      isOrderUrgent: this.props.labOrder['urgent']
    };

    this.throwError = this.throwError.bind(this)
  }

  movedTextToClipboard(shippingInfo) {
    alert("Copied address to clipboard: \n" + shippingInfo)
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        labOrder: nextProps.labOrder,
        shippingAddress: nextProps.shippingAddress
      })
    }
  }

  getLabOrder = (e, data) => {
    //e.preventDefault();

    var token = Auth.getToken()
    var guid = this.props.labOrder.guid
    console.log("getting lab order again: " + guid)
    axios
      .get(`api/v2/lab_orders/${guid}`, { headers: { 'token': token } })
      .then(res => {

        console.log("res " + res.data['data']);
        let museaApi = new MuseaApi()
        var parsedOrder = museaApi.mergeAttributesForLabOrder(res.data);

        let currentUserAdmin, currentUserApiVendor, viewAsPhotographerId, viewingAsPhotographer
        if (res.data.meta) {
          currentUserAdmin = res.data.meta.current_user_is_admin
          currentUserApiVendor = res.data.meta.current_user_is_api_vendor
          viewAsPhotographerId = res.data.meta.view_as_photographer_id
          viewingAsPhotographer = res.data.meta.view_as_photographer
        } else {
          currentUserAdmin = false
          currentUserApiVendor = false
          viewAsPhotographerId = null
          viewingAsPhotographer = null
        }

        console.log("setting shipping address " + JSON.stringify(parsedOrder.shipping_address))
        console.log("setting labOrder " + JSON.stringify(parsedOrder))
        this.setState({
          shippingAddress: parsedOrder.shipping_address,
          retrievedLabOrderSecondTime: true
        });
        return true;
      })
      .catch(err => {
        //this.throwError(err)
        console.log(err)
        //let error = JSON.parse(err.response)
        //this.throwError(error)
      })
  };

  formatOrderDate(labOrder) {
    let options = { hour: '2-digit', minute: '2-digit' };
    let rawParsedDate = new Date(Date.parse(labOrder.paid_at)).toLocaleDateString("en-US", options)
    let parsedDate = rawParsedDate.toUpperCase()
    return parsedDate
  }


  throwError(err) {
    let errorMessage, errorObject, stackTrace
    if (err.response && err.response.status === 401) {
      this.setState({
        userLoggedOut: true
      })
      return false
    } else {
      if (err.response != null) {
        errorObject = err.response.data.error
        if (errorObject != null) {
          errorMessage = "\nERROR CODE: " + errorObject.status + "\n ERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.developer_message
          if (err.response.data.error.more_info != null) {
            this.honeybadger.notify(err)
            alert("ERROR MESSAGE: " + errorMessage)
            console.log("ERROR MESSAGE: " + errorMessage + "\nMORE INFO: " + err.response.data.error.more_info.join(" ,").split(",").join("\n"))
          } else {
            this.honeybadger.notify(err)
            alert("ERROR MESSAGE: " + errorMessage)
            console.log(errorMessage + " More info not available.")
          }
        } else {
          errorMessage = "\nERROR CODE: " + err.response
        }
      } else {
        // this.state.honeybadger.notify(err)
        this.honeybadger.notify(err)
        alert("ERROR MESSAGE: " + err.stack)
        errorMessage = err.stack
      }
    }

    this.setState({
      loading: false,
      query: "",
      updatingUser: false,
      errorState: true,
      errorMessage: errorMessage
    })
  }


  toggleOrderImporance(e) {

    var guid = this.props.labOrder.guid
    var token = Auth.getToken()
    var isUrgent = this.state.isOrderUrgent

    if (isUrgent == null || isUrgent == false) {
      isUrgent = true;
    } else {
      isUrgent = false;
    }

    axios.patch(`/api/v2/lab_orders/${guid}`, {
      "type": "urgent",
      "value": isUrgent
    }, { headers: { 'token': token } })
      .then((res) => {
        console.log("This is important", res.data.data['attributes']['urgent']);
        this.setState({
          isOrderUrgent: res.data.data['attributes']['urgent']
        });
        
      }
      ).catch((err) => this.throwError(err))
  }

  render() {
    const { labOrder, shippingAddress,isOrderUrgent } = this.state
    let formattedDate = this.formatOrderDate(labOrder)
    console.log("shippingAddress is " + JSON.stringify(shippingAddress))

    //UPDATED ON 5/23/18 AT 5:45PM
    let labOrderCity, labOrderStreet, labOrderPhoneNumber, labOrderEmail, shippingInfo, shippingString, toggleShipValue

    if (labOrder != null) {

      if (labOrder && shippingAddress) {
        labOrderCity = "" + shippingAddress.city + ", " + shippingAddress.state_province + " " + shippingAddress.zip
      } else {
        labOrderCity = ""
      }

      if (labOrder && labOrder.guid !== null && shippingAddress && shippingAddress.street) {
        let streetTwo
        if (shippingAddress.street_2 !== null && shippingAddress.street_2.length > 1) {
          streetTwo = shippingAddress.street_2
        } else {
          streetTwo = ""
        }
        labOrderStreet = "" + shippingAddress.street + " " + streetTwo
        labOrderPhoneNumber = shippingAddress.phone_number
      } else {
        labOrderStreet = <div>
          <a href="#"
            onClick={(e) => { this.props.bustLabOrderCache(e) }}
            style={{ cursor: 'pointer', color: 'blue' }}> RELOAD FOR ADDRESS </a>
        </div>
        labOrderPhoneNumber = ""
        if (this.state.retrievedLabOrderSecondTime) {
          console.log("already got order")
        } else {
          this.getLabOrder()
        }
      }

      if (shippingAddress && shippingAddress.email && shippingAddress.email.length > 1) {
        labOrderEmail = shippingAddress.email
      } else {
        labOrderEmail = labOrder.photographer_email
      }

      let labOrderRecipient
      if (labOrder && shippingAddress && shippingAddress.recipient && shippingAddress.recipient.length > 0) {
        labOrderRecipient = shippingAddress.recipient
      } else if (labOrder && labOrder.client_name && labOrder.client_name.length > 0) {
        labOrderRecipient = labOrder.client_name
      } else {
        labOrderRecipient = labOrder.photographer_name
      }
      shippingInfo = <div className="orderSummaryShippingAddress">

        {labOrderRecipient}
        <br />
        {labOrderStreet}
        <br />
        {labOrderCity}
      </div>

      shippingString = `${labOrderRecipient}` + "\n" + `${labOrderStreet}` + "\n" + `${labOrderCity}`

    } else {
      shippingInfo = <div className="orderSummaryShippingAddress">
        NONE SELECTED
            <br />
        <br />
      </div>
      shippingString = ""
    }

    if (this.props.showShippingForm) {
      toggleShipValue = "HIDE"
    } else {
      toggleShipValue = "EDIT"
    }

    let shippingMethod
    if (labOrder.shipping_method === "two_day" || labOrder.shipping_method == "overnight") {
      shippingMethod = <div style={{ color: 'red', fontWeight: '800' }}>SHIPPING INFO: ({labOrder.shipping_method})</div>
    } else {

      shippingMethod = <div>SHIPPING INFO</div>
    }

    //ORDER NUMBER: {labOrder.external_order_id}

    var orderFlag;
    if (isOrderUrgent == false || isOrderUrgent == null) {
      orderFlag = <div>
        <strong className="bold-text-5"> Flag order as important  </strong>
        <button className="w-button submit-button-8" style={{ display: "inline-block" }}
          onClick={(e) => { this.toggleOrderImporance(e) }}>Flag</button>
      </div>
    } else if (isOrderUrgent == true) {
      orderFlag = <div>
        <strong className="bold-text-5" style={{ color: "red" }}>This order has been flaged as important  </strong>
        <button className="w-button submit-button-8" style={{ display: "inline-block" }}
          onClick={(e) => { this.toggleOrderImporance(e) }}>Unflag</button>
      </div>
    }

    return (
      <div>
        <div className="container-15 w-container">
          <div className="columns-3 w-row">
            <div className="column-21 w-col w-col-3">
              <div className="text-block-120">
                {labOrder.order_name}
              </div>
              <div className="text-block-119">
                PAID AT {formattedDate}
              </div>
              <div className="text-block-121"><strong>
                ORDER NUMBER:
              </strong>
                <br />
                {labOrder.external_order_id}
              </div>
            </div>
            <div className="column-11 w-col w-col-3">
              <div className="text-block-122">PHOTOGRAPHER INFO</div>
              <div className="text-block-123">
                photographer name: {labOrder.photographer_name}
                <br />
                photographer business: {labOrder.photographer_business}
                <br />
                phone: {labOrderPhoneNumber}
                <br />
                email: {labOrderEmail}
                <br />
                <Link to={{ pathname: `/photographers/${labOrder.photographer_id}` }}>
                  View Photographer Profile
                </Link>
              </div>
            </div>
            <div className="w-col w-col-3">
              <div className="text-block-126">{shippingMethod}</div>
              <div id="shippingInfoObject" className="text-block-124">
                {shippingInfo}
              </div>

              <div style={{ display: 'inline-flex' }}
                className="text-block-125">
                <a href="#"
                  onClick={(e) => { this.props.toggleShippingAddressForm(e, shippingInfo) }}
                  style={{
                    cursor: 'pointer', color: 'blue',
                    marginLeft: '1em'
                  }}> {toggleShipValue} </a>

                <CopyToClipboard text={shippingString}
                  onCopy={() => this.movedTextToClipboard(shippingString)}>
                  <div style={{ cursor: 'pointer', color: 'blue', marginLeft: '2.5em' }} >COPY</div>
                </CopyToClipboard>

              </div>
            </div>

            <div className="w-col w-col-3">
              <div className="w-row">
                <div className="w-col w-col-6">
                  <div className="text-block-118">SUBTOTAL</div>
                  <div className="text-block-118">DISCOUNT</div>
                  <div className="text-block-127">CREDIT</div>
                  <div className="text-block-128">SHIPPING</div>
                  <div><strong className="bold-text-11">TOTAL</strong></div>
                </div>
                <div className="w-col w-col-6">
                  <div className="text-block-117">
                    ${parseFloat(+labOrder.subtotal).toFixed(2)}
                  </div>
                  <div className="text-block-117">
                    ${parseFloat(+labOrder.discount).toFixed(2)}
                  </div>
                  <div className="text-block-116">
                    ${parseFloat(+labOrder.credit_applied).toFixed(2)}
                  </div>
                  <div className="text-block-129">
                    ${parseFloat(+labOrder.shipping_total).toFixed(2)}
                  </div>
                  <div className="text-block-115">
                    ${parseFloat(+labOrder.total).toFixed(2)}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <OrderSummaryInfoAdmin showShippingAddressForm={this.props.showShippingForm} sendToApi={this.props.sendToApi} createDownloadAllLink={this.props.createDownloadAllLink} refundLabOrder={this.props.refundLabOrder} updateLabOrder={this.props.updateLabOrder} key={labOrder.guid} labOrder={labOrder} trackingUrl={labOrder.tracking_url} />

      </div >
    );
  }
}

export default OrderSummaryAdmin;

