import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
//import logo from "./img/sign-up-1-musea-colorwheel.png";
import Auth from "./modules/Auth";
import axios from "axios";
import validatedCheck from "./img/sign-up-1-v2-baselinecheckcircleblack48dp-copy-green@2x.png";
import checkBox from "./img/sign-up-1-v2-baselinecheckboxblack48dp@2x.png";
import emptyCheckBox from "./img/checkout-20-rectangle-18.png";

//import logo from "./img/sign-in-page-musea-colorwheel@2x.png";
import logo1080 from "./img/MUSEAbadge-black-p-1080.jpeg";
import logo500 from "./img/MUSEAbadge-black-p-500.jpeg"
import logo800 from "./img/MUSEAbadge-black-p-800.jpeg"
import logoBlack from "./img/MUSEAbadge-black.jpg"

//import "./css/signInWebflow.css";

class SignUpOne extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      firstName: '',
      lastName: '',
      agreeToTerms: false,
      userCreatedSuccessfully: false,
      errorState: false,
      emailError: false,
      passwordError: false,
      passwordsMatch: false
    };
   this.validatePasswords = this.validatePasswords.bind(this);
   this.validateEmail = this.validateEmail.bind(this);
   this.emailValid = this.emailValid.bind(this);
   this.toggleAgreeToTerms = this.toggleAgreeToTerms.bind(this);
   this.throwError = this.throwError.bind(this)
   this.handleChange = this.handleChange.bind(this)
   this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {

  }

  handleChange(e) {
    const name = e.target.name;
    const val = e.target.value;
    if (name === "passwordConfirmation") {
      this.validatePasswords(val)
    } else if (name === "email" && this.emailValid(val)) {
      this.validateEmail(val)
    } else {
      this.setState({
        [name]: val
      });
    }
    //console.log(this.state);
  }

  emailValid(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  toggleAgreeToTerms(e) {
    if (this.state.agreeToTerms) {
       this.setState({
         agreeToTerms: false
       });
    } else {
       this.setState({
         agreeToTerms: true
       });
    }
  }

  validateEmail(email) {
    axios
    .post('/api/v2/check_for_email',{email: email}, {headers: {}})
         .then(res => {
           //console.log(result);
           this.setState({
             emailError: true
           })
         })
         .catch(err =>
          //console.log(result);
          this.setState({
            emailError: false,
            email: email
          })
         )
  }

  validatePasswords(passwordConfirmation) {
    if (this.state.password.length >= 8 && this.state.password === passwordConfirmation) {
    console.log("match")
    this.setState({
      passwordConfirmation: passwordConfirmation,
      passwordError: false,
      passwordsMatch: true
    });
    return
    } else {
    this.setState({
      passwordConfirmation: passwordConfirmation,
      passwordError: true,
      passwordsMatch: false
    });
    return
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    axios
    .post('/api/v2/users',
          {email: this.state.email,
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            website: this.state.website,
            password: this.state.password,
            password_confirmation: this.state.passwordConfirmation
          }, {})
          .then(res => {
            var token = res.data.token;
            var intercom_user_hash = res.data.intercom_user_hash;
            var email = res.data.email;
            var name = res.data.name;
            var businessName = res.data.business_name;
            Auth.authenticateUser(token, intercom_user_hash, email, name, businessName);
            this.setState({
              userCreatedSuccessfully: true
            })
          })
          .catch(err =>
                 this.throwError(err))
  }

  throwError(err) {
    let errorObject = err.response.data.error
    let errorMessage = "\nERROR CODE: " + errorObject.status + "\n ERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.developer_message
    console.log("ERROR MESSAGE: " + errorMessage + "\n MORE INFO: " + err.response.data.error.more_info.join(" ,").split(",").join("\n"))
    //alert(errorMessage)

    this.setState({
      loading: false,
      query: "",
      errorState: true,
      errorMessage: errorMessage
    })
  }

  render() {

    let logoSrcSet = `${logo500} 500w, ${logo800} 800w, ${logo1080} 1080w, ${logoBlack} 1200w`
    let createUserButton, agreeInputBox, emailErrorField, emailValidImage, emailValidColor, firstNameErrorField, lastNameErrorField, passwordErrorField,passwordValidImage
    if (this.state.userCreatedSuccessfully) {
      return <Redirect push to={{ pathname: "/signup/2-of-3", state: {} }} />; 
    }

    if (this.state.email.length > 0 && this.state.firstName.length > 0 && this.state.lastName.length > 0 && this.state.agreeToTerms && this.state.passwordsMatch) {
      createUserButton = <input type="submit"
                  onClick={(e) => {this.handleSubmit(e)}}
                  className="button w-button"
                  style={{width: '50%', color: 'white', backgroundColor: 'black', marginTop: '30px'}}
                  value="NEXT"/>
    } else {
      createUserButton = <input type="submit"
                  className="button w-button"
                  disabled
                  style={{width: '50%', color: 'white', backgroundColor: 'black', marginTop: '30px', opacity: '0.5'}}
                  value="NEXT"/>
    }

    if (this.state.agreeToTerms) {
      agreeInputBox = <img anima-src={checkBox}
                  style={{marginLeft: '17px', height: '23px', verticalAlign: 'middle' }}
                  onClick={(e) => {this.toggleAgreeToTerms(e)}}
                  src={checkBox}
                  alt="check box"
                  />
    } else {
      //agreeInputBox = <div

                  //style={{marginLeft: '17px', height: '18px', width: '18px', verticalAlign: 'middle'}}
                  //onClick={(e) => {this.toggleAgreeToTerms(e)}}
                  //className="baselinecheckboxblack48dp" />

      agreeInputBox = <img anima-src={emptyCheckBox}
                  style={{marginLeft: '17px', height: '17px', verticalAlign: 'middle' }}
                  onClick={(e) => {this.toggleAgreeToTerms(e)}}
                  src={emptyCheckBox}
                  alt="check box"
                  />
    }

    if (this.state.emailError) {
      emailErrorField = <div>
        <p className="w-form-fail" name="emailError" style={{display: 'inline-block', marginTop: '5px'}}>
          Email already in use. Please login or reset your password.
        </p>
      </div>
      emailValidImage = ""
    }  else {
      emailErrorField = <div></div>
    }

    if (!this.state.emailError && this.emailValid(this.state.email)) {
      emailValidImage = "url('" + validatedCheck + "')"
      emailValidColor = 'green'
    } else {
      emailValidImage = ""
      emailValidColor = 'red'
    }

    if (this.state.passwordError) {
      passwordErrorField = <div>
         <p className="w-form-fail" name="passwordMatchError" style={{display:'inline-block', marginTop: '5px'}}>
                  Password fields must match and be at least 8 characters long.
                </p>
      </div>
      passwordValidImage = ""
    } else {
      passwordErrorField = <div> </div>
    }

    if (!this.state.passwordError && this.state.passwordsMatch) {
      passwordValidImage = "url('" + validatedCheck + "')"
    } else {
      passwordValidImage = ""
    }

    if (this.state.errorState) {

    return (
      <div>
        <div className="SignUp">
          <img alt="musea logo"
            srcSet={logoSrcSet}
            sizes="144px"
            className="image"
            src={logoBlack}
            style={{width: "144"}}
            />

            <div className="w-form-fail" style={{
        textAlign: 'center',
        marginTop: '50px',
        marginBottom: '20px',
        display: 'inline-block',
        width: '100%'
      }} >
              <div>
                Oops! Something went wrong while submitting the form.
                <br/>
                {this.state.errorMessage}
              </div>
            </div>

            <div className="text-block">
              <Link to="/signup/1-of-3">
                <span style={{marginLeft: '0.5em', textDecoration: 'underline', cursor: 'pointer'}}>RELOAD PAGE</span>
              </Link>
            </div>

       </div>
      </div>
    );
    } else {

    return (

      <div>
        <div className="SignUp">
          <img alt="musea logo"
            srcSet={logoSrcSet}
            sizes="144px"
            className="image"
            src={logoBlack}
            style={{width: "144"}}
            />

          <div className="text-block-19">
            <strong>SIGN UP<br/></strong>
              <br/>
            <strong>1</strong> • 2 • 3
          </div>

        <div className="form-block w-form">
          <div id="wf-form-SIGN-IN-FORM" name="wf-form-SIGN-IN-FORM" data-name="SIGN IN FORM" method="get" className="form">
            <div className="firstName">

              <input type="text"
                className="text-field-2 w-input"
                name="firstName"
                onChange={this.handleChange}
                placeholder="First Name"/>

                {firstNameErrorField}
            </div>

            <div className="lastName">
              <input type="text"
                className="text-field-2 w-input"
                name="lastName"
                onChange={this.handleChange}
                placeholder="Last Name"/>

                {lastNameErrorField}
            </div>

            <div className="E-Mail">
              <input type="text"
                className="text-field-2 w-input"
                name="email"
                onChange={this.handleChange}
                style={{
                  background: emailValidImage + ' 98% 50% /20px no-repeat',
                  color: emailValidColor }}
                placeholder="E-Mail"/>

                {emailErrorField}
            </div>

            <div className="website">

              <input type="text"
                className="text-field-2 w-input"
                name="website"
                onChange={this.handleChange}
                placeholder="Website or Social Media URL"/>

            </div>

            <div className="password">
              <input type="password"
                className="text-field-2 w-input"
                name="password"
                onChange={this.handleChange}
                placeholder="Password"/>
            </div>

            <div className="passwordConfirmation">

              <input type="password"
                className="text-field-2 w-input"
                name="passwordConfirmation"
                onChange={this.handleChange}
                style={{ background: passwordValidImage + ' 98% 50% /20px no-repeat' }}
                placeholder="Confirm Password"/>

                {passwordErrorField}

            </div>



            <div className="termsAgree" style={{textAlign: 'center'}}>

              <div className="agree">
                <div className="iagreetotermsand"
                  style={{display: 'inline',
                    marginTop: '1.18%'}} >

                  I AGREE TO

                  <a
                    style={{textDecoration: 'underline',
                      marginLeft: '3px'}}
                    href="https://musealab.com/terms-conditions/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="iagreetotermsand"
                  >
                    TERMS AND CONDITIONS
                  </a>

                  {agreeInputBox}


                </div>
                <br/>
                <br/>
                <span>
                  By entering your email, you consent to receive
                  email updates from MUSEA.
                </span>
              </div>

            </div>

            {createUserButton}
          </div>
        </div>

        </div>
      </div>

    );
    }



  }
}
export default SignUpOne;
