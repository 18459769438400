import React, { Component } from "react";
import styled, { keyframes } from 'styled-components';

import { fadeIn, bounce, flash } from 'react-animations'
import { Link } from "react-router-dom"
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class AddToCartSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      guid: this.props.guid,
      images: this.props.labOrder.images,
      lineItems: this.props.labOrder.lineItems,
      lineItemCount: this.props.labOrder.lineItems.length,
      lineItemGuid: this.props.lineItemGuid,
      inCart: this.props.labOrder.images_in_cart,
      labOrder: this.props.labOrder,
      subtotal: this.props.subtotal,
      printSizePricing: "",
      printBorder: "",
      selections: this.props.selections,
      selectedPhotoForNotes: this.props.selectedPhotoForNotes,
      newlyActive: this.props.newlyActive,
      photoFileName: this.props.photoFileName,
      notesForPhotos: {},
      addingToCart: false,
      errorState: false,
      cropNotificationModalStatus: false
    };
    this.openCropNotification = this.openCropNotification.bind(this);
    this.acceptCrop = this.acceptCrop.bind(this);
    this.refuseCrop = this.refuseCrop.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.innerHtml = "";
    document.body.appendChild(script);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      // console.log("nextProps " + nextProps)
      // console.log("nextProps lineItemCount " + nextProps.lineItemCount)
      this.setState({
        selections: nextProps.selections,
        printSizePricing: nextProps.printSizePricing,
        newlyActive: nextProps.newlyActive,
        photoFileName: nextProps.photoFileName,
        printBorder: nextProps.printBorder,
        finishingCategory: nextProps.finishingCategory,
        finishingPricing: nextProps.finishingPricing,
        finishingDetailPricing: nextProps.finishingDetailPricing,
        selectedPhotoForNotes: nextProps.selectedPhotoForNotes,
        cropOptions: nextProps.cropOptions,
        labOrder: nextProps.labOrder,
        lineItemCount: nextProps.lineItemCount,
        lineItems: nextProps.labOrder.lineItems,
        subtotal: nextProps.subtotal
      });
    }
  }

  handleChange(e) {
    //const name = e.target.name;

    const photoId = this.state.selectedPhotoForNotes
    const val = e.target.value;
    let notesForPhotos = this.state.notesForPhotos

    notesForPhotos[photoId] = val

    // console.log("photoId for notes: " + photoId)
    this.props.updatePricingSelection(notesForPhotos, "notesForPhotos")
    this.setState({
      notesForPhotos: notesForPhotos,
      currentNotes: val
    })
    //addNotesToLineItems(notesForPhotos)

    //this.setState({
    //[name]: val
    //});
    //console.log(this.state);
  }
  openCropNotification() {
    this.setState({
      cropNotificationModalStatus: true
    })
  }
  refuseCrop() {
    this.setState({ cropNotificationModalStatus: false })
  };
  acceptCrop(lineItemGuid) {
    this.setState({ cropNotificationModalStatus: false })
    this.props.addItemsToCart(lineItemGuid)
  };

  handleSubmit(lineItemGuid) {
    // console.log("handleSubmit", lineItemGuid);
    if (this.state.lineItems.length < 1 || (this.state.lineItemCount && this.state.lineItemCount < 1)) {
      this.openCropNotification()
    } else {
      this.props.addItemsToCart(lineItemGuid)
    }
  }


  unsetNewlyActive(e) {
    e.preventDefault()
    if (this.state.newlyActive) {
      this.props.updatePricingSelection(false, "newlyActiveNotesField")
      //this.setState({
      //newlyActive: false
      //})
    }
  }

  render() {
    //addingToCart,currentNotes,selections, printSizePricing, printBorder, finishingCategory, finishingPricing, finishingDetailPricing, addToCartButtons, checkoutButtons, photoNotesArea, lineItems, 
    const { lineItemGuid, selections, selectedPhotoForNotes, photoFileName, finishingCategory, finishingPricing, finishingDetailPricing, printSizePricing, currentNotes, lineItemCount, notesForPhotos, labOrder, subtotal, newlyActive, editing } = this.state
    //let photoFileName, selectedPhotoForNotes
    //if (this.state.selectedPhotoForNotes && this.state.selectedPhotoForNotes.length > 0) {
    //selectedPhotoForNotes = this.state.selectedPhotoForNotes
    //photoFileName = this.state.photoFileName
    //} else {
    //selectedPhotoForNotes = "ALL"
    //photoFileName = "ALL PHOTOS"
    //}

    //console.log("labOrder subtotal " + labOrder.subtotal)
    let notesField, notesHeader

    //console.log("state subtotal " + subtotal)
    //console.log("props labOrder subtotal " + this.props.labOrder.subtotal)
    //console.log("props subtotal " + this.props.subtotal)
    let subtotalParsed
    if (this.props.subtotal != null) {
      subtotalParsed = parseFloat(+this.props.subtotal).toFixed(2)
    } else if (subtotal != null) {
      subtotalParsed = parseFloat(+subtotal).toFixed(2)
    } else {
      subtotalParsed = 0.00
    }

    let notesFieldValue
    if (selectedPhotoForNotes && selectedPhotoForNotes.length > 0) {
      //console.log("selected photo is " + selectedPhotoForNotes)
      //console.log("current notes is " + currentNotes)
      notesFieldValue = notesForPhotos[selectedPhotoForNotes]
    }

    if (notesForPhotos && selectedPhotoForNotes && selectedPhotoForNotes.length > 0 && notesForPhotos[selectedPhotoForNotes] && notesForPhotos[selectedPhotoForNotes].length > 0) {
      notesFieldValue = notesForPhotos[selectedPhotoForNotes]
    } else {
      notesFieldValue = ""
    }

    let addToCartButtonValue

    if (lineItemGuid && lineItemGuid.length > 0) {
      addToCartButtonValue = "UPDATE"
    } else {
      addToCartButtonValue = "ADD TO CART"
    }

    let addToCartButton
    if (this.props.hideAddToCartButton || printSizePricing.length < 1) {
      addToCartButton = <div></div>
    } else if (finishingCategory.toUpperCase() !== "NONE" && finishingCategory.length > 1 && finishingPricing === null || finishingCategory.toUpperCase() !== "NONE" && finishingCategory.length > 1 && finishingPricing.length < 1 || finishingCategory === "hardwood_frame" && finishingDetailPricing === null || finishingCategory === "hardwood_frame" && finishingDetailPricing.length < 1 || finishingCategory === "hardwood_float_canvas_frame" && finishingDetailPricing === null || finishingCategory === "hardwood_float_canvas_frame" && finishingDetailPricing.length < 1 || finishingCategory === "mount" && finishingDetailPricing === null || finishingCategory === "mount" && finishingDetailPricing.length < 1) {
      addToCartButton = <a style={{ color: 'white' }} onClick={() => { alert("To add this item to your cart, please select all finishing service details like mat size, mount size and color, or frame size, profile, acrylic and stain. You can set finishing category to NONE to add to cart.") }} className="button-45 w-button"> {addToCartButtonValue} </a>
    } else {
      addToCartButton =
        <a style={{ color: 'white' }} onClick={
          (e) => {
            console.log("LineItem GUID", lineItemGuid)
            e.preventDefault();
            this.handleSubmit(lineItemGuid)
          }} className="button-45 w-button">
          {addToCartButtonValue} </a>
    }
    // let reviewCartButton = <Link to={{
    //   pathname: `/cart-preview/${this.state.guid}`,
    //   state: {
    //     orderGuid: `${this.state.guid}`,
    //     labOrder: { labOrder }
    //   }
    // }}
    //   className="button-46 w-button"
    //   style={{ color: 'black' }} >
    //   REVIEW CROPPED ({lineItemCount})
    //     </Link>
    let viewCartButton = <Link to={{
      pathname: `/lab-orders/${this.state.guid}/review`,
      state: {
        orderGuid: `${this.state.guid}`,
        labOrder: { labOrder }
      }
    }}
      className="button-46 w-button"
      style={{ color: 'black' }} >
      VIEW CART ({lineItemCount})
        </Link>

    let checkoutButton
    if (this.props.checkoutButton) {
      checkoutButton = <Link to={{
        pathname: `/lab-orders/${this.state.guid}/checkout-details`,
        state: {
          orderGuid: `${this.state.guid}`,
          labOrder: `${this.state.labOrder}`
        }
      }}
        style={{ color: 'white' }}
        className="button-47 w-button"
      >
        CHECKOUT
          </Link>
    } else {
      checkoutButton = <div></div>
    }

    if (newlyActive) {
      const bounceAnimation = keyframes`${flash}`;
      const BouncyDiv = styled.div`
       animation: 1s ${bounceAnimation};
       `;
      notesHeader = <div className="text-block-158">PHOTO NOTES</div>
      notesField = <BouncyDiv>
        <textarea id="field"
          style={{ border: '2px solid lightblue' }}
          name="lineItemNotes"
          value={notesFieldValue}
          onClick={(e) => { this.unsetNewlyActive(e) }}
          placeholder="Enter any order notes you have..."
          maxLength="5000"
          className="w-input">

        </textarea>
        <div style={{ textAlign: 'left' }}>
          Click pencil under photo to begin editing notes.
            </div>
      </BouncyDiv>
    } else if ((photoFileName == "ALL PHOTOS") || (selections && selections.length > 1)) {
      notesHeader = <div className="text-block-158">PHOTO NOTES</div>
      notesField = <div>
        <textarea id="field"
          style={{ border: '2px solid lightblue' }}
          name="lineItemNotes"
          value={notesFieldValue}
          onChange={this.handleChange}
          placeholder="Enter any order notes you have..."
          maxLength="5000"
          className="w-input">

        </textarea>
        <div style={{ textAlign: 'left' }}>
          Editing notes for: {photoFileName}
        </div>
      </div>
    } else if (!selections || selections.length < 1) {
      notesHeader = <div></div>
      notesField = <div style={{ marginTop: '350px' }}></div>
    } else {
      notesHeader = <div className="text-block-158">PHOTO NOTES</div>
      notesField = <textarea id="field"
        name="lineItemNotes"
        value={notesFieldValue}
        onChange={this.handleChange}
        placeholder="Enter any order notes you have..."
        maxLength="5000"
        className="w-input">
      </textarea>
    }

    let cropNotificationModal =
      <Dialog
        open={this.state.cropNotificationModalStatus}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            WAIT! Before adding to cart, check your cropping selections on all photos.
      </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.refuseCrop()} color="secondary">
            Check Crop
      </Button>
          <Button onClick={() => this.acceptCrop(lineItemGuid)} color="primary">
            Looks Good
      </Button>
        </DialogActions>
      </Dialog>

    return (

      <div className="w-col w-col-2">
        {notesHeader}
        <div className="w-form">
          <form id="email-form" name="email-form" data-name="Email Form">

            {notesField}
            {addToCartButton}
            {viewCartButton}
          </form>
        </div>
        <div className="text-block-159">SUBTOTAL ${subtotalParsed}</div>
        {checkoutButton}
        {cropNotificationModal}
      </div>



    )
  }
}

export default AddToCartSidebar;
