import React, { Component } from "react";

class AccountInfoForm extends Component {
  constructor() {
    super();
    this.state = {
      firstName: '',
      lastName: '',
      phone: '',
      businessName: '',
      errorState: false
    };
   this.throwError = this.throwError.bind(this)
   this.handleChange = this.handleChange.bind(this)
   this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {

  }

  handleChange(e) {
    const name = e.target.name;
    const val = e.target.value;
    this.setState({
      [name]: val
    });
    //console.log(this.state);
  }

  handleSubmit(e) {
    e.preventDefault()
  }

  throwError(err) {
    let errorObject = err.response.data.error
    let errorMessage = "\nERROR CODE: " + errorObject.status + "\n ERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.developer_message
    console.log("ERROR MESSAGE: " + errorMessage + "\n MORE INFO: " + err.response.data.error.more_info.join(" ,").split(",").join("\n"))
    //alert(errorMessage)

    this.setState({
      loading: false,
      query: "",
      errorState: true,
      updatingAccount: false,
      errorMessage: errorMessage
    })
  }

  render() {
    let updateUserButton, firstNameValue, lastNameValue, businessNameValue, phoneValue
    if (this.state.firstName.length > 0 || this.state.lastName.length > 0 ||this.state.businessName.length > 0 || this.state.phone.length > 0) {
      updateUserButton = <div>
                <input type="submit"
                  onClick={(e) => {this.props.updateUser(this.state.firstName, this.state.lastName, this.state.businessName, this.state.phone ) }}
                  className="button-11 w-button"
                  value="UPDATE"/>
                </div>
    } else {
      updateUserButton = <div>
                <input type="submit"
                  className="button-11 w-button"
                  disabled
                  style={{opacity: '0.5'}}
                  value="UPDATE"/>
                </div>
    }

    if (!this.props.firstName.length > 0) {
      firstNameValue = "FIRST NAME"
    } else {
      firstNameValue = this.props.firstName
    }

    if (!this.props.lastName.length > 0) {
      lastNameValue = "LAST NAME"
    } else {
      lastNameValue = this.props.lastName
    }

    if (this.props.businessName == null || !this.props.businessName.length > 0) {
      businessNameValue = "BUSINESS NAME"
    } else {
      businessNameValue = this.props.businessName
    }

    if (this.props.phone == null || !this.props.phone.length > 0) {
      phoneValue = "PHONE"
    } else {
      phoneValue = this.props.phone
    }


    return (
      <div className="w-form">
        <div className="form-3">
          <input type="text"
            className="text-field-4 w-input"
            name="firstName"
            onChange={this.handleChange}
            placeholder={firstNameValue} />

          <input type="text"
            className="text-field-5 w-input"
            name="lastName"
            onChange={this.handleChange}
            placeholder={lastNameValue} />

          <input type="text"
            className="text-field-6 w-input"
            name="businessName"
            onChange={this.handleChange}
            placeholder={businessNameValue} />

          <input type="text"
            className="text-field-7 w-input"
            name="phone"
            pattern="[0-9]*"
            onChange={this.handleChange}
            placeholder={phoneValue} />

          {updateUserButton}

        </div>
      </div>
    )
  }
}

export default AccountInfoForm;
