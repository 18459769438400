import React, { Component } from "react";

class BoutiquePackagingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      review: true,
      boutiqueShippingNote: this.props.boutiqueNote,
      labOrderHasBoutiqueShipping: this.props.boutiqueShippingSelection ? this.props.boutiqueShippingSelection : false,
      boutiqueShipping: this.props.boutiqueShippingSelection ? this.props.boutiqueShippingSelection : false,
    };
   this.handleChange = this.handleChange.bind(this)
   this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(e) {
    const name = e.target.name;
    const val = e.target.value;
    this.setState({
      [name]: val
    });
  }

  handleSubmit(e) {
    e.preventDefault()
    if (this.state.boutiqueShipping && this.state.labOrderHasBoutiqueShipping) {
      this.props.updateBoutiqueOnLabOrder("removeBoutique", "")
    } else {
      this.props.updateBoutiqueOnLabOrder("addBoutique", this.state.boutiqueShippingNote)
    }
    //this.props.updateLabOrderPackaging("addBoutique")
  }


  toggleBoutiqueShippingForm(e) {
    e.preventDefault()
    let boutiqueShippingOption
    if (this.state.boutiqueShipping) {
      if (this.state.labOrderHasBoutiqueShipping) {
        this.handleSubmit(e)
      } else {
        boutiqueShippingOption = false
      }
    } else {
      boutiqueShippingOption = true
    }
    this.setState({
      boutiqueShipping: boutiqueShippingOption
    })
  }

  render() {
    const {boutiqueShipping, boutiqueShippingNote } = this.state
    let boutiqueShippingFormButton, saveBoutique, boutiqueShippingNoteLength
    if (boutiqueShipping) {
      boutiqueShippingFormButton = <a href="#"
            onClick={(e) => {this.toggleBoutiqueShippingForm(e)}}
            className="button-38 w-button">
            REMOVE/HIDE BOUTIQUE PACKAGING
          </a>
        boutiqueShippingNoteLength = 0
        if (this.state.boutiqueShippingNote && this.state.boutiqueShippingNote.length > 0) {
          saveBoutique = <input type="submit"
            value="SAVE"
            onClick={(e) => {this.handleSubmit(e)}}
            className="submit-button-7 w-button"/>
            boutiqueShippingNoteLength = this.state.boutiqueShippingNote.length
        } else {
          saveBoutique = <input type="submit"
            value="SAVE"
            style={{opacity: '0.5'}}
            disabled
            className="submit-button-7 w-button"/>
            boutiqueShippingNoteLength = 0
        }
    } else {
      boutiqueShippingFormButton = <a href="#"
            onClick={(e) => {this.toggleBoutiqueShippingForm(e)}}
            className="button-38 w-button">
            ADD BOUTIQUE PACKAGING
          </a>
          if (this.state.boutiqueShippingNote &&  this.state.boutiqueShippingNote.length > 0) {
            saveBoutique = <input type="submit" value="SAVE"
              onClick={(e) => {this.handleSubmit(e)}}
              className="submit-button-7 w-button"/>
              boutiqueShippingNoteLength = this.state.boutiqueShippingNote.length
          } else {
            saveBoutique = <input type="submit"
              value="SAVE"
              style={{opacity: '0.5'}}
              disabled
              className="submit-button-7 w-button"/>
            boutiqueShippingNoteLength = 0
          }
    }

    if(boutiqueShipping) {
      return (
        <div className="div-block-40">
          <div className="form-block-5 w-clearfix w-form">
            <form id="email-form-2" name="email-form-2" data-name="Email Form 2" className="form-9 w-clearfix">
              <small> Limit 300 characters. {boutiqueShippingNoteLength}/300</small>
              <textarea id="field-6"
              name="boutiqueShippingNote"
              placeholder="Write a note to your client..."
              maxLength="300"
              value={boutiqueShippingNote}
              onChange={this.handleChange}
              className="textarea w-input">
              </textarea>
            </form>
          </div>
          <div className="w-form">
            <form id="email-form-6" name="email-form-6" data-name="Email Form 6">
              {saveBoutique}
            </form>
          </div>
          {boutiqueShippingFormButton}
        </div>
      )
    } else {
      //return(<div></div>)
      return (
        <div>
          {boutiqueShippingFormButton}
        </div>
      )
    }
  }
}

export default BoutiquePackagingForm;
