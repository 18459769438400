import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import NavBar from "./components/NavBar.js";
import LineItemSummary from "./components/LineItemSummary.js";

import axios from "axios";
import Auth from "./modules/Auth.js";
import MuseaApi from "./modules/MuseaApi.js"

class CheckoutFinal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      //guid: this.props.location.state.orderGuid,
      labOrder: "",
      formattedLineItems: null,
      lineItems: null,
      processingOrder: false,
      orderConfirmed: false,
      errorState: false
    };
    this.deleteLineItem = this.deleteLineItem.bind(this)
    this.changeQty = this.changeQty.bind(this)
  }

  componentDidMount() {
    //const script = document.createElement("script");
    //script.innerHtml = "";
    //document.body.appendChild(script);
    let museaApi = new MuseaApi
    museaApi.checkAndResetCache()
    this.getLabOrder()
  }

  deleteLineItem(lineItemGuid){
    var token = Auth.getToken()
    axios
    .delete(`api/v2/line_items/${lineItemGuid}`,
            {headers: {'token': token}}
           )
           .then(res => {
             //console.log("res " + res.data['data'].attributes);
             let museaApi = new MuseaApi()
             var parsedOrder = museaApi.mergeAttributesForLabOrder(res.data);
             this.setState({
               redirectToReferrer: true,
               auth: Auth.isAuthenticated(),
               from: { pathname: "/" },
               labOrder: parsedOrder,
               formattedLineItems: parsedOrder.lineItems,
               loading: false,
               errorState: false
             });
             return true;
           })
           .catch(err => {
             this.throwError(err)
             //let error = JSON.parse(err.response)
             //this.throwError(error)
           })
  }

  changeQty(UpOrDown, currentQty, lineItemGuid){
    let newQty
    if (UpOrDown === "increase") {
      newQty = currentQty + 1
    } else {
      newQty = currentQty - 1
    }

    if (newQty > 0) {
      var token = Auth.getToken()
      axios
      .patch(`api/v2/line_items/${lineItemGuid}/update_quantity`, {
        qty: newQty
      },
      {headers: {'token': token}}
            )
            .then(res => {
              //console.log("res " + res.data['data'].attributes);

              let currentUserAdmin, currentUserApiVendor, viewAsPhotographerId, viewingAsPhotographer
              if (res.data.meta) {
                currentUserAdmin = res.data.meta.current_user_is_admin
                currentUserApiVendor = res.data.meta.current_user_is_api_vendor
                viewAsPhotographerId = res.data.meta.view_as_photographer_id
                viewingAsPhotographer = res.data.meta.view_as_photographer
              } else {
                currentUserAdmin = false
                currentUserApiVendor = false
                viewAsPhotographerId = null
                viewingAsPhotographer = null
              }

              let museaApi = new MuseaApi()
              var parsedOrder = museaApi.mergeAttributesForLabOrder(res.data);
              this.setState({
                redirectToReferrer: true,
                auth: Auth.isAuthenticated(),
                from: { pathname: "/" },
                labOrder: parsedOrder,
                userIsAdmin: currentUserAdmin,
                userIsApiVendor: currentUserApiVendor,
                viewAsPhotographerId: viewAsPhotographerId,
                viewingAsPhotographer: viewingAsPhotographer,
                formattedLineItems: parsedOrder.lineItems,
                loading: false,
                errorState: false
              });
              return true;
            })
            .catch(err => {
              this.throwError(err)
              //let error = JSON.parse(err.response)
              //this.throwError(error)
            })
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    this.setState({
      processingOrder: true
    })
    var token = Auth.getToken()
    var guid = this.props.location.pathname["split"]("/")[2];
    axios
    .post(`/api/v2/lab_orders/${guid}/checkout`,
          { save_card: this.state.saveCard }, {headers: {token: token}})
          .then(res => {
            this.setState({
              orderConfirmed: true
            })
          })
          .catch(err =>
                 this.throwError(err))
  }

  getLabOrder = (e, data) => {
    //e.preventDefault();
    var token = Auth.getToken()
    var guid = this.props.location.pathname["split"]("/")[2];
    axios
      .get(`api/v2/lab_orders/${guid}`, {headers: {'token': token}})
      .then(res => {
        //console.log("res " + res.data['data'].attributes);
        let museaApi = new MuseaApi()
        var parsedOrder = museaApi.mergeAttributesForLabOrder(res.data);

        this.setState({
          redirectToReferrer: true,
          auth: Auth.isAuthenticated(),
          from: { pathname: "/" },
          labOrder: parsedOrder,
          formattedLineItems: parsedOrder.lineItems,
          loading: false,
          errorState: false
        });
        return true;
      })
      .catch(err => {
        this.throwError(err)
        //let error = JSON.parse(err.response)
        //this.throwError(error)
      })
  };

  throwError(err) {
    let errorMessage, errorObject
    if (err.response.data["error"] && err.response.data["error"].status === 401) {
      this.setState({
        userLoggedOut: true
      })
      return false
    } else {
      if (err.response.data["error"] != null) {
        errorObject = err.response.data["error"]
        errorMessage = "\nERROR CODE: " + errorObject.status + "\nERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.user_message["message"]
        if (errorObject["more_info"] != null) {
          this.state.honeybadger.notify(err)
          alert("ERROR: " + errorMessage)
          console.log("ERROR: " + errorMessage + "\nMORE INFO: " + errorObject["more_info"].join(" ,").split(",").join("\n"))
        } else {
          this.state.honeybadger.notify(err)
          alert("ERROR: " + errorMessage)
          console.log(errorMessage + " More info not available.")
        }
      } else { 
        errorMessage = "\nERROR CODE: " + err.response.status + "\n ERROR MESSAGE: " + err.response.message
      }
    }
    this.setState({
      loading: false,
      processingOrder: false,
      selections: [],
      errorState: true,
      addingToCart: false,
      errorMessage: errorMessage
    })
  }

  render() {
    if (this.state.orderConfirmed) {
      return <Redirect to={{ pathname: '/lab-order-confirmation' , state: {} }} push />
    }

    if (this.state.userLoggedOut) {
      return <Redirect to={{ pathname: '/login' , state: { notifyUserOfLogout: true } }} push />
    }

    const {labOrder, formattedLineItems, loading} = this.state
              //addressRecipient: this.props.addressRecipient,
              //addressStreet: this.props.addressStreet,
              //addressCity: this.props.addressCity,
              //addressState: this.props.addressState,
              //addressZip: this.props.addressZip,
              //maskedCard: this.props.maskedCard,
              //creditAndDiscount: this.props.creditAndDiscount,
              //boutiqueNote: this.props.boutiqueNote />

    let lineItems, orderName, totalPrice, maskedCard, creditAndDiscount, shippingInfo, boutiqueInfo, orderButton, editLink

    if (labOrder && labOrder.length > 0  && labOrder.status !== "open" && labOrder.stripe_order_confirmation !== null) {
      return <Redirect to={{ pathname: `/lab-orders/${labOrder.guid}` , state: {notifyUserOfBadStatus: true} }} push />
    }

    if (labOrder && labOrder.lineItems && labOrder.lineItems.length < 1) {
      let redirectOrderUrl = `/lab-orders/${labOrder.guid}/edit/order-builder`
      return <Redirect to={{ pathname: redirectOrderUrl , state: {notifyUserOfMissingLineItems: true} }} push />
    }

    if (labOrder && !labOrder.local_pickup && (labOrder.shipping_address && labOrder.shipping_address.zip === null) ) {
      let redirectCheckoutDetailsUrl = `/lab-orders/${labOrder.guid}/checkout-details`
      return <Redirect to={{ pathname: redirectCheckoutDetailsUrl, state: {notifyUserOfMissingShippingAddress: true} }} push />
    }

    if (labOrder && (labOrder.selected_card_to_use === null) && (parseFloat(+labOrder.credit_applied) <= parseFloat(+labOrder.total)) ) {
      let redirectCheckoutDetailsUrl = `/lab-orders/${labOrder.guid}/checkout-details`
      return <Redirect to={{ pathname: redirectCheckoutDetailsUrl, state: {notifyUserOfMissingShippingAddress: true} }} push />
    }

    if (labOrder && labOrder.guid !== null  && labOrder.selected_card_to_use !== null && labOrder.shipping_address && labOrder.status === "open" || labOrder && labOrder.guid !== null && parseFloat(labOrder.credit_applied) == (parseFloat(labOrder.subtotal) + parseFloat(labOrder.shipping_total)) && labOrder.shipping_address && labOrder.status === "open") {
      orderButton = <div style={{opacity: '1'}} onClick={(e) => {this.handleSubmit(e)}} className="button-33 w-button">PLACE ORDER</div>
    } else {
      orderButton = <div style={{opacity: '0.5', cursor: 'default', pointerEvents: 'none' }} className="disabled-33 w-button">PLACE ORDER</div>
    }

    if (labOrder && labOrder !== null) {
      let labOrderCity
    if (labOrder && labOrder.shipping_address.city && labOrder.shipping_address.state_province) {
      labOrderCity = "" + labOrder.shipping_address.city + ", " + labOrder.shipping_address.state_province + " " + labOrder.shipping_address.zip
    } else {
      labOrderCity = ""
    }

    let labOrderRecipient

    if (labOrder.shipping_address.recipient && labOrder.shipping_address.recipient.length > 0) {
      labOrderRecipient = labOrder.shipping_address.recipient
    } else if (labOrder.client_name && labOrder.client_name.length > 0) {
      labOrderRecipient = labOrder.client_name
    } else {
      labOrderRecipient = labOrder.photographer_name
    }

    if (labOrder.local_pickup) {
      shippingInfo = <div className="orderSummaryShippingAddress text-block-58">
        {labOrderRecipient}
        <br />
        LOCAL PICKUP
      </div>
    } else if (labOrder.shipping_address.street) {
      shippingInfo = <div className="orderSummaryShippingAddress text-block-58">
            {labOrderRecipient}
            <br />
            {labOrder.shipping_address.street} {labOrder.shipping_address.street_2}
            <br />
            {labOrderCity}
          </div>
    } else {
      shippingInfo = <div className="orderSummaryShippingAddress text-block-58">
              NONE SELECTED
            <br />
            <br />
          </div>
      }
    }

    if (labOrder && labOrder !== null && labOrder.boutique_shipping_note !== null && labOrder.boutique_shipping_note.length > 1) {
      boutiqueInfo = <div className="orderSummaryBoutiquePackagingNote text-block-63">
           {labOrder.boutique_shipping_note}
        </div>
    } else {
      boutiqueInfo = <div className="text-block-63">NO BOUTIQUE PACKAGING</div>
    }

    if (labOrder && labOrder !== null) {
      totalPrice = labOrder.total
      if (labOrder.selected_card_mask && labOrder.selected_card_mask.length > 0) {
        maskedCard = labOrder.selected_card_mask
      } else {
        maskedCard = "NONE SELECTED"
      }
      let creditAmount = parseFloat(+labOrder.credit_applied)
      let discountAmount = parseFloat(+labOrder.discount)
      let totalDiscount = creditAmount + discountAmount
      creditAndDiscount = parseFloat(+totalDiscount).toFixed(2)
    } else {
      totalPrice = 0.0
      creditAndDiscount = 0.0
      maskedCard = "NONE SELECTED"
    }
    if (labOrder && labOrder !== null && labOrder.lineItems !== null) {
      lineItems= labOrder.lineItems
      orderName = labOrder.order_name
      editLink = <div className="text-block-100 text-block-101" style={{marginLeft: '100px'}}>
                  <Link style={{color: 'blue'}} to={{
                    pathname: `/lab-orders/${labOrder.guid}/checkout-details`,
                  }}>
                    EDIT
                  </Link>
                </div>
    } else {
      orderName = "LAB ORDER CHECKOUT"
      editLink = <div></div>
    }
    let currentIndex = 0
    let renderedLineItems
    if (lineItems && lineItems !== null) {
      renderedLineItems = formattedLineItems
      .sort((a, b) => a.guid - b.guid)
      .map(lineItem => {
      let index = currentIndex += 1
      return <LineItemSummary key={lineItem.guid + "" + index} isReviewScreen={false} canDeleteItem={false} qtyNoEdit={true} deleteLineItem={this.deleteLineItem} changeQty={this.changeQty} labOrderGuid={lineItem.labOrderGuid} index={index} guid={lineItem.guid} lineItem={lineItem} lineItemId={lineItem.guid} photoGuid={lineItem.photoGuid} coverPhotoUrl={lineItem.photoUrl} croppedPhotoUrl={lineItem.photograph_cropped_url} imageName={lineItem.imageName} externalId={lineItem.externalId} notes={lineItem.notes} adminNotes={lineItem.internalNotes} printSize={lineItem.printSize} printSizeGuid={lineItem.printSizeGuid} printBorder={lineItem.printBorder} printBorderRaw={lineItem.printBorderRaw} paper={lineItem.paper} paperGuid={lineItem.paperGuid} printPrice={lineItem.printPrice} finishingCategory={lineItem.finishingCategory} finishingService={lineItem.finishingService} finishingServiceGuid={lineItem.finishingServiceGuid} finishingServiceDetailGuid={lineItem.finishingServiceDetailGuid} finishingDetailOne={lineItem.finishingDetailOne} finishingDetailTwo={lineItem.finishingDetailTwo} finishingDetailThree={lineItem.finishingDetailThree} finishingPrice={lineItem.finishingPrice} cropAttributes={lineItem.cropAttributes} quantity={lineItem.quantity} totalPrice={lineItem.totalPrice} />
    })
    }

    if (loading || this.state.processingOrder) {
      return (
        <div>
          <NavBar />
          <div className="CheckoutFinal">
            <div className="loading" style={{
              marginTop: '10%',
              fontWeight: '800',
              marginLeft: '50%'
            }}>
            loading...
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <NavBar />

          <div className="container-14 w-container">
            <div className="columns-3 w-row">
              <div className="column-10 w-col w-col-4">
                <div className="text-block-53">
                  {orderName}
                </div>
                  {editLink}
              </div>
              <div className="column-11 w-col w-col-4">
                <div className="text-block-55">
                  <strong>TOTAL           </strong>
                  ${parseFloat(totalPrice).toFixed(2)}
                </div>
              </div>
              <div className="w-col w-col-4">
                <div className="div-block-37">
                 {orderButton}
                </div>
              </div>
            </div>
          </div>

          <div className="w-layout-grid grid-5">
            <div id="w-node-ea0b9cdfbd23-832e0c0c" className="text-block-60">SHIPPING TO</div>
              {shippingInfo}

            <div id="w-node-ea0b9cdfbd2b-832e0c0c" className="text-block-64">CREDIT CARD</div>
            <div id="w-node-ea0b9cdfbd2d-832e0c0c" className="text-block-62">{maskedCard}
            <br />

            </div>


            <div id="w-node-ea0b9cdfbd2f-832e0c0c" className="text-block-61">DISCOUNT AND CREDIT APPLIED</div>
            <div id="w-node-ea0b9cdfbd31-832e0c0c" className="text-block-59">${creditAndDiscount}</div>
            <div id="w-node-ea0b9cdfbd33-832e0c0c" className="text-block-65">
              BOUTIQUE
              <br/>
              PACKAGING
            </div>
            {boutiqueInfo}
          </div>

          <div className="text-block-99">ORDER DETAILS</div>

          {renderedLineItems}

        </div>
      );

    }

  }
}

export default CheckoutFinal;
