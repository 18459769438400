import React, { Component } from "react";
//import { Link } from "react-router-dom";

//import axios from "axios";
//import ReactCrop from 'react-image-crop';

import noteIcon from "../img/createNote.png";
import outlineClose from "../img/closeIcon.png";

import CropperSingle from "./CropperSingle.js";
//import CropperMultiple from "./CropperMultiple.js";

//import 'react-image-crop/dist/ReactCrop.css';
import '../css/ReactCropCustom.css';
//import "../css/newWebflow.css";

class SelectionArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selections: this.props.selections,
      labOrder: this.props.labOrder,
      cropOptions: this.props.cropOptions,
      printSizePricing: this.props.printSizePricing
    };
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.innerHtml = "";
    document.body.appendChild(script);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      //console.log("nextProps " + nextProps)
      this.setState({
        selections: nextProps.selections,
        printSizePricing: nextProps.printSizePricing,
        finishingPricing: nextProps.finishingPricing,
        finishingDetailPricing: nextProps.finishingDetailPricing,
        notesForPhotos: nextProps.notesForPhotos,
        selectedPhotoForNotes: nextProps.selectedPhotoForNotes,
        labOrder: nextProps.labOrder,
        cropOptions: nextProps.cropOptions
      });
    }
  }

  //handleOnImageLoaded = (image, pixelCrop) => {
  //this.imageRef = image;
  //};


  flipCropperOrientation = (imageGuid) => {
    //console.log("imageGuid to flip " + imageGuid)
    let labOrder = this.state.labOrder
    let imageToUpdate = labOrder.images.filter(item => (item.guid === imageGuid));
    let baseCropOptions

    if (imageToUpdate.length > 0) {
      let updatedImages = labOrder.images.map(image => {
        if (image.cropOptions !== null) {
          baseCropOptions = image.cropOptions
        } else {
          baseCropOptions = this.state.cropOptions
        }

        let cropOptions = {}
        Object.assign(cropOptions, baseCropOptions);
        cropOptions.imageGuid = image.guid
        cropOptions.unit = 'px'
        //cropOptions.unit = '%'
        cropOptions.imageRef = image.guid
        cropOptions.ref = image.guid

        if (image.guid !== imageGuid) {
          cropOptions.aspect = image.cropOptions.aspect
        } else {
          cropOptions.aspect = Math.pow(cropOptions.aspect, -1)
          //cropOptions.unit = '%'
          cropOptions.unit = 'px'
          cropOptions.guides = true
          cropOptions.height = 100
          cropOptions.width = 100
          //console.log("cropOptions aspect for " + image.guid + " is " + cropOptions.aspect)
        }

        image.cropOptions = cropOptions
        return image
      })
      labOrder.images = updatedImages
      //console.log("lab order " + labOrder)
      this.setState({
        labOrder: labOrder
      })
    }
  }

  //<img
  //alt="logo"
  //anima-src={outlineArrowDown}
  //className="text-block-157 outlinearrowdownwardblack48dp"
  //style={{marginTop: '6em', marginLeft: '0px'}}
  //src={outlineArrowDown}
  ///>

  render() {
    const { labOrder, selections, printSizePricing } = this.state

    let renderedSelections = []
    let selectedImages
    if (labOrder.images.length > 0) {
      selectedImages = labOrder.images.filter(item => (selections.includes(item.guid)));
    } else {
      selectedImages = []
    }

    //console.log("selected " + selectedImages)

    if (selections.length > 1 && printSizePricing !== null && printSizePricing.length > 0 && selectedImages.length > 0) {
      console.log("selections in SelectionArea is " + selections)
      renderedSelections = selectedImages.map(image => {
        //var srcset = `${image.presigned_photo_url} 1080w`

        let imageSrc
        if (image.smallest_photo && image.smallest_photo.length > 0) {
          imageSrc = image.smallest_photo
        } else {
          imageSrc = image.presigned_photo_url
        }

        return <div key={image.guid} className="singleImageMultiple">
          <div style={{ marginRight: '10px' }} >
            <div id="w-node-c73ed8c81b7d-f0e38d79" className="div-block-85">
              <img
                alt="close"
                anima-src={outlineClose}
                style={{ zIndex: '9999' }}
                className="image-73"
                src={outlineClose}
                onClick={() => { this.props.updateSelections("removeImage", image.guid, selections) }}
              />
            </div>
            <div className="div-block-84">
              <img anima-src={imageSrc}
                alt={image.photo_file_name}
                style={{ width: '250px' }}
                className="selectedPhoto image-72"
                sizes="248px"
                src={imageSrc} />
            </div>
            <div id="w-node-042d82278fe7-f0e38d79" className="div-block-76">
              <img
                alt="createNote"
                anima-src={noteIcon}
                style={{ height: '25px', width: '25px', cursor: 'pointer' }}
                className="createNoteIcon"
                src={noteIcon}
                onClick={() => this.props.selectNotesForPhoto(image.guid, image.photo_file_name)}
              />
            </div>
          </div>
        </div>
      })

      //renderedSelections = selectedImages.map(image => {
      //var srcset = `${image.presigned_photo_url} 1080w`
      //return <CropperMultiple key={image.guid} srcset={srcset} image={image} updateLabOrder={this.props.updateLabOrder} updateSelections={this.props.updateSelections} flipCropperOrientation={this.flipCropperOrientation} selectNotesForPhoto={this.props.selectNotesForPhoto} selections={selections} />

      //<ReactCrop ref={image.guid} imageRef={image.guid} key={image.guid} className="selectedPhoto" src={image.presigned_photo_url} crop={image.cropOptions} onChange={this.handleCropChange} style={{border: 'none'}} onComplete={this.handleCropCompleted} />

      //})
    } else if (selections.length > 1 && selectedImages.length > 0) {
      renderedSelections = selectedImages.map(image => {
        //var srcset = `${image.presigned_photo_url} 1080w`

        let imageSrc
        if (image.smallest_photo && image.smallest_photo.length > 0) {
          imageSrc = image.smallest_photo
        } else {
          imageSrc = image.presigned_photo_url
        }

        return <div key={image.guid} className="singleImageMultiple">
          <div style={{ marginRight: '10px' }} >
            <div id="w-node-c73ed8c81b7d-f0e38d79" className="div-block-85">
              <img
                alt="close"
                anima-src={outlineClose}
                style={{ zIndex: '9999' }}
                className="image-73"
                src={outlineClose}
                onClick={() => { this.props.updateSelections("removeImage", image.guid, selections) }}
              />
            </div>
            <div className="div-block-84">
              <img anima-src={imageSrc}
                alt={image.photo_file_name}
                style={{ width: '250px' }}
                className="selectedPhoto image-72"
                sizes="248px"
                src={imageSrc} />
            </div>
            <div id="w-node-042d82278fe7-f0e38d79" className="div-block-76">
              <img
                alt="createNote"
                anima-src={noteIcon}
                style={{ height: '25px', width: '25px', cursor: 'pointer' }}
                className="createNoteIcon"
                src={noteIcon}
                onClick={() => this.props.selectNotesForPhoto(image.guid, image.photo_file_name)}
              />
            </div>
          </div>
        </div>
      })
    } else if (selections !== null && selections.length === 1 && printSizePricing !== null && printSizePricing.length > 0 && selectedImages.length > 0) {
      //console.log("\n\n print size pricing not null: " + printSizePricing + "\n\n")
      const image = selectedImages[0]
      renderedSelections = <CropperSingle key={image.guid} image={image} updateLabOrder={this.props.updateLabOrder} flipCropperOrientation={this.flipCropperOrientation} selections={selections} selectedImages={selectedImages} />

      //<ReactCrop ref={image.guid} imageRef={image.guid} key={image.guid} className="singleSelectedPhoto" src={image.presigned_photo_url} crop={image.cropOptions} onChange={this.handleCropChange} style={{width: 'auto', maxWidth: '90%', maxHeight: '500px', border: 'none' }} onComplete={this.handleCropCompleted} />

    } else if (selections.length === 1 && selectedImages.length > 0) {
      //console.log("\n\n print size pricing null \n\n")
      const image = selectedImages[0]

      let imageSrc
      if (image.smallest_photo && image.smallest_photo.length > 0) {
        imageSrc = image.smallest_photo
      } else {
        imageSrc = image.presigned_photo_url
      }

      renderedSelections = <div>
        <img anima-src={imageSrc}
          alt={image.photo_file_name}
          className="image-57 singleSelectedPhoto"
          style={{
            width: 'auto',

            maxWidth: '90%',
            maxHeight: '800px'
          }}
          src={imageSrc} />
        <br />
      </div>
    } else {
      renderedSelections = <div style={{ marginTop: '60px', textAlign: 'center', fontWeight: '600' }}>
        loading image...
          </div>
    }

    if (selections.length > 1) {
      return (
        //<div>
        //<div className="smithorder" style={{left: '0', textAlign: 'center'}}>
        //{labOrder.order_name}
        //</div>
        //<div className="imageSelectedMultiple">
        //{renderedSelections}
        //</div>
        //</div>
        <div className="w-col w-col-8"
          style={{ height: "100vh", overflow: "auto" }}>
          <div className="text-block-157" style={{ fontSize: '14pt' }}>
            {labOrder.order_name}
          </div>
          <div className="grid-13" style={{ marginTop: '30px' }}>
            {renderedSelections}
          </div>
        </div>
      )
    } else if (selections.length === 1) {
      return (

        <div className="w-col w-col-8">
          <div className="text-block-157" style={{ fontSize: '14pt' }}>
            {labOrder.order_name}
          </div>
          <div className="div-block-60">
            {renderedSelections}
          </div>
        </div>

      )
    } else {
      return (
        <div className="w-col w-col-8">
          <div className="text-block-157" style={{ fontSize: '14pt' }}>
            {labOrder.order_name}
          </div>
          <div style={{ marginBottom: '20px', marginTop: '20%', fontSize: '16pt', lineBreak: 'after' }} className="div-block-60">
            SELECT A PHOTO TO BEGIN

          </div>
        </div>
      )
    }
  }
}

export default SelectionArea;
