import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
//import logo from "./img/sign-up-1-musea-colorwheel.png";
import validatedCheck from "./img/sign-up-1-v2-baselinecheckcircleblack48dp-copy-green@2x.png";
import Auth from "./modules/Auth.js";
import axios from "axios";
//import "./css/signUp1.css";

//import logo from "./img/sign-in-page-musea-colorwheel@2x.png";
import logo1080 from "./img/MUSEAbadge-black-p-1080.jpeg";
import logo500 from "./img/MUSEAbadge-black-p-500.jpeg"
import logo800 from "./img/MUSEAbadge-black-p-800.jpeg"
import logoBlack from "./img/MUSEAbadge-black.jpg"

//import "./css/signIn.css";
//import "./css/signInWebflow.css";

class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      creating: false,
      redirectToReferrer: false,
      tokenCreated: false,
      errorState: false,
      emailValidated: false,
      auth: Auth.isAuthenticated()
    };
   this.handleChange = this.handleChange.bind(this);
   this.validateEmail = this.validateEmail.bind(this);
   this.emailValid = this.emailValid.bind(this);
   this.throwError = this.throwError.bind(this)
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.innerHtml = "";
    document.body.appendChild(script);
  }

  handleChange(e) {
    const name = e.target.name;
    const val = e.target.value;

    if (name === "email" && this.emailValid(val)) {
      this.validateEmail(val)
    } else {
      this.setState({
        [name]: val
      });
    }
    //console.log(this.state);
  }

  emailValid(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  validateEmail(email) {
    axios
    .post(`/api/v2/check_for_email`,{email: email}, {headers: {}})
         .then(res => {
           //console.log(result);
           this.setState({
             emailError: false,
             email: email,
             emailValidated: true
           });
         })
         .catch(err =>
                //console.log(result);
                this.setState({
                  emailError: true,
                  emailValidated: false
                })
               )
  }

  handleSubmit(e) {
    e.preventDefault()
    this.setState({
      creating: true
    });

    var token = Auth.getToken()
    axios
    .post('/api/v2/forgot_password',
          {email: this.state.email,
          }, {headers: {'token': token}})
          .then(res => {
            this.setState({
              tokenCreated: true
            });
          })
          .catch(err =>
                 this.throwError(err))
  }

  throwError(err) {
    let errorMessage
    if (err.response != null) {
      let errorObject = err.response.data.error
      errorMessage = "\nCODE: " + errorObject.status + "\nERROR CLASS: " + errorObject.class_name + "\nERROR MESSAGE: " + errorObject.developer_message
      if (err.response.data.error.more_info != null){
        console.log("ERROR MESSAGE: " + errorMessage + "\nMORE INFO: " + err.response.data.error.more_info.join(" ,").split(",").join("\n"))
      } else {
        console.log(errorMessage + " More info not available.")
      }
    } else {
      errorMessage = err.stack
    }

    this.setState({
      loading: false,
      selections: [],
      errorState: true,
      addingToCart: false,
      errorMessage: errorMessage
    })
  }


  render() {
    let logoSrcSet = `${logo500} 500w, ${logo800} 800w, ${logo1080} 1080w, ${logoBlack} 1200w`
    let resetPasswordButton, emailErrorField, emailValidImage, emailValidColor

    if (this.state.emailValidated) {
      resetPasswordButton = <div>
                <input type="submit"
                  className="button w-button"
                  onClick={(e) => {this.handleSubmit(e)}}
                  value="SUBMIT"/>
                </div>
    } else {
      resetPasswordButton = <div>
                <input type="submit"
                  className="button w-button"
                  disabled
                  style={{opacity: '0.5'}}
                  value="SUBMIT"/>
                </div>
    }

    if (this.state.emailError) {
      emailErrorField = <div>
        <p className="w-form-fail" name="emailError" style={{display: 'inline-block', marginTop: '5px'}}>
          Email does not exist. Please register as a new user.
        </p>
      </div>

      emailValidImage = ""
      emailValidColor = 'red'
    } else if (!this.state.emailError && this.emailValid(this.state.email)) {
      emailErrorField = <div></div>
      emailValidImage = "url('" + validatedCheck + "')"
      emailValidColor = 'green'
    } else {
      emailErrorField = <div></div>
      emailValidImage = ""
    }

    if (this.state.tokenCreated) {
      const redirectPath = 'forgot-password-confirmation';
      return <Redirect push to={{ pathname: redirectPath, state: {} }} />
    } else if (this.state.errorState) {
    return (
      <div>
        <div className="ForgotPassword" style={{}}>
          <img alt="musea logo"
            srcset={logoSrcSet}
            sizes="144px"
            className="image"
            src={logoBlack}
            style={{width: "144"}}
            />

            <div className="w-form-fail" style={{
        textAlign: 'center',
        marginTop: '50px',
        marginBottom: '20px',
        display: 'inline-block',
        width: '100%'
      }} >
              <div>
                Oops! Something went wrong while submitting the form.
                <br/>
                {this.state.errorMessage}
              </div>
            </div>

            <div className="text-block">
              <Link to="/forgot-password">
                <span style={{marginLeft: '0.5em', textDecoration: 'underline', cursor: 'pointer'}}>RELOAD PAGE</span>
              </Link>
            </div>

       </div>
      </div>
    );
    } else {

    return (
      <div>
        <div className="ForgotPassword" style={{}}>

          <img alt="musea logo"
            srcset={logoSrcSet}
            sizes="144px"
            className="image"
            src={logoBlack}
            style={{width: "144"}}
            />

          <div className="form-block w-form">
            <form id="wf-form-SIGN-IN-FORM" name="wf-form-SIGN-IN-FORM" data-name="SIGN IN FORM" method="get" className="form">
              <div className="text-block-2">
                RESET PASSWORD
              </div>

              <div className="Email">
                <input type="text"
                  className="text-field-2 w-input"
                  name="email"
                  style={{
                    background: emailValidImage + ' 98% 50% /20px no-repeat',
                    color: emailValidColor }}
                  onChange={this.handleChange}
                  placeholder="E-mail"/>

                {emailErrorField}
              </div>

              {resetPasswordButton}
            </form>

          </div>

          <div className="text-block">
            BACK TO
            <Link to="/login">
              <span style={{marginLeft: '0.5em', textDecoration: 'underline', cursor: 'pointer'}}>SIGN IN PAGE</span>
            </Link>
          </div>

        </div>
      </div>
    );

    }
  }
}

export default ForgotPassword;
