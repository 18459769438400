import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import Auth from "../modules/Auth.js";

import logo from "../img/MUSEA-Colorwheel.png";
import accountCircle from "../img/user.png";
import outlineLaunch from "../img/outline_launch_black_48dp.png";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userLoggedOut: false,
      currentUserAdmin: this.props.currentUserAdmin
    };
    this.logoutUser = this.logoutUser.bind(this)
  }


  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        currentUserAdmin: nextProps.currentUserAdmin
      })
    }
  }

  logoutUser() {
    Auth.deauthenticateUser()
    //console.log("redirecting user...")
    this.setState({
      userLoggedOut: true
    })
  }


  render() {
    const { currentUserAdmin } = this.state
    if (this.state.userLoggedOut) {
      return <Redirect to={{ pathname: '/login', state: { notifyUserOfLogout: true } }} push />
    }
    let photographersLink
    //console.log("currentUserAdmin: " + currentUserAdmin)
    //console.log("props currentUserAdmin: " + this.props.currentUserAdmin)
    if (currentUserAdmin) {
      photographersLink = <Link to="/photographers" className="text-block-7">
        PHOTOGRAPHERS
      </Link>
    } else {
      photographersLink = <div></div>
    }
    //const availableCredit = (if this.props.availableCredit){
    //"CREDIT $" + {availableCredit}
    //} else {
    //"CREDIT"
    //}

    return (
      <div className="NavBar">
        <div className="section">

          <Link to="/">
            <img
              anima-src={logo}
              alt="musea logo"
              className="image-2"
              style={{ width: '40px' }}
              src={logo} />
          </Link>

          <div className="container-2 w-container">
            <Link to="/lab-orders" className="text-block-7">
              ORDERS
          </Link>

            <a
              href="http://musealab.com/pricing"
              target="_blank"
              rel="noopener noreferrer"
              className="text-block-8"
            >
              PRICING
          </a>

            <Link to="/credit" className="text-block-9">
              CREDIT
          </Link>

            {photographersLink}
          </div>

          <Link to="/account">
            <img
              anima-src={accountCircle}
              alt="account icon"
              className="image-4"
              src={accountCircle}
            />
          </Link>

          <img
            anima-src={outlineLaunch}
            alt="logout icon"
            className="image-3"
            onClick={() => { this.logoutUser() }}
            src={outlineLaunch}
          />
        </div>
      </div>
    );
  }
}

export default NavBar;
