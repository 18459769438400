import React, { Component } from "react";
//import { Link } from "react-router-dom";
import OrderSummaryInfo from "./OrderSummaryInfo.js";

//import "../css/orderPreviewOpen.css";
//import "../css/newWebflow.css";

class OrderSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labOrder: this.props.labOrder
    };
  }

  formatOrderDate(labOrder) {
    let options = {hour: '2-digit', minute: '2-digit'};
    let rawParsedDate = new Date(Date.parse(labOrder.updated_at)).toLocaleDateString("en-US", options)
    let parsedDate = rawParsedDate.toUpperCase()
    return parsedDate
  }

  render() {

    const { labOrder } = this.state
    let formattedDate = this.formatOrderDate(labOrder)

    return (
      <div>

        <div className="container-15 w-container" style={{}}>
          <div className="columns-3 w-row">
            <div className="column-10 w-col w-col-6">

              <div className="text-block-53"
                style={{}}>
                {labOrder.order_name}
              </div>

              <div className="text-block-54" style={{}}>
                UPDATED ON {formattedDate}
              </div>

              <div className="text-block-55" style={{}}>
                <strong> TOTAL ${parseFloat(+labOrder.total).toFixed(2)} </strong>
              </div>

            </div>

            <div className="column-11 w-col w-col-6">
              <div className="text-block-56">
                <strong className="bold-text-9">STATUS      </strong>
                {labOrder.status}
                <br/>
              </div>
              <div className="text-block-57">
                <strong className="bold-text-10">TRACKING</strong>
                <br/>
                <a href={labOrder.tracking_url}>
                  {labOrder.tracking_url}
                </a>
              </div>
            </div>


           </div>
          </div>

        <OrderSummaryInfo labOrder={labOrder} />

      </div>
    );
  }
}

export default OrderSummary;

