import React, { Component } from "react";
//import { Link } from "react-router-dom";

//import axios from "axios";
import ReactCrop from 'react-image-crop';

import outlineCrop from "../img/cropIcon.png";
//import outlineCreate from "../img/order-page-multiples-20-outlinecreateblack48dp 3@2x.png";
//import outlineClose from "../img/order-page-multiples-20-outlinecloseblack48dp@2x.png";
//import outlineArrowDown from "../img/order-page-1-outlinearrowdownwardblack48dp.png";
//import 'react-image-crop/dist/ReactCrop.css';
import '../css/ReactCropCustom.css';
import { Button } from "@material-ui/core";
//import "../css/newWebflow.css";

class CropperSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selections: this.props.selections,
      image: this.props.image
    };
  }
  componentDidUpdate() {
    // TODO this is where I can implement gallery wrap 
    // setTimeout(() => {
    const reactCropSelection = document.querySelector('.ReactCrop__crop-selection');
    if (this.state.image.cropOptions.selectedPaper === "Deckled Paper - Circle with border"
      || this.state.image.cropOptions.selectedPaper === "Deckled Paper - Circle no border") {
      if (reactCropSelection !== null) {
        reactCropSelection.style.borderRadius = "100%"
        reactCropSelection.style.border = "dashed white"
      }
    } 
    // else if (this.state.image.cropOptions.selectedPaper === "Canvas - Gallery Wrap") {
    //   const _selectedImage = document.querySelector('.ReactCrop__crop-selection');
    //   let imageHeight = _selectedImage.offsetHeight;
    //   let y_aspect = parseInt(this.state.image.cropOptions.y_aspect)

    //   let borderWidth = (imageHeight * 1.25 / y_aspect + 4)

    //   console.log(this.state.image.cropOptions)


    //   console.log(borderWidth)
    //   borderWidth = borderWidth + "px"
    //   reactCropSelection.style.borderRadius = "0%"
    //   reactCropSelection.style.border = 'solid rgba(255,255,255,0.5)'
    //   reactCropSelection.style.borderWidth = borderWidth
    // }
    else {
      if (reactCropSelection !== null) {
        reactCropSelection.style.borderRadius = "0%"
        reactCropSelection.style.border = "dashed white"
      }
    }
    // }, 500)
  }
  handleCropChange = (crop, image) => {
    console.log(this.state)
    if(crop.x_aspect === "16" && crop.y_aspect === "24" && this.state.image.cropOptions.selectedPaper === "Canvas - Gallery Wrap"){
      crop.x_aspect = "20"
      crop.y_aspect = "28"
      crop.aspect = 20/28
    }
    this.props.updateLabOrder(crop.imageGuid, crop)
  }

  handleCropCompleted = (crop, image) => {
    this.props.updateLabOrder(crop.imageGuid, crop)
  }
  render() {
    const { image } = this.state //, selections

    let imageSrc
    if (image.smallest_photo && image.smallest_photo.length > 0) {
      imageSrc = image.smallest_photo
    } else {
      imageSrc = image.presigned_photo_url
    }

    //let renderedSelections = []
    return (
      <div>
        <ReactCrop key={image.guid} ref={image.guid} imageRef={image.guid} className="image-57 singleSelectedPhoto" src={imageSrc} crop={image.cropOptions} onChange={this.handleCropChange} style={{
          width: 'auto', maxWidth: '90%',
          maxHeight: '800px', border: 'none'
        }} onComplete={this.handleCropCompleted} />
        <br />
        <div className="container-16 w-container">
          <img
            alt="cropIcon"
            anima-src={outlineCrop}
            className="image-61 largeCropIcon"
            style={{ marginTop: '20px' }}
            src={outlineCrop}
            onClick={() => this.props.flipCropperOrientation(image.guid)}
          />
        </div>


      </div>
    )
  }
}

export default CropperSingle;
