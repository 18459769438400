import React, { Component } from "react";

class OrderSummaryInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addressRecipient: this.props.addressRecipient,
      addressStreet: this.props.addressStreet,
      addressCity: this.props.addressCity,
      addressState: this.props.addressState,
      addressZip: this.props.addressZip,
      maskedCard: this.props.maskedCard,
      creditAndDiscount: this.props.creditAndDiscount,
      boutiqueNote: this.props.boutiqueNote
    }
  }

  componentWillReceiveProps(nextProps) {
    if(this.props !== nextProps) {
      this.setState({
        labOrder: nextProps.labOrder
      });
    }
  }

  render() {
    let maskedCard, creditAndDiscount, boutiqueNote, shippingInfo, labOrderCity,labOrderRecipient, labOrderStreet
    let labOrder = this.props.labOrder

    if (labOrder != null) {
      let creditAmount = parseFloat(+labOrder.credit_applied)
      let discountAmount = parseFloat(+labOrder.discount)
      let totalDiscount = creditAmount + discountAmount
      creditAndDiscount = parseFloat(+totalDiscount).toFixed(2)
    } else {
      creditAndDiscount = 0.0
    }

    if (labOrder != null) {
      maskedCard = labOrder.selected_card_mask
    } else {
      maskedCard = "NO INFO"
    }

    if (labOrder != null) {
      if (labOrder && labOrder.shipping_address && labOrder.shipping_address.city && labOrder.shipping_address.state_province) {
        labOrderCity = "" + labOrder.shipping_address.city + ", " + labOrder.shipping_address.state_province + " " + labOrder.shipping_address.zip
      } else {
        labOrderCity = ""
      }

      if (labOrder.shipping_address) {
        if (labOrder.shipping_address.recipient && labOrder.shipping_address.recipient.length > 0) {
          labOrderRecipient = labOrder.shipping_address.recipient

          if (labOrder.shipping_address.street && labOrder.shipping_address.street_2 && labOrder.shipping_address.street_2.length > 0) {
            labOrderStreet = "" + labOrder.shipping_address.street + " " + labOrder.shipping_address.street_2
          } else {
            labOrderStreet = "" + labOrder.shipping_address.street
          }

        } else if (labOrder.client_name && labOrder.client_name.length > 0) {
          labOrderRecipient = labOrder.client_name
        } else {
          labOrderRecipient = labOrder.photographer_name
        }
      } else {
        labOrderRecipient = ""
        labOrderStreet = ""
      }
      shippingInfo = <div className="orderSummaryShippingAddress text-block-58">

      {labOrderRecipient}
      <br />
      {labOrderStreet}
      <br />
      {labOrderCity}
      </div>
    } else {
      shippingInfo = <div className="orderSummaryShippingAddress">
      NONE SELECTED
      <br />
      <br />
      </div>
    }


    let boutiqueInfo
    if (boutiqueNote != null && boutiqueNote.length > 1) {
      boutiqueInfo = <div>
          <div className="orderSummaryBoutiquePackaging">
            BOUTIQUE PACKAGING
            <br/>
            <br/>
          </div>

          <div className="orderSummaryBoutiquePackagingNote">
           {boutiqueNote}
          </div>
        </div>
    } else {
      boutiqueInfo = <div>NO BOUTIQUE PACKAGING</div>
    }

    return (

      <div>

        <div className="orderSummaryInfo">

          <div className="div-block-14">
            <div className="w-layout-grid grid-8">
              <div id="w-node-ce191bc54973-129f596a" className="text-block-60">SHIPPING TO</div>

              {shippingInfo}

              <div id="w-node-a209b337b9af-129f596a" className="text-block-64">CREDIT CARD</div>
              <div id="w-node-f84e43f400f1-129f596a" className="text-block-62">{maskedCard}</div>

              <div id="w-node-ed3bdc8c1918-129f596a" className="text-block-61">DISCOUNT AND CREDIT APPLIED</div>
              <div id="w-node-cc29a87de619-129f596a" className="text-block-59"> ${parseFloat(+creditAndDiscount).toFixed(2)} </div>

              <div id="w-node-e13868b274ab-129f596a" className="text-block-65">BOUTIQUE PACKAGING</div>
              <div id="w-node-2f297ac7542a-129f596a" className="text-block-63"> {boutiqueInfo}</div>

            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default OrderSummaryInfo;
