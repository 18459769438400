import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";

import NavBar from "./components/NavBar.js";
import Auth from "./modules/Auth";
import MuseaApi from "./modules/MuseaApi.js";

import Honeybadger from 'honeybadger-js'
import ErrorBoundary from '@honeybadger-io/react'

import axios from "axios";
import downloadBlack from "./img/outline_cloud_download_black_48dp.png";

import Dropzone from 'react-dropzone';
import NewAddressForm from "./components/NewAddressForm.js";
import CreditCardForm from "./components/CreditCardForm.js";
import AccountInfoForm from "./components/AccountInfoForm.js";
import AddressList from "./components/AddressList.js";
import LoginInfoForm from "./components/LoginInfoForm.js";
import CreditCardList from "./components/CreditCardList.js";

//import "./css/Account.css";
//import "./css/newWebflow.css";

class Account extends Component {
  constructor() {
    super();
    const hb_config = {
      api_key: process.env.REACT_APP_HB_KEY,
      environment: process.env.REACT_APP_CURRENT_ENV,
      revision: 'current'
    }
    this.honeybadger = Honeybadger.configure(hb_config)
    this.state = {
      errorState: false,
      honeybadger: this.honeybadger,
      accepted: [],
      addresses: [],
      defaultAddress: [],
      creditCards: [],
      defaultCard: [],
      stripeId: '',
      clearingCards: false,
      creatingNewAddress: false,
      clearingAddresses: false,
      updatingAccountInfo: false,
      updatingPrimaryCard: false,
      updatingPrimaryAddress: false,
      generatingTaxReport: false,
      showCreditCardForm: true
    };
   this.throwError = this.throwError.bind(this)
   this.getUserProfile = this.getUserProfile.bind(this)
   this.toggleCreditCardForm = this.toggleCreditCardForm.bind(this)
   this.clearCardList = this.clearCardList.bind(this)
   this.clearAddressList = this.clearAddressList.bind(this)
   this.updateUser = this.updateUser.bind(this)
   this.createNewAddress = this.createNewAddress.bind(this)
   this.makePrimary = this.makePrimary.bind(this)
   this.deleteItem = this.deleteItem.bind(this)
   this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    let museaApi = new MuseaApi
    museaApi.checkAndResetCache()
    this.getUserProfile()
  }

  getUserProfile() {
    var token = Auth.getToken()
    axios
    .get('/api/v2/profile', {headers: {token: token}})
         .then(res => {
           console.log(res);
           this.setState({
             creditCards: res.data.cards.data,
             addresses: res.data.addresses.data,
             user: res.data.user,
             guid: res.data.user.data.attributes.guid,
             defaultAddress: res.data.default_address,
             defaultCard: res.data.default_card
           });
         })
         .catch(err =>
               this.throwError(err)
               )
  }

  handleChange(e) {
    const name = e.target.name;
    const val = e.target.value;
    this.setState({
      [name]: val
    });
    //console.log(this.state);
  }

  updateUser(firstName, lastName, businessName, phone) {
    this.setState({
      updatingAccountInfo: true
    })
    var token = Auth.getToken()
    axios
    .patch(`/api/v2/users/${this.state.guid}`,
          { first_name: firstName,
            last_name: lastName,
            business_name: businessName,
            phone: phone
          }, {headers: {token: token}})
          .then(res => {
            console.log("updated")
            this.setState({
              updatingAccountInfo: false,
              creditCards: res.data.cards.data,
              addresses: res.data.addresses.data,
              user: res.data.user,
              guid: res.data.user.data.attributes.guid,
              defaultAddress: res.data.default_address,
              defaultCard: res.data.default_card
            })
          })
          .catch(err =>
                 this.throwError(err))
  }

  generateTaxReport(year) {
    this.setState({
      generatingTaxReport: true
    })
    var token = Auth.getToken()
    axios
    .post('/api/v2/accounts/generate_tax_report',
          { year: year }, {headers: {token: token}})
          .then(res => {
            console.log("updated")
            this.setState({
              generatingTaxReport: false,
              creditCards: res.data.cards.data,
              addresses: res.data.addresses.data,
              user: res.data.user,
              guid: res.data.user.data.attributes.guid,
              defaultAddress: res.data.default_address,
              defaultCard: res.data.default_card
            })
          })
          .catch(err =>
                 this.throwError(err))
  }

  createNewAddress(streetAddress, city, stateName, zip) {
    this.setState({
      creatingNewAddress: true
    })
    var token = Auth.getToken()
    axios
    .post('/api/v2/addresses',
          { street_address: streetAddress,
            city: city,
            state: stateName,
            zip: zip
          }, {headers: {token: token}})
          .then(res => {
            console.log("updated")
            this.setState({
              creatingNewAddress: false,
              creditCards: res.data.cards.data,
              addresses: res.data.addresses.data,
              user: res.data.user,
              guid: res.data.user.data.attributes.guid,
              defaultAddress: res.data.default_address,
              defaultCard: res.data.default_card
            })
          })
          .catch(err =>
                 this.throwError(err))
  }

  toggleCreditCardForm(e) {
    if (this.state.showCreditCardForm) {
       this.setState({
         showCreditCardForm: false
       });
    } else {
       this.setState({
         showCreditCardForm: true
       });
    }
  }

  makePrimary(objectGuid, type) {
    let url
    if (type === "card") {
      url = `/api/v2/accounts/update_default_card`
      this.setState({updatingPrimaryCard: true})
    } else if (type === "address") {
      url = `/api/v2/accounts/update_default_address`
      this.setState({updatingPrimaryAddress: true})
    }

    var token = Auth.getToken()
    axios
    .post(url, {make_primary: objectGuid}, {headers: {'token': token}})
         .then(res => {
           console.log(res);
           this.setState({
             creditCards: res.data.cards.data,
             updatingPrimaryCard: false,
             updatingPrimaryAddress: false,
             addresses: res.data.addresses.data,
             user: res.data.user,
             guid: res.data.user.data.attributes.guid,
             defaultAddress: res.data.default_address,
             defaultCard: res.data.default_card
           });
         })
         .catch(err =>
                this.throwError(err)
               )
  }

  clearCardList() {
    if (this.state.creditCards != null) {
      this.setState({clearingCards: true})
      let cardsLength = this.state.creditCards.length - 1
      this.state.creditCards.map((card, index) => {
        console.log("index is " + index + " and length is " + cardsLength)
        console.log("card is " + card.attributes.id)
        if (index < (cardsLength)) {
          return this.deleteItem(card.attributes.id, "card", "clearingCards")
        } else {
          return this.deleteItem(card.attributes.id, "card", "")
        }
      })
    }
  }

  clearAddressList() {
    if (this.state.addresses != null) {
    this.setState({clearingAddresses: true})
    let addrLength = this.state.addresses.length

    this.state.addresses.map((address, index) => {
      //console.log("index is " + index + " and length is " + addrLength)
      //console.log("addr is " + address.attributes.id)

      if ( (index === (addrLength - 1)) || (index >= (addrLength - 1))) {
        //index < (addrLength)) {
        this.makePrimary("", "address")
        return this.deleteItem(address.attributes.guid, "address", "")
      } else {
        return this.deleteItem(address.attributes.guid, "address", "clearingAddresses")
      }
    })
    }
  }

  deleteItem(objectGuid, type, universalState) {
    let url
    if (type === "card") {
      url = `/api/v2/accounts/remove_card`
    } else if (type === "address") {
      url = `/api/v2/accounts/remove_address`
    }

    var token = Auth.getToken()
    axios
    .post(url, {selected_item: objectGuid}, {headers: {'token': token}})
         .then(res => {
           console.log(res);
           if (universalState === "clearingAddresses") {
             this.setState({
               clearingAddresses: true,
               clearingCards: false,
               creditCards: res.data.cards.data,
               addresses: res.data.addresses.data,
               user: res.data.user,
               guid: res.data.user.data.attributes.guid,
               defaultAddress: res.data.default_address,
               defaultCard: res.data.default_card
             });
           } else if (universalState === "clearingCards") {
             this.setState({
               clearingCards: true,
               clearingAddresses: false,
               creditCards: res.data.cards.data,
               addresses: res.data.addresses.data,
               user: res.data.user,
               guid: res.data.user.data.attributes.guid,
               defaultAddress: res.data.default_address,
               defaultCard: res.data.default_card
             });
           } else {
             this.setState({
               clearingCards: false,
               clearingAddresses: false,
               creditCards: res.data.cards.data,
               addresses: res.data.addresses.data,
               user: res.data.user,
               guid: res.data.user.data.attributes.guid,
               defaultAddress: res.data.default_address,
               defaultCard: res.data.default_card
             });
           }
         })
         .catch(err =>
               this.throwError(err)
               )
  }

  throwError(err) {
    let errorMessage, errorObject
    if (err.response.data["error"] && err.response.data["error"].status === 401) {
      this.setState({
        userLoggedOut: true
      })
      return false
    } else {
      if (err.response.data["error"] != null) {
        errorObject = err.response.data["error"]
        errorMessage = "\nERROR CODE: " + errorObject.status + "\nERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.user_message["message"]
        if (errorObject["more_info"] != null) {
          this.state.honeybadger.notify(err)
          alert("ERROR: " + errorMessage)
          console.log("ERROR: " + errorMessage + "\nMORE INFO: " + errorObject["more_info"].join(" ,").split(",").join("\n"))
        } else {
          this.state.honeybadger.notify(err)
          alert("ERROR: " + errorMessage)
          console.log(errorMessage + " More info not available.")
        }
      } else { 
        errorMessage = "\nERROR CODE: " + err.response.status + "\n ERROR MESSAGE: " + err.response.message
      }
    }

    this.setState({
      loading: false,
      creatingNewAddress: false,
      updatingAccountInfo: false,
      updatingPrimaryCard: false,
      updatingPrimaryAddress: false,
      generatingTaxReport: false,
      clearingCards: false,
      clearingAddresses: false,
      selections: [],
      errorState: true,
      addingToCart: false,
      errorMessage: errorMessage
    })
  }

  onDrop(acceptedFiles, rejectedFiles) {
    this.setState({
      uploading: true,
      completedUpload: false
    })

    acceptedFiles.forEach(file => {

      var token = Auth.getToken()
      axios
      .post(`/api/v2/lab_orders/${this.state.guid}/create_presigned_url`,
            {guid: this.state.guid,
              file_name: file.name
            }, {headers: {'token': token}})
      .then(res => {
        var signedUrl = res.data.signedUrl;
        //alert(signedUrl);

        var options = {
          headers: {
            'Content-Type': file.type
          }
        };

        return axios.put(signedUrl, file, options);
      })
      .then(res => {
      axios
      .patch(`/api/v2/lab_orders/${this.state.guid}/photograph_uploaded`,
            {guid: this.state.guid,
              file_name: file.name
            }, {headers: {'token': token}})
      })
      .then(this.getUserProfile)
      .then(res => {
        //console.log(result);
        this.setState({
          accepted: acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
          })),
          rejected: rejectedFiles,
          uploading: false,
          completedUpload: true,
          fileCount: (acceptedFiles.length + rejectedFiles.length)
        });
      })
      .catch(function (err) {
        this.throwError(err);

      });

    });
  }

  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    this.state.accepted.forEach(
      file =>
        URL.revokeObjectURL(file.preview)
    )
  }

  render() {
    const {fromEvent} = require('file-selector');
    let creditCardForm, creditCardList, firstName, lastName, email, businessName, phone, guid, stripeId, certLink, blanketCertFileName, blanketCertUpdate, downloadCertLink, nineteenMonthlyReportLink, eighteenMonthlyReportLink, seventeenMonthlyReportLink, sixteenMonthlyReportLink, nineteenMonthlySales, eighteenMonthlySales, seventeenMonthlySales, sixteenMonthlySales, accountInfoForm, newAddressForm, addressList, certArea, taxReportSection

    if (this.state.userLoggedOut) {
      return <Redirect to={{ pathname: '/login' , state: { notifyUserOfLogout: true } }} push />
    }

    if (this.state.user) {
      certLink = this.state.user.data.attributes.blanket_certificate_of_resale_link
      firstName = this.state.user.data.attributes.first_name
      lastName = this.state.user.data.attributes.last_name
      businessName= this.state.user.data.attributes.business_name
      phone= this.state.user.data.attributes.phone
      email= this.state.user.data.attributes.email
      guid = this.state.user.data.attributes.guid
      stripeId = this.state.user.data.attributes.stripe_customer_id
      nineteenMonthlyReportLink = this.state.user.data.attributes.nineteen_user_tax_report_link
      eighteenMonthlyReportLink = this.state.user.data.attributes.eighteen_user_tax_report_link
      seventeenMonthlyReportLink = this.state.user.data.attributes.seventeen_user_tax_report_link
      sixteenMonthlyReportLink = this.state.user.data.attributes.sixteen_user_tax_report_link
      if (certLink && certLink.length) {
      let certName = unescape(certLink.split("/")[certLink.split("/").length - 1])
      blanketCertFileName = unescape(certName.split("?")[0])
      } else {
      blanketCertFileName = ""
      }
    } else {
      firstName = "FIRST NAME"
      lastName = "LAST NAME"
      businessName= "BUSINESS NAME"
      phone= "PHONE"
      email= "E-MAIL"
      stripeId = ""
      certLink = ""
      blanketCertFileName = ""
      nineteenMonthlyReportLink = ""
      eighteenMonthlyReportLink = ""
      seventeenMonthlyReportLink = ""
      sixteenMonthlyReportLink = ""
    }

  if (nineteenMonthlyReportLink && nineteenMonthlyReportLink.length) {
      nineteenMonthlySales = <div> <a download href={nineteenMonthlyReportLink}
                        style={{color: 'black'}}>
                          <img alt="logo"
                                    anima-src={downloadBlack}
                                    className="image-15"
                                    src={downloadBlack} />
                        </a>
                        </div>
    } else {
      nineteenMonthlySales = <div>
        <input
          type="submit"
          className="button-12 w-button"
          style={{marginLeft: '10px'}}
          onClick={(e) => {this.generateTaxReport(2019) }}
          value="GENERATE" />
      </div>
    }

  if (eighteenMonthlyReportLink && eighteenMonthlyReportLink.length) {
      eighteenMonthlySales = <div> <a download href={eighteenMonthlyReportLink}
                        style={{color: 'black'}}>
                          <img alt="logo"
                                    anima-src={downloadBlack}
                                    className="image-15"
                                    src={downloadBlack} />
                        </a>
                        </div>
    } else {
      eighteenMonthlySales = <div>
        <input
          type="submit"
          className="button-12 w-button"
          style={{marginLeft: '10px'}}
          onClick={(e) => {this.generateTaxReport(2018) }}
          value="GENERATE" />
      </div>
    }

    if (seventeenMonthlyReportLink && seventeenMonthlyReportLink.length) {
      seventeenMonthlySales = <div><a download
                        href={seventeenMonthlyReportLink}
                        style={{color: 'black'}} >
                          <img alt="logo"
                                    anima-src={downloadBlack}
                                    className="image-15"
                                    src={downloadBlack} />
                        </a>
                        </div>

    } else {
      seventeenMonthlySales = <div>
        <input
          type="submit"
          className="button-12 w-button"
          style={{marginLeft: '10px'}}
          onClick={(e) => {this.generateTaxReport(2017) }}
          value="GENERATE" />
      </div>
    }

    if (sixteenMonthlyReportLink && sixteenMonthlyReportLink.length) {
      sixteenMonthlySales = <div><a download
                        href={sixteenMonthlyReportLink}
                        style={{color: 'black'}}>
                          <img
                                alt="logo"
                                    anima-src={downloadBlack}
                                    className="image-15"
                                    src={downloadBlack}
                                 />
                        </a>
                      </div>
    } else {
      sixteenMonthlySales = <div>
        <input
          type="submit"
          className="button-12 w-button"
          style={{marginLeft: '10px'}}
          onClick={(e) => {this.generateTaxReport(2016) }}
          value="GENERATE" />
      </div>
    }

    if (this.state.generatingTaxReport) {
      taxReportSection = <div>
            <div className="updating" style={{textAlign: 'center', marginBottom: '20px', fontWeight: '700'}}>
              updating tax report...
            </div>
        </div>
    } else {
      taxReportSection = <ul className="list">
                <li>
                  <div className="text-block-29">2016 YEARLY SALES</div>
                  {sixteenMonthlySales}
                </li>
                <li>
                  <div className="text-block-30">2017 YEARLY SALES</div>
                  {seventeenMonthlySales}
                </li>
                <li>
                  <div className="text-block-30">2018 YEARLY SALES</div>
                  {eighteenMonthlySales}
                </li>
                <li>
                  <div className="text-block-30">2019 YEARLY SALES</div>
                  {nineteenMonthlySales}
                </li>
              </ul>

    }

    if (blanketCertFileName && blanketCertFileName.length){

      if (this.state.uploading) {
        downloadCertLink = <div> </div>
      } else {
        downloadCertLink = <span style={{paddingLeft: '0px'}} className="text-block-15">{blanketCertFileName}
                    <a download href={certLink}
                        style={{color: 'black'}}>
                          <img alt="logo"
                                    anima-src={downloadBlack}
                                    className="image-15"
                                    src={downloadBlack} />
                      </a>
                   </span>
      }
    } else {
      downloadCertLink = <div></div>
    }

    blanketCertUpdate = <div>
                <input
                  type="submit"
                  className="button-18 w-button"
                  value="UPDATE" />
                </div>


    if (this.state.uploading) {
      certArea = <div>
            <div className="updating" style={{textAlign: 'center', marginTop: '20px', fontWeight: '700'}}>
              updating blanket certificate...
            </div>
        </div>
    } else {
      certArea = <Dropzone
                  accept="application/pdf"
                  getDataTransferItems={evt => fromEvent(evt)}
                  onDrop={this.onDrop.bind(this)}>

                  {({getRootProps, open, getInputProps}) => (
                    <div {...getRootProps()}
                       className="selectaphototobe"
                       style={{ }}>

                      <input {...getInputProps()} />

                      {blanketCertUpdate}

                    </div>
                  )}

                </Dropzone>
    }

    if (this.state.updatingAccountInfo) {
      accountInfoForm = <div>
            <div className="updating" style={{textAlign: 'center', marginBottom: '20px', fontWeight: '700'}}>
              updating user information...
            </div>
        </div>
    } else {
      accountInfoForm = <AccountInfoForm guid={guid} updateUser={this.updateUser} firstName={firstName} lastName={lastName} businessName={businessName} phone={phone}/>
    }

    if (this.state.creatingNewAddress) {
      newAddressForm = <div>
            <div className="updating" style={{textAlign: 'center', marginBottom: '20px', fontWeight: '700'}}>
              creating address...
            </div>
        </div>
    } else {
      newAddressForm = <NewAddressForm createNewAddress={this.createNewAddress}/>
    }

    if (this.state.clearingAddresses) {
      addressList = <div>
            <div className="updating" style={{textAlign: 'center', marginBottom: '20px', fontWeight: '700'}}>
              removing all saved addresses...
            </div>
        </div>
    } else if (this.state.updatingPrimaryAddress) {
      addressList = <div>
            <div className="updating" style={{textAlign: 'center', marginBottom: '20px', fontWeight: '700'}}>
              updating primary address...
            </div>
        </div>
    } else {
      addressList = <AddressList makePrimary={this.makePrimary} deleteItem={this.deleteItem} clearAddressList={this.clearAddressList} defaultAddress={this.state.defaultAddress} addresses={this.state.addresses}/>
    }

    if (this.state.clearingCards) {
      creditCardList = <div>
            <div className="updating" style={{textAlign: 'center', marginBottom: '20px', fontWeight: '700'}}>
              removing all saved cards...
            </div>
        </div>
    } else if (this.state.updatingPrimaryCard) {
      creditCardList = <div>
            <div className="updating" style={{textAlign: 'center', marginBottom: '20px', fontWeight: '700'}}>
              updating primary card...
            </div>
        </div>
    } else if (this.state.creditCards && this.state.creditCards.length > 0) {

      creditCardList = <CreditCardList clearCardList={this.clearCardList} makePrimary={this.makePrimary} defaultCard={this.state.defaultCard} deleteItem={this.deleteItem} creditCards={this.state.creditCards}/>
    } else {
      creditCardList = <div></div>
    }

    if (this.state.showCreditCardForm) {
      creditCardForm = <CreditCardForm cardPage="account" getUserProfile={this.getUserProfile} stripeId={stripeId} newCardVisibility='visible' onlyCard={true} />
    } else {
      creditCardForm = <div></div>
    }
    return (
      <div>
        <NavBar />
        <div className="Account">
          <div className="column-6 w-col w-col-1">
          </div>

         <div className="w-row">
           <div className="column-4 w-col w-col-5">
             <div className="text-block-14">
               BASIC INFORMATION
             </div>

             <div className="AccountInfoForm">
               {accountInfoForm}
             </div>

              <div className="NewAddressForm">
               {newAddressForm}
              </div>


              {addressList}

           </div>

           <div className="column-5 w-col w-col-5">
             <div className="text-block-15">
                CHANGE ACCOUNT EMAIL
             </div>
             <div className="LoginInfoForm">
                <LoginInfoForm guid={guid} email={email}/>
             </div>

             <div className="text-block-15" style={{marginTop: '40px'}}>
               CREDIT CARDS
             </div>

              {creditCardForm}

              {creditCardList}

               <div className="certificateUpload" style={{marginTop: '20px'}}>

                <div className="text-block-31">
                  BLANKET CERTIFICATE OF RESALE
                  <br />
                  {downloadCertLink}
                </div>
               {certArea}
              </div>


              <div className="text-block-28">
                SALES HISTORY
              </div>
                {taxReportSection}


           </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Account;
